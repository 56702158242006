import React from 'react';
import styled from 'styled-components';
import { Button } from 'ui/components';
import { Col, Container, Img, MainContainer, Row } from 'ui/layout';
import {
  fontMagra,
  fontMontserrat,
  H1,
  Number100,
  Number96,
  Text
} from 'ui/typography';
import { BREAKPOINTS, COLORS } from 'utils';
import {
  figure1,
  figure2,
  map_img,
  professionals_icon,
  professionals_icon_mob,
  experience_icon,
  experience_icon_mob,
  square_icon,
  square_icon_mob,
  square_buildings_icon,
  square_buildings_mob,
  apartment_icon,
  apartment_icon_mob,
  stadium_icon,
  stadium_icon_mob,
  road_icon,
  road_icon_mob,
  factory_icon,
  factory_icon_mob
} from 'assets/stats';

const StatIconsStyles = [
  {
    top: -30,
    right: 5,
    lg: {
      width: 72,
      right: -3
    },
    sm: {
      src: square_icon_mob,
      width: 34,
      top: 8,
      right: 49
    }
  },
  {
    top: -50,
    right: 77,
    lg: {
      width: 63,
      top: -28,
      right: -6
    },
    sm: {
      src: square_buildings_mob,
      width: 30,
      top: 4,
      right: 47
    }
  },
  {
    top: -5,
    right: 4,
    lg: {
      width: 68,
      top: -29,
      right: -4
    },
    sm: {
      src: apartment_icon_mob,
      width: 33,
      top: 6,
      right: 52
    }
  },
  {
    top: 3,
    right: 56,
    lg: {
      width: 82,
      top: 16,
      right: -36
    },
    sm: {
      src: stadium_icon_mob,
      width: 39,
      top: 7,
      right: 50
    }
  },
  {
    top: -6,
    right: 4,
    lg: {
      width: 57,
      top: -22,
      right: -11
    },
    sm: {
      src: road_icon_mob,
      width: 27,
      top: 8,
      right: 49
    }
  },
  {
    top: -12,
    right: -46,
    lg: {
      width: 75,
      top: -6,
      right: -16
    },
    sm: {
      src: factory_icon_mob,
      width: 36,
      top: -5,
      right: 49
    }
  }
];

export const Stats = (props) => {
  return (
    <Wrapper
      {...props}
      paddingTop={227}
      lgPaddingTop={141}
      smPaddingTop={66}
      paddingBottom={95}
      lgPaddingBottom={51}
      smPaddingBottom={80}
    >
      <Container relative>
        <Figure1 src={figure1} alt={''} />
        <MainContainer>
          <Row
            paddingRight={60}
            smdPaddingRight={'0'}
            smPaddingRight={41}
            spaceBetween
          >
            <Col auto lgWidth={'100%'} lgMarginBottom={75} smMarginBottom={24}>
              <Title>
                История <br />
                компании
              </Title>
            </Col>
            <Col auto lgPaddingLeft={3} smPaddingLeft={25} xsPaddingLeft={'0'}>
              <Row
                gap={15}
                lgGap={11}
                smdGap={3}
                smGap={5}
                paddingLeft={45}
                lgPaddingLeft={'0'}
              >
                <Col auto marginTop={25} lgMarginTop={18} smMarginTop={7}>
                  <ProfIcon src={professionals_icon} alt={''} />
                </Col>
                <Col auto marginTop={23} lgMarginTop={'0'}>
                  <Number>500</Number>
                  <Description marginTop={4} lgMarginTop={11} smMarginTop={1}>
                    Профессионалов <br />
                    своего дела
                  </Description>
                </Col>
              </Row>
            </Col>
            <Col auto smMarginRight={7}>
              <NumberWrapper>
                <Number>10</Number>
              </NumberWrapper>
              <Description
                textCenter
                marginTop={16}
                lgMarginTop={6}
                smMarginTop={3}
                smMaxWidth={'66px'}
              >
                Лет работы
              </Description>
            </Col>
          </Row>
        </MainContainer>
      </Container>

      <ButtonRow
        justifyContent={'center'}
        marginTop={86}
        lgMarginTop={88}
        smMarginTop={26}
        lgPaddingRight={15}
        smPaddingRight={12}
      >
        {/* <Button title={"Наша команда"} /> */}
      </ButtonRow>

      <ContainerSecond
        relative
        marginTop={93}
        lgMarginTop={80}
        smMarginTop={25}
      >
        <MainContainer>
          <Title2>
            География проектов <br />
            от Москвы до Нефтеюганска
          </Title2>
          <MapImg src={map_img} alt={''} />
        </MainContainer>
      </ContainerSecond>

      {/*Stats*/}
      <MainContainer
        marginTop={50}
        lgMarginTop={19}
        smMarginTop={10}
        lgMarginRight={-2}
        paddingLeft={'0'}
        maxWidth={1212}
      >
        <StatsRow
          gap={90}
          xlGap={30}
          lgGap={'0'}
          lgPaddingLeft={7}
          smPaddingLeft={'0'}
          smMaxWidth={'235px'}
        >
          <Col width={'50%'} lgWidth={'100%'} zIndex={1}>
            {' '}
            {/*1*/}
            <StatBox paddingY={76} lgPaddingY={55} smPaddingY={13} border>
              <div>
                <StatNumberRow gap={5} smGap={1} nowrap>
                  <NumberContainer>
                    <Number nowrap>158 253</Number>
                    <StatIcon
                      src={square_icon}
                      styles={StatIconsStyles[0]}
                      alt={''}
                    />
                  </NumberContainer>
                  <Unit>Га</Unit>
                </StatNumberRow>
                <StatDescr lineHeight={25} lgMarginTop={-5} marginTop={6}>
                  Площади застройки
                </StatDescr>
              </div>
            </StatBox>
          </Col>

          <Col width={'50%'} lgWidth={'100%'} zIndex={1}>
            {' '}
            {/*2*/}
            <StatBox
              paddingY={76}
              lgPaddingY={54}
              lgPaddingTop={65}
              smPaddingTop={32}
              smPaddingBottom={12}
              border
            >
              <div>
                <StatNumberRow
                  gap={5}
                  smGap={1}
                  paddingLeft={6}
                  lgPaddingLeft={'0'}
                >
                  <NumberContainer>
                    <Number nowrap>76 778</Number>
                    <StatIcon
                      src={square_buildings_icon}
                      styles={StatIconsStyles[1]}
                      alt={''}
                    />
                  </NumberContainer>
                  <Unit>кв.м.</Unit>
                </StatNumberRow>
                <StatDescr lineHeight={25} lgMarginTop={-6} marginTop={6}>
                  Площади строящихся зданий
                </StatDescr>
              </div>
            </StatBox>
          </Col>

          <Col width={'50%'} lgWidth={'100%'} zIndex={1}>
            {' '}
            {/*3*/}
            <StatBox
              paddingTop={58}
              paddingBottom={66}
              lgPaddingTop={63}
              lgPaddingBottom={57}
              smPaddingTop={31}
              smPaddingBottom={16}
              border
            >
              <div>
                <StatNumberRow gap={5} letterSpacing={-3}>
                  <NumberContainer>
                    <Number nowrap>20 336</Number>
                    <StatIcon
                      src={apartment_icon}
                      styles={StatIconsStyles[2]}
                      alt={''}
                    />
                  </NumberContainer>
                  <Unit>кв.м.</Unit>
                </StatNumberRow>
                <StatDescr marginTop={11} lgMarginTop={-3} smMarginTop={1}>
                  Квартир
                </StatDescr>
              </div>
            </StatBox>
          </Col>

          <Col width={'50%'} lgWidth={'100%'} zIndex={1}>
            {' '}
            {/*4*/}
            <StatBox
              paddingTop={58}
              paddingBottom={66}
              lgPaddingTop={64}
              lgPaddingBottom={56}
              smPaddingTop={31}
              smPaddingBottom={16}
              border
            >
              <div>
                <StatNumberRow>
                  <NumberContainer>
                    <Number nowrap>8 309</Number>
                    <StatIcon
                      src={stadium_icon}
                      styles={StatIconsStyles[3]}
                      alt={''}
                    />
                  </NumberContainer>
                </StatNumberRow>
                <StatDescr marginTop={11} lgMarginTop={-3} smMarginTop={'0'}>
                  Мест на стадионе
                </StatDescr>
              </div>
            </StatBox>
          </Col>

          <Col width={'50%'} lgWidth={'100%'} zIndex={1}>
            {' '}
            {/*5*/}
            <StatBox
              paddingY={80}
              lgPaddingTop={67}
              lgPaddingBottom={49}
              smPaddingTop={32}
              smPaddingBottom={18}
              smdBorder
            >
              <div>
                <StatNumberRow gap={5} smGap={4}>
                  <NumberContainer smMarginLeft={-3}>
                    <Number nowrap>50 000</Number>
                    <StatIcon
                      src={road_icon}
                      styles={StatIconsStyles[4]}
                      alt={''}
                    />
                  </NumberContainer>
                  <Unit>км.</Unit>
                </StatNumberRow>
                <StatDescr
                  marginTop={17}
                  lgMarginTop={1}
                  smMarginTop={-2}
                  lgSpacing
                >
                  Дорог
                </StatDescr>
              </div>
            </StatBox>
          </Col>
        </StatsRow>
      </MainContainer>

      <Figure2 src={figure2} alt={''} />
    </Wrapper>
  );
};

const Title = styled(H1)`
  ${fontMagra}
`;

const Number = styled(H1)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 70px;
    line-height: 85px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 35px;
    line-height: 42px;
  }
`;
const ButtonRow = styled(Row)`
  z-index: 1;
  position: relative;
`;
const NumberContainer = styled(Container)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    position: relative;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    position: static;
  }
`;
const Wrapper = styled(Container)`
  position: relative;
  background: ${COLORS.dark};
  color: ${COLORS.white};
`;
const Figure1 = styled(Img)`
  position: absolute;
  left: 0;
  top: -42px;
  width: 101px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 50px;
    top: -18px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 23px;
    top: -6px;
    left: -5px;
  }
`;
const ProfIcon = styled(Img)`
  width: 115px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 89px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 42px;
    content: url('${professionals_icon_mob}');
  }
`;
const Description = styled(Container)`
  ${fontMagra}
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  color: ${COLORS.white};
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 12px;
    line-height: 14px;
  }
`;
const NumberWrapper = styled.div`
  margin-left: 17px;
  margin-top: 20px;
  width: 112px;
  height: 88px;
  background: url('${experience_icon}') bottom center/contain no-repeat;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin: 0;
    width: 137px;
    height: 117px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 120px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 65px;
    height: 54px;
    background-image: url('${experience_icon_mob}');
  }
`;
const ContainerSecond = styled(Container)`
  :after {
    content: '';
    position: absolute;
    left: -56px;
    top: 0;
    height: 92px;
    width: 200px;
    transform: skewX(-30deg);
    background: #0c67b1;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    :after {
      height: 51px;
      top: 14px;
      left: -140px;
    }
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    :after {
      height: 24px;
      top: 7px;
      left: -187px;
    }
  }
`;
const Title2 = styled.h5`
  ${fontMagra}
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  padding-top: 18px;
  color: #ffffff;
  z-index: 2;
  position: relative;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 30px;
    line-height: 36px;
    padding-top: 0;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 20px;
    line-height: 24px;
    margin-left: -2px;
  }
`;
const MapImg = styled(Img)`
  position: absolute;
  top: -305px;
  left: 479px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 930px;
    left: 420px;
    top: -99px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 442px;
    left: 243px;
    top: -46px;
  }
`;
const StatsRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.xl}px) {
    margin-left: -60px;
    margin-right: -60px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-left: 0;
    margin-right: 0;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin-left: auto;
    margin-right: auto;
  }
`;
const StatBox = styled(Container)`
  ${(p) => (p.border ? `border-bottom: 1px solid ${COLORS.primary1};` : '')}
  @media (max-width: ${BREAKPOINTS.lg}px) {
    > div {
      max-width: 392px;
      margin-left: auto;
      margin-right: auto;
    }

    ${(p) => (p.lgBorder ? `border-bottom: 1px solid ${COLORS.primary1};` : '')}
  }

  @media (max-width: ${BREAKPOINTS.smd}px) {
    ${(p) =>
      p.smdBorder ? `border-bottom: 1px solid ${COLORS.primary1};` : ''}
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding-left: 7px;
    padding-right: 0px;
  }
`;
const StatNumberRow = styled(Row)`
  position: relative;
  align-items: flex-end;
  ${(p) => (p.letterSpacing ? `letter-spacing: ${p.letterSpacing}px;` : '')}
  @media (max-width: ${BREAKPOINTS.lg}px) {
    letter-spacing: unset;
  }
`;
const StatDescr = styled(Text)`
  ${fontMontserrat}
  font-size: 24px;
  ${(p) =>
    p.lgSpacing
      ? `
    @media (max-width: ${BREAKPOINTS.lg}px) {
      letter-spacing: 0.07em;
    }
  `
      : ''}
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 16px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    letter-spacing: unset;
  }
`;
const Unit = styled.span`
  font-size: 25px;
  line-height: 39px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 29px;
    line-height: 56px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 12px;
    line-height: 25px;
  }
`;
const StatIcon = styled(Img)`
  position: absolute;
  top: ${(p) => (p.styles?.top ? `${p.styles.top}px` : '0')};
  right: ${(p) => (p.styles?.right ? `${p.styles.right}px` : '0')};
  @media (max-width: ${BREAKPOINTS.lg}px) {
    transform: translateX(100%);
    ${(p) =>
      p.styles?.lg
        ? `
      ${p.styles?.lg.width ? `width: ${p.styles?.lg.width}px;` : ''}
      ${p.styles?.lg.top ? `top: ${p.styles?.lg.top}px;` : ''}
      ${p.styles?.lg.right ? `right: ${p.styles?.lg.right}px;` : ''}
    `
        : ''}
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${(p) =>
      p.styles?.sm
        ? `
      ${p.styles?.sm.src ? `content: url("${p.styles?.sm.src}");` : ''}
      ${p.styles?.sm.width ? `width: ${p.styles?.sm.width}px;` : ''}
      ${p.styles?.sm.top ? `top: ${p.styles?.sm.top}px;` : ''}
      ${p.styles?.sm.right ? `right: ${p.styles?.sm.right}px;` : ''}
    `
        : ''}
  }
`;
const Figure2 = styled(Img)`
  position: absolute;
  left: 0;
  bottom: -206px;
  width: 237px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    bottom: -85px;
    width: 115px;
    height: 222px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 49px;
    height: 104px;
    bottom: -42px;
    left: -2px;
  }
`;
