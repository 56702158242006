import React from 'react';
import { Slider } from 'components/common';

import { ProjectAsideInfo, ProjectInfo, ProjectsCommon } from '../components';
import { project7 } from 'assets/projects';
import { Col, Container, Row } from '../../../ui/layout';
import styled from 'styled-components';
import {
  ill1,
  ill2,
  ill3,
  ill4,
  ill5,
  ill6,
  ill7,
  ill8,
  ill9,
  ill10
} from 'assets/projects/toolRoad';
import { BREAKPOINTS } from '../../../utils';

export const TollRoad = () => {
  return (
    <ProjectsCommon>
      <Row gap={15}>
        <Col width={'66.66%'} lgWidth={'100%'}>
          <ProjectInfo
            ill={project7}
            title={'Лыткаринская платная дорога'}
            location={'Подмосковье'}
          >
            Масштабный проект системного улучшения дорожной инфраструктуры
            Подмосковья, предусматривающий строительство современной скоростной
            магистрали Солнцево – Бутово – Видное – Лыткарино – Томилино –
            Красково – Железнодорожный (ЛБВ-ЛТКЖ), известный также как
            «Южно-Лыткаринская автодорога» (ЮЛА).
          </ProjectInfo>
        </Col>
        <Col width={'33.33%'} lgWidth={'100%'}>
          <AsideIllRow>
            <Col>
              <ProjectAsideInfo
                info={[
                  {
                    title: 'Ширина дорожного полотна',
                    value: '10–20 м'
                  },
                  {
                    title: '40 000 автомобилей в сутки –',
                    value:
                      'интенсивность движения в первый год (с увеличением трафика до 56 300 шт. в сутки)'
                  },
                  {
                    title: 'Расчетная скорость движения',
                    value: '120 км/ч'
                  },
                  {
                    title: '4–6 м полос движения ',
                    value:
                      'с разделительной полосой в зоне расположения легкого рельсового транспорта'
                  },
                  {
                    title: '10 000 машин в сутки'
                  },
                  {
                    title: '100 % безопасность в любое время'
                  },
                  {
                    title: '68 % экономии времени на дорогу'
                  },
                  {
                    title: '0 барьеров – свободное движение'
                  }
                ]}
                minHeight={353}
              />
            </Col>
          </AsideIllRow>
        </Col>
      </Row>

      <Container marginTop={60} lgMarginTop={48} smdMarginTop={24}>
        <Slider
          images={[ill1, ill2, ill3, ill4, ill5, ill6, ill7, ill8, ill9, ill10]}
        />
      </Container>

      <Row marginTop={60}>
        <p>
          Скоростная дорога (предельная скорость до 120 км/ч) длиной 44,84
          километра пройдет по территории Ленинского городского округа (ЛГО), а
          также городских округов Лыткарино, Люберцы и Балашиха, соединив
          Варшавское и Носовихинское шоссе. Автомагистраль станет ближайшим
          дублером МКАД и будет способствовать ее разгрузке. Новая дорога
          позволит жителям региона воспользоваться двумя новыми въездами в
          Москву (по Юго-восточной и Северо-восточной хордам), в 2,5 раза
          сократит время в пути от населенных пунктов области и до аэропортов
          Домодедово и Жуковский, позволит создать рабочие места, существенно
          увеличит стоимость существующего жилья и позволит привлечь
          дополнительные инвестиции в строительный кластер этой части
          Подмосковья. Новая дорога позволит жителям региона воспользоваться
          двумя новыми въездами в Москву (по Юго-восточной и Северо-восточной
          хордам), в 2,5 раза сократит время в пути от населенных пунктов
          области и до аэропортов Домодедово и Жуковский, позволит создать
          рабочие места, существенно увеличит стоимость существующего жилья и
          позволит привлечь дополнительные инвестиции в строительный кластер
          этой части Подмосковья. Новая дорога будет сделана по принципу
          «безбарьерного проезда», что позволит не только не снижать скорость
          движения на всех участках трассы, но также избежать пробок на всем ее
          протяжении. А это, в свою очередь, позволит кардинально снизить
          уровень вредных выбросов сгоревшего топлива, таким образом сохраняя
          нормальный экологический фон. Важнейший фактор: при строительстве
          дороги будут учтены все требования экологического контроля, включая
          тоннель, над которым будет проходить «зеленая дорога» для безопасного
          пересечения транспортной магистрали животными. Лыткаринская платная
          дорога – высокоскоростная автомобильная магистраль категории I-Б
        </p>
      </Row>
    </ProjectsCommon>
  );
};

const AsideIllRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 66px;
    padding-bottom: 36px;
    position: relative;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 24px;
    padding-bottom: 38px;
  }
`;

const Img = styled.img`
  width: 100%;
  display: block;
  object-fit: cover;
`;
