import { useQuery } from '@apollo/client';
import { GET_OTHER_NEWS } from 'api/queries';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Row } from 'ui';
import { Button } from 'ui/components';
import { BREAKPOINTS } from 'utils';
import { NewsBlock } from '../NewsBlock';

export const OtherNews = ({ id }) => {
  const { loading, error, data } = useQuery(GET_OTHER_NEWS, {
    variables: { id }
  });

  if (loading) {
    return <p>Loading...</p>;
  } else if (error === []) {
    return <p>Something went wrong...</p>;
  }

  return (
    <>
      <Row lgGap={16} colsMB={36} lgColsMB={32} smdColsMB={20}>
        {data.articles.data.map((item, index) => (
          <Col width={'100%'} lgWidth={'50%'} smdWidth={'100%'} key={index}>
            <NewsBlock data={{ id: item.id, ...item?.attributes }} tall={index === 0} />
          </Col>
        ))}
      </Row>

      <ButtonContainer>
        <Link to={'/news'}>
          <Button title={'Все новости'} size={'medium'} />
        </Link>
      </ButtonContainer>
    </>
  );
};

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 109px;
  margin-left: -40px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 69px;
    margin-left: 0;
  }
`;
