import React from 'react';
import styled from 'styled-components';
import { Col, Container, Img, PageContainer, Row } from 'ui/layout';
import { fontMagra, fontMontserrat, H1, H3, H4 } from 'ui/typography';
import { BREAKPOINTS, COLORS } from 'utils';
import illustration from 'assets/ourGoal/illustration.png';
import illustration2 from 'assets/ourGoal/illustration2.png';
import illustration3 from 'assets/ourGoal/illustration3.png';
import illustration1_tablet from 'assets/ourGoal/illustration1_tablet.png';
import illustration1_mobile from 'assets/ourGoal/illustration1_mobile.png';
import bgFigure from 'assets/ourGoal/bg_figure.svg';
import targetIcon from 'assets/ourGoal/target_icon.svg';

export const OurGoal = (props) => {
  return (
    <PageContainer {...props}>
      <Row>
        <IllustrationWrapper
          xxlWidth={'55%'}
          xxlMaxWidth={'770px'}
          lgWidth={'45.6%'}
          smdWidth={'100%'}
          auto
          relative
        >
          <Ill1 wide src={illustration} block alt={''} />
          <DescriptionMobile hidden smdVisible>
            Работаем в интересах страны, создавая масштабные проекты социальной
            инфраструктуры. Изменяем мир, делая его более комфортным и удобным,
            создавая фундамент для процветания следующих поколений!
          </DescriptionMobile>
        </IllustrationWrapper>
        <Col width={'42%'} lgWidth={'52%'} smdHidden>
          <DescrContainer
            marginTop={233}
            lgMarginTop={67}
            marginLeft={67}
            lgMarginLeft={22}
            maxWidth={410}
            lgMaxWidth={303}
          >
            <StyledH1>Наша миссия —</StyledH1>
            <MissionSubtitle>
              Работаем в интересах будущего страны, создавая масштабные проекты
              социальной инфраструктуры. Изменяем мир, делая его более
              комфортным и удобным, создавая фундамент для процветания следующих
              поколений!
            </MissionSubtitle>
          </DescrContainer>
        </Col>
      </Row>

      <TargetRow
        gap={50}
        lgGap={35}
        alignCenter
        justifyContent={'center'}
        nowrap
        smdWrap
      >
        <Col auto lgMarginTop={20}>
          <TargetImg src={targetIcon} alt={''} />
        </Col>
        <Col
          auto
          maxWidth={'487px'}
          lgMaxWidth={'319px'}
          smdWidth={'100%'}
          marginTop={22}
          smdMarginTop={13}
        >
          <StyledH1>Наша цель —</StyledH1>
          <GoalSubtitle>
            полномасштабное и всеобъемлющее участие в крупнейших проектах
            промышленно-гражданского и инфраструктурного строительства, в форме
            государственно-частного партнерства и в рамках государственного
            заказа - контрактах жизненного цикла.
          </GoalSubtitle>
        </Col>
      </TargetRow>

      <Row
        gap={9}
        lgGap={5}
        smGap={3}
        nowrap
        justifyContent={'flex-end'}
        alignItems={'flex-end'}
        marginTop={104}
        lgMarginTop={119}
        smdMarginTop={23}
      >
        <Illustration2Wrapper
          auto
          xlWidth={'74%'}
          smWidth={'73.5%'}
          marginLeft={-118}
        >
          <Ill2 src={illustration2} block alt={''} />
        </Illustration2Wrapper>
        <Col auto xlWidth={'26%'} smWidth={'26.5%'}>
          <Ill3 src={illustration3} block alt={''} />
        </Col>
      </Row>
    </PageContainer>
  );
};

const StyledH1 = styled(H1)`
  ${fontMagra}

  font-weight: 800;
  color: #106eb7;
`;
const MissionSubtitle = styled.div`
  ${fontMontserrat}
  margin-top: 21px;
`;
const IllustrationWrapper = styled(Col)`
  position: relative;

  :after {
    content: '';
    position: absolute;
    bottom: -243px;
    right: -331px;
    width: 541px;
    height: 481px;
    background: url('${bgFigure}') center/contain no-repeat;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    :after {
      width: 256px;
      height: 228px;
      right: -117px;
      bottom: -162px;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    :after {
      width: 222px;
      height: 198px;
      right: -46px;
      bottom: -92px;
    }
  }
`;
const DescrContainer = styled(Container)``;
const DescriptionMobile = styled(Container)`
  position: absolute;
  top: 40px;
  right: 13%;
  width: 80%;
  max-width: 274px;
  color: white;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    top: 18px;
    max-width: 207px;
  }
`;
const TargetRow = styled(Row)`
  margin-top: 325px;
  margin-left: -177px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 290px;
    padding-left: 18px;
    margin-left: -80px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    justify-content: flex-start;
    margin-top: 30px;
    padding: 0 29px 0 23px;
    margin-left: 0;
  }
`;
const TargetImg = styled(Img)`
  width: 235px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 175px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 65px;
  }
`;
const StyledH4 = styled(H4)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 30px;
    line-height: 39px;
    font-weight: 700;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 20px;
    line-height: 26px;
  }
`;
const GoalSubtitle = styled.p`
  ${fontMontserrat}
  max-width: 503px;
  margin-top: 10px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 18px;
    font-size: 16px;
    line-height: 21px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 19px;
    font-size: 12px;
    line-height: 16px;
    max-width: 400px;
  }
`;
const Illustration2Wrapper = styled(Col)`
  position: relative;
  z-index: 2;

  :after {
    content: '';
    position: absolute;
    bottom: 0;
    right: -412px;
    width: 572px;
    height: 92px;
    background: #fab43a;
    transform: skewX(-30deg);
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    :after {
      right: -191px;
      width: 265px;
      height: 43px;
    }
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    :after {
      right: -90px;
      width: 45%;
      height: 21px;
    }
  }
`;
const Ill1 = styled(Img)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    content: url('${illustration1_tablet}');
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    content: url('${illustration1_mobile}');
  }
`;
const Ill2 = styled(Img)`
  @media (max-width: ${BREAKPOINTS.xl}px) {
    width: 100%;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: calc(100% + 6px);
    margin-left: -6px;
  }
`;
const Ill3 = styled(Img)`
  margin-bottom: 112px;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    width: 100%;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-bottom: 52px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin-bottom: 26px;
  }
`;
