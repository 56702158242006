import React from 'react';
import styled from 'styled-components';
import { Header, Footer } from 'components';
import { Col, Container, MainContainer, Row } from 'ui/layout';
import { H2 } from 'ui';
import { BREAKPOINTS } from '../../utils';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

export const ContactsPage = () => {
  return (
    <>
      <Header active={'contacts'} />
      <Wrapper>
        <MainContainer>
          <TitleText>Контакты</TitleText>

          <Row marginTop={60} lgMarginTop={48} smdMarginTop={36} spaceBetween>
            <Col width={'465px'} paddingLeft={3}>
              <TextBold>Телефон</TextBold>
              <TextNormal marginBottom={36} smdMarginBottom={24}>
                +7-495-899-05-64
              </TextNormal>
              <TextBold>Почта</TextBold>
              <TextNormal marginBottom={36} smdMarginBottom={24}>
                info@allgk.ru
              </TextNormal>
              <TextBold>Адрес обособленного подразделения</TextBold>
              <TextNormal marginBottom={36} smdMarginBottom={24}>
                г.Москва, поселение Московский,
                <br />
                Киевское шоссе 22-й километр, домовладение 6,
                <br />
                строение 1, БЦ «ComCity», А4а, 4 этаж, 108811
              </TextNormal>
              <TextBold>Юридический адрес</TextBold>
              <TextNormal marginBottom={36} smdMarginBottom={48}>
                391030, Рязанская обл., Клепиковский
                <br /> район, г. Спас-Клепики, ул. Московская, д. 4
                <br />
                <br />
                ОГРН 1126226000273,
                <br /> Дата присвоения ОГРН: 05.06.2012
                <br />
                <br /> ИНН 6205008227, КПП 620501001
              </TextNormal>
              <Container hidden lgVisible marginBottom={60}>
                <TextBold>Отдел подбора персонала</TextBold>
                <TextNormal marginBottom={36}>
                  по вопросу вакансий и приёма на работу обращаться:
                  <br />
                  <br />
                  +7 929 066-38-62
                  <br />
                  <br />
                  +7 930 888-50-62
                  <br />
                  <br />
                  +7 930 881-65-62 (добавочные 1209, 1213, 1703, 1416)
                </TextNormal>
              </Container>
            </Col>
            <Block width={'50%'} lgDisplay={'none'}>
              <TextBold>Отдел подбора персонала</TextBold>
              <TextNormal marginBottom={36}>
                по вопросу вакансий и приёма на работу обращаться:
                <br />
                +7 929 066-38-62
                <br />
                +7 930 888-50-62
                <br />
                +7 930 881-65-62 (добавочные 1209, 1213, 1703, 1416)
              </TextNormal>
              <YMaps>
                <Map
                  width={'100%'}
                  height={'407px'}
                  defaultState={{ center: [55.633785, 37.431989], zoom: 15 }}
                >
                  <Placemark defaultGeometry={[55.633785, 37.431989]} />
                </Map>
              </YMaps>
            </Block>
          </Row>
        </MainContainer>
        <Block
          width={'100%'}
          display={'none'}
          lgDisplay={'block'}
          alignSelf={'center'}
        >
          <YMaps>
            <Map
              width={'100%'}
              height={'440px'}
              defaultState={{ center: [55.633785, 37.431989], zoom: 15 }}
            >
              <Placemark defaultGeometry={[55.633785, 37.431989]} />
            </Map>
          </YMaps>
        </Block>
      </Wrapper>
      <Footer />
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding-top: 115px;
  padding-bottom: 208px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding-top: 80px;
    padding-bottom: 160px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-top: 48px;
    padding-bottom: 96px;
  }
`;

const TitleText = styled.p`
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 36px;
    line-height: 44px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

const TextBold = styled.p`
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const TextNormal = styled.p`
  margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom + 'px' : 'unset')};
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 18px;
    line-height: 23px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-bottom: ${(p) =>
      p.smdMarginBottom ? p.smdMarginBottom + 'px' : 'inherit'};
    font-size: 16px;
    line-height: 21px;
  }
`;

const Block = styled(Col)`
  display: ${(p) => (p.display ? p.display : 'inherit')};
  flex-direction: column;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: ${(p) => (p.lgDisplay ? p.lgDisplay : 'inherit')};
  }
`;
