import styled from 'styled-components';
import { Container, MainContainer, Row } from 'ui/layout';
import { fontMagra, fontMontserrat, H1, Text25 } from 'ui/typography';
import { BREAKPOINTS, COLORS } from 'utils';

const Title = styled(H1)`
  ${fontMagra}
`;
const StyledMainContainer = styled(MainContainer)`
  border-bottom: 1px solid #bdbdbd;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    border: none;
    :after {
      content: '';
      position: absolute;
      left: -50px;
      bottom: -1px;
      height: 1px;
      width: calc(100% + 110px);
      background: #bdbdbd;
    }
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    :after {
      left: 20px;
      width: calc(100% - 40px);
    }
  }
`;
const SlideRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    justify-content: center;
  }
`;
const IllustrationWrapper = styled(Container)`
  width: 532px;
  height: 473px;
  position: relative;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 270px;
    height: 240px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 129px;
    height: 115px;
    transform: translateX(-5px);
  }
`;
const Figure = styled.img`
  width: 100%;
  height: 100%;
`;
const Illustration = styled.img`
  position: absolute;
  max-width: 100%;
  width: ${(p) => p.width}px;
  left: ${(p) => p.left}px;
  top: ${(p) => p.top}px;
  z-index: ${(p) => (p.zIndex ? p.zIndex : '-1')};
  @media (max-width: ${BREAKPOINTS.lg}px) {
    ${(p) =>
      p.lg
        ? `
      ${p.lg.width ? `width: ${p.lg.width}px;` : ''};
      ${p.lg.left ? `left: ${p.lg.left}px;` : ''};
      ${p.lg.top ? `top: ${p.lg.top}px;` : ''}
      ${p.lg.icon ? `content: url("${p.lg.icon}");` : ''}
    `
        : ''}
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${(p) =>
      p.sm
        ? `
        ${p.sm.width ? `width: ${p.sm.width}px;` : ''};
        ${p.sm.left ? `left: ${p.sm.left}px;` : ''};
        ${p.sm.top ? `top: ${p.sm.top}px;` : ''}
        ${p.sm.icon ? `content: url("${p.sm.icon}");` : ''}
    `
        : ''}
  }
`;
const TextWrapper = styled(Container)`
  white-space: pre-line;
  ${fontMontserrat}

  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-left: auto;
    margin-right: auto;
    min-height: 126px;
    display: flex;
    align-items: center;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    max-width: 327px;
    padding-left: 21px;
    padding-right: 20px;
    box-sizing: content-box;
  }
`;
const NavLeft = styled.img`
  padding: 15px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 13px;
    left: -34px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 6px;
    top: 58px;
    left: 23px;
  }
`;
const NavRight = styled(NavLeft)`
  transform: rotate(180deg) translateY(50%);
  left: unset;
  right: 0;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    right: -30px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    right: 24px;
  }
`;
const PaginationRow = styled(Row)`
  transform: translateX(-18px);
  @media (max-width: ${BREAKPOINTS.lg}px) {
    transform: translateX(-5px);
  }
`;
const PaginationDot = styled.div`
  width: 17px;
  height: 17px;
  border: 1px solid ${COLORS.lightGray};
  border-radius: 100%;
  transition: background 0.15s;
  cursor: pointer;

  ${(p) =>
    p.active
      ? `
    background: #23B5B6;
    border:none;
    cursor: default;
  `
      : ''}

  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 10px;
    height: 10px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 5px;
    height: 5px;
  }
`;

export {
  Title,
  StyledMainContainer,
  SlideRow,
  IllustrationWrapper,
  Figure,
  Illustration,
  TextWrapper,
  NavLeft,
  NavRight,
  PaginationRow,
  PaginationDot
};
