import React, { useState, useMemo } from 'react';
import { Header, Footer, NewsBlock, Pagination, YearsFilter } from 'components';
import { Col, MainContainer, Row } from 'ui/layout';
import styled from 'styled-components';
import figure from 'assets/common/figure_green.svg';
import { BREAKPOINTS } from '../../utils';
import { useQuery } from '@apollo/client';
import { ALL_ARTICLES } from 'api/queries';

const YEARS = [2022, 2023, 2024];
let NEWS_ON_PAGE = 11;
// if (window.innerWidth < BREAKPOINTS.lg) NEWS_ON_PAGE = 11;
if (window.innerWidth < BREAKPOINTS.smd) NEWS_ON_PAGE = 5;

export const NewsPage = () => {
  const [page, setPage] = useState(1);
  const { loading, data, error } = useQuery(ALL_ARTICLES, {variables: {page: page, pageSize: NEWS_ON_PAGE}});

  const [selectedYear, setSelectedYear] = useState(
    YEARS.includes(new Date().getFullYear())
      ? new Date().getFullYear()
      : YEARS[YEARS.length - 1]
  );

  const changeYear = (year) => {
    setSelectedYear(year);
    setPage(1);
  };

  // const FILTERED_NEWS = useMemo(
  //   () =>
  //     data.articles.data.filter(
  //       (item) => item.attributes.date.getFullYear() === selectedYear
  //     ),
  //   [selectedYear]
  // );

  if (loading) {
    return <p>Loading...</p>;
  } else if (error) {
    return <p>Something went wrong...</p>;
  }

  const articles = data.articles?.data;
  const totalPages = data.articles?.meta?.pagination?.pageCount;

  return (
    <div>
      <Header active={'news'} />

      <Wrapper>
        <Figure1 src={figure} alt={''} />

        <MainContainer>
          <Title>Новости компании и отрасли</Title>

          {/*<FiltersContainer>*/}
          {/*  <YearsFilter*/}
          {/*    options={YEARS}*/}
          {/*    selected={selectedYear}*/}
          {/*    onChange={(value) => changeYear(value)}*/}
          {/*  />*/}
          {/*</FiltersContainer>*/}

          <NewsList>
            <Row gap={20} lgGap={16} colsMB={36} lgColsMB={32} smdColsMB={20}>
              {articles.map((item, index) => (
                <Col
                  width={index === 0 ? '66.66%' : '33.33%'}
                  lgWidth={index === 0 ? '100%' : '50%'}
                  smdWidth={'100%'}
                  key={index}
                >
                  <NewsBlock
                    data={{ id: item.id, ...item?.attributes }}
                    wide={index === 0}
                    tall={index === 1}
                  />
                </Col>
              ))}
            </Row>
          </NewsList>

          <PaginationContainer>
            <Pagination
              active={page}
              changeActive={setPage}
              pages={totalPages}
            />
          </PaginationContainer>
        </MainContainer>

        <Figure2 src={figure} alt={''} />
      </Wrapper>

      <Footer />
    </div>
  );
};

const Title = styled.h1`
  font-size: 38px;
  line-height: 46px;
  font-weight: 600;
  max-width: 380px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    max-width: 453px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 25px;
    line-height: 30px;
    max-width: 256px;
  }
`;
const Wrapper = styled.div`
  position: relative;
  padding-top: 89px;
  padding-bottom: 155px;
  :after {
    content: '';
    position: absolute;
    left: -76px;
    top: 0;
    height: 89px;
    width: 100px;
    background: #54ccc9;
    transform: skew(-30deg);
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding-top: 55px;
    padding-bottom: 101px;
    :after {
      left: -76px;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-top: 30px;
    padding-bottom: 57px;
    :after {
      height: 26px;
      left: -90px;
      top: 32px;
    }
  }
`;
const Figure1 = styled.img`
  position: absolute;
  right: -249px;
  top: 84px;
  object-fit: contain;
  width: 499px;
  z-index: -1;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 429px;
    top: 32px;
    right: -276px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 282px;
    top: 67px;
    right: -179px;
  }
`;
const Figure2 = styled(Figure1)`
  top: unset;
  right: unset;
  left: -245px;
  bottom: 2px;
  width: 375px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 263px;
    bottom: -2px;
    left: -183px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 214px;
    bottom: -27px;
    left: -162px;
  }
`;
const FiltersContainer = styled.div`
  margin-top: 38px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 42px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 10px;
  }
`;
const NewsList = styled.div`
  margin-top: 14px;
  margin-left: -10px;
  margin-right: -10px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-left: -15px;
    margin-right: -15px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 27px;
    margin-left: -7px;
    margin-right: -7px;
  }
`;
const PaginationContainer = styled.div`
  margin-top: 71px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 78px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 39px;
  }
`;
