import React from 'react';
import styled from 'styled-components';
import { Button } from 'ui/components';
import { Container, MainContainer, Row as DefRow, Img, Col } from 'ui/layout';
import illustration from 'assets/workFor/illustration2.png';
import { BREAKPOINTS, COLORS } from '../../../../utils';
import { fontMontserrat } from 'ui';

export const WorkFor = (props) => {
  return (
    <MainContainer {...props}>
      <Row gap={32}>
        <Col
          width={'47.7%'}
          lgWidth={'72%'}
          lgOrder={2}
          lgMarginTop={43}
          smHidden
        >
          <Img src={illustration} wide alt='' />
        </Col>
        <Col width={'52.3%'} lgWidth={'100%'}>
          <Description marginTop={140} xlMarginTop={64} lgMarginTop={'0'}>
            <span>ГК «Альянс»</span> – динамично растущая компания, занимающая
            устойчивые позиции в сфере реализации проектов с привлечением
            инфраструктурных инвестиций и государственно-частного партнерства, в
            рамках исполнения государственного заказа.
            <br />
            <br />
            <span>ГК «Альянс»</span> активно стремится выйти в лидеры
            строительной отрасли, особенно в производственно-инфраструктурном
            кластере, опираясь на команду профессионалов, имеющих богатый опыт в
            строительстве.
            <br />
            <br />
            <span>ГК «Альянс»</span>, обладая успешным опытом выполнения крупных
            и технологически сложных проектов, владеет всем необходимым
            оборудованием для выполнения строительных работ любого уровня,
            использует инновационные технологии во всех сферах - от строительных
            материалов до новейших систем организации производственного
            менеджмента, управления и контроля.
          </Description>

          <Container
            marginTop={40}
            smMarginTop={44}
            lgTextCenter
            lgMarginLeft={-7}
            smMarginLeft={-18}
          >
            <Button title={'Наши проекты'} smNoRadius />
          </Container>
        </Col>
      </Row>
    </MainContainer>
  );
};

const Row = styled(DefRow)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    justify-content: center;
  }
`;
const Description = styled(Container)`
  ${fontMontserrat}
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  & span {
    font-weight: 700;
    color: ${COLORS.primary1};
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 12px;
    line-height: 16px;
  }
`;
