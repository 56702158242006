import React, { useRef } from 'react';
import { ProjectInfo, ProjectsCommon, ProjectAsideInfo } from '../components';
import { Col, Container, Row } from '../../../ui/layout';
import styled from 'styled-components';
import {
  ill1,
  ill2,
  ill3,
  ill4,
  ill4_tablet,
  project
} from 'assets/projects/schoolVladivostok';
import { VideoBlock } from '../components/VideoBlock';
import { BREAKPOINTS } from '../../../utils';
import { Slider } from 'components/common';

export const SchoolVladivostok = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <ProjectsCommon>
      <Row gap={14}>
        <Col width={'66.66%'} lgWidth={'100%'}>
          <ProjectInfo
            ill={project}
            title={'Школа №1'}
            location={'Владивосток'}
          >
            Сложный, многокомпонентный объем школьного комплекса инкрустирован в
            рельеф участка и своим основанием повторяет его естественную,
            ненарушенную структуру (рисунок рельефа). Данное решение позволяет
            максимально сохранить существующий рельеф, минимизировать
            перемещение земляных масс и сократить время на подготовку участка к
            строительству. Центральный (общешкольный) корпус составляют
            общешкольные пространства, классы для творчества, лектории,
            библиотечная зона, бассейн, спортивные и актовый залы,
            ориентированные на прибрежную полосу.
          </ProjectInfo>
        </Col>

        <Col width={'33.33%'} lgWidth={'100%'}>
          <AsideContainer>
            <AsideIllRow gap={31} lgGap={14} cols={1} lgCols={2} smdCols={1}>
              <Col>
                <ProjectAsideInfo
                  info={[
                    {
                      title: 'Площадь земельного участка',
                      value: '31 496 кв. м'
                    },
                    {
                      title: 'Площадь застройки',
                      value: '10 329 кв. м'
                    },
                    {
                      title: 'Общая площадь здания',
                      value: '25 000 кв. м'
                    },
                    {
                      title: ' Количество учащихся',
                      value: '1275'
                    },
                    {
                      title: 'Количество надземных этажей',
                      value: '3'
                    }
                  ]}
                  minHeight={461}
                />
              </Col>
              <Ill1Wrapper>
                <Img src={ill1} alt={''} />
              </Ill1Wrapper>
            </AsideIllRow>
          </AsideContainer>
        </Col>
      </Row>

      {/* SLIDER */}
      <Container marginTop={60} lgHidden>
        <Slider images={[ill2, ill3, ill4]} />
      </Container>

      <Row gap={30}>
        <Col lgWidth={'100%'}>
          <Ill2WrapperMobile>
            <Img src={ill2} alt={''} />
          </Ill2WrapperMobile>

          <Container marginTop={60} lgMarginTop={26} smdMarginTop={47}>
            Двумя расходящимися лучами общешкольная зона соединена с учебными
            блоками. Учебные блоки размещены в наивысшей точке участка, что
            обеспечивает максимальную инсоляцию классов в течение учебного года.
            Многосветное рекреационное пространство соединяет учебный корпус и
            общешкольные помещения. Рекреация, выполненная в виде живописного
            амфитеатра, соединяет многочастный комплекс в единый организм и
            выступает в роли центрального ядра школы. Здание имеет сложную в
            плане форму с разным количеством этажей.
            <br />
            <br />
            Здание школы сформировано из двух блоков с разделением на
            функциональные группы: учебную и общешкольную.
            <br />
            <br />В свою очередь учебная группа разделена на два блока: блок
            начальной и блок основной и средней школы. Обучение ведется в одну
            смену, в соответствии с программой возрастных классных групп:
            <br />
            <br />
            возрастная классная группа 1-4 классов (начальное общее
            образование).
            <br />
            <br />
            возрастная классная группа 5-9 классов (основное общее образование).
            <br />
            <br />
            возрастная классная группа 10-11 классов (среднее общее
            образование).
            <br />
            <br />
            Общее количество классов – 51, из них в начальной школе– 20 классов
            (500 чел.), в основной школе – 25 классов (625 чел.), в старшей
            школе – 6 классов (150 чел.). Классы рассчитаны на 25 учеников.
            Соотношение количества параллелей по ступеням образования составляет
            5:5:3.
          </Container>
        </Col>
      </Row>

      <Row
        gap={29}
        marginTop={19}
        lgMarginTop={45}
        smdMarginTop={39}
        lgMarginBottom={'0'}
      >
        <Col lgWidth={'100%'}>
          <Container lgMarginBottom={60}>
            <Ill3Wrapper>
              <Img src={ill3} alt={''} />
            </Ill3Wrapper>

            <TextMargin>
              В каждом классе предусматривается одно место для учащегося,
              относящего к МГН.
              <br />
              <br />
              При разработке архитектурных и объемно-планировочных решений
              учитывалось задание заказчика по количеству параллелей
              образовательной организации, состав требуемых помещений в
              соответствии с СП 2.4.3648-20 и СП 118.13330.2012, габариты
              предоставленного земельного участка, существующая и проектируемая
              застройка, инсоляция, действующие нормы и правила. Предельные
              параметры строительства не превышают предусмотренных
              градостроительным планом разрешенных параметров застройки. Для
              обеспечения условий доступа детей с ограниченными возможностями
              здоровья в общеобразовательной организации предусмотрены
              мероприятия по созданию безбарьерной среды.
              <br />
              <br />
              Главные фасады здания с учебными помещениями ориентированы в
              юго-восточном и юго-западном направлении, что обеспечивает
              нормативную инсоляцию учебных помещений не менее 1,5 часа. Все
              основные помещения школы, в том числе с постоянным пребыванием
              людей, запроектированы с естественным освещением.
              <br />
              <br />
              Главный вход в здание расположен с юго-восточного фасада.
              <br />
              <br />
              Комплекс школы разделен на две функциональные зоны-корпуса.
              Центральный корпус составляют общешкольные пространства, классы
              для творчества, лектории, библиотечная зона, бассейн, спортивные и
              актовый залы, ориентированные на прибрежную полосу. Набор
              функциональных групп, состав и площади помещений
              общеобразовательного учреждения соответствуют педагогической
              структуре и назначению. Помещения школы состоят из учебных и
              общешкольных пространств. Количество основных помещений классов и
              классов-кабинетов принято равным количеству учебных групп в
              соответствии с организационно- педагогической структурой. Высота
              мебели учебных помещений соответствует росту и возрасту детей,
              учитывает гигиенические требования.
            </TextMargin>
          </Container>

          <Ill4Wrapper>
            <Img src={ill4} alt={''} />
          </Ill4Wrapper>

          <Container marginTop={60} lgMarginTop={65} smdMarginTop={27}>
            Площадь учебных кабинетов принята из расчета: при фронтальных формах
            занятий не менее 2,5 м2 /уч.
            <br />
            <br />
            при организации групповых форм работы и индивидуальных занятий – не
            менее 3,5 м2/уч;
            <br />
            <br />
            специализированных кабинетов и лаборатории по естественным наукам –
            3,5 м2/уч;
            <br />
            <br />
            кабинета информатики - 4,5 м2 /уч.
            <br />
            <br />
            мастерских по изучению технологий и труда - 4,0-7,0 м2 /уч.
            <br />
            <br />
            Число специализированных учебных кабинетов и предметных кабинетов
            определено учебными часами по каждой дисциплине с дифференциацией на
            теоретические и практические занятия. Количество предметных
            кабинетов определено исходя из количества часов по предметам,
            изучаемых в предметных кабинетах, занятий в спортивном зале,
            количества параллелей классов, предельной и дневной нагрузкой на
            обучающихся.
            <br />
            <br />
            Учебные помещения начальной школы (1–4 классы) располагаются
            частично в правом крыле на 1 этаже, а также занимают половину 2
            этажа учебного корпуса школы. Ученики начальной общеобразовательной
            школы обучаются в закрепленных за каждым классом универсальных
            учебных помещениях, сгруппированных в учебные секции. Также в
            учебном блоке начальных классов запроектированы три универсальных
            помещения для групп продленного дня, кабинет труда. В учебной секции
            для обучающихся первых классов, посещающих группы продленного дня,
            предусмотрены спальни с игровыми зонами. Для учащихся основного
            общего – среднего образования предусмотрена организация
            образовательной деятельности по классно-кабинетной системе.
            <br />
            <br />
            Учебные помещения и кабинеты для 5–11 классов включают: кабинеты
            химии, физики и биологии с практикумами и лаборантскими, кабинеты
            истории, кабинет ОБЖ, классы для занятий по общеобразовательным
            предметам, шесть кабинетов иностранного языка, два кабинета
            информатики с лаборантскими, кабинет музыки, кабинет ИЗО, два
            кабинета трудовой подготовки для девочек (кулинария и домоводство;
            мастерская по обработке тканей и технологии) с подсобным помещением,
            два кабинета трудовой подготовки для мальчиков (слесарная и
            столярная мастерские) с подсобным помещением.
            <br />
            <br />
            Для детей начальных, средних и старших классов, нуждающихся в
            психолого-педагогической помощи, предусмотрены отдельные кабинеты:
            <br />
            <br />
            • кабинет учителя-логопеда
            <br />
            <br />
            • кабинет учителя-логопеда;
            <br />
            <br />В помещениях начальных классов, лаборантских, учебных
            кабинетах химии, физики, ИЗО, биологии, слесарной и столярной
            мастерских, кабинетах домоводства и помещениях медицинского
            назначения установлены умывальные раковины.
            <br />
            <br />
            Слесарная и столярная мастерские расположены на первом этаже здания,
            имеют вход из коридора и общий обособленный выход через тамбур
            непосредственно на улицу. При мастерских предусмотрено помещение
            инструментальной.
            <br />
            <br />
            Рекреационные помещения находятся в непосредственной близости к
            учебным помещениям. Площадь зальной рекреации принята из расчета не
            менее 2,0 м2/уч. Площадь коридорной рекреации принята из расчета не
            менее 0,6 м2/уч.
            <br />
            <br />
            Спортивные залы расположены в общешкольном корпусе школы и имеют
            рассредоточенный доступ из начальной и основной и средней школ через
            рекреационный коридор и переход. Проектом предусмотрены два
            спортивных зала, бассейн, тренажерный зал, зал для хореографии.
            Количество и размеры спортзалов определены исходя из назначения и
            количества учебных часов с учетом разделения использования начальной
            и старшей школы:
            <br />
            <br />
            • спортивный зал размером 18,0х30,0 м (h до низа выступающих
            конструкций 7,0 м) с зоной для болельщиков;
            <br />
            <br />
            • спортивный зал размером 12,0х24,0 м (h до низа выступающих
            конструкций 6,0 м);
            <br />
            <br />
            • зал для хореографии размером 16,80х8,00 м (h до низа выступающих
            конструкций
            <br />
            <br />
            • тренажерный зал размером 19,00х13,00 м (h до низа выступающих
            конструкций 4,0 м).
            <br />
            <br />
            При спортивных залах предусмотрены: снарядные, помещение для
            хранения уборочного инвентаря и приготовления дезинфицирующих и
            моющих растворов, раздевальные, душевые и санузлы для мальчиков и
            девочек. Для МГН в санузлах предусмотрена универсальная кабина, в
            душевой – универсальный душ. При каждом зале есть кабинет учителя
            физкультуры с душевой и санузлом. При туалетах и раздевалках
            оборудованы раковины для мытья рук.
            <br />
            <br />
            Также на 1 этаже расположен бассейн с размером чаши 11,0х25,0 м. При
            нем предусмотрены раздевальные, душевые и санузлы для мальчиков и
            девочек. Для МГН предусмотрены отдельные универсальные кабины с
            санузлом и душем. Рядом расположено помещение инструктора-тренера с
            душем и санузлом, снарядная, кабинет медсестры, комната для хранения
            реагентов, лаборатория анализа воды, зал управления, помещение для
            хранения уборочного инвентаря и приготовления дезинфицирующих и
            моющих растворов.
            <br />
            <br />
            Центральная входная группа учебного корпуса предусматривает
            отдельные входы в здание для начальной и основной школы,
            расположенные по разные стороны от поста охраны.
            <br />
            <br />
            Гардеробные для учащихся предусмотрены раздельные для каждого
            класса. Для учащихся 1–4 классов гардероб расположен в вестибюле со
            стороны входа начальной школы; для учащихся 5–11 классов – в
            вестибюле со стороны входа учеников основной школы.
            <br />
            <br />
            На каждом этаже запроектированы туалеты для персонала и учащихся, в
            том числе для МГН. Количество приборов установлено согласно СП
            251.1325800.2016. Для персонала выделены санузлы из расчета не менее
            1 унитаз на 20 человек. Для персонала и обучающихся основного общего
            и среднего общего образования предусмотрены комнаты личной гигиены.
            <br />
            <br />
            На каждом этаже предусмотрено помещение для хранения и обработки
            уборочного инвентаря, приготовления дезинфекционных растворов,
            оборудованное поддоном и подводкой к нему холодной и горячей воды.
            Общая площадь помещений принята из расчета не менее 0,8 м2 на каждые
            100 м2 полезной площади, но не менее 2,0 м2.
            <br />
            <br />
            На 1 этаже рядом с вестибюлем общешкольного корпуса расположены
            помещения медицинского назначения
            <br />
            <br />
            Помещения медицинского назначения сгруппированы в одном блоке:
            <br />
            <br />
            • кабинет врача;
            <br />
            <br />
            • процедурная и прививочный кабинеты
            <br />
            <br />
            • стоматологический кабинет
            <br />
            <br />
            • помещение для приготовления дезинфицирующих растворов и хранения
            уборочного инвентаря
            <br />
            <br />
            • санузел с учетом размещения МГН на кресле-коляске
            <br />
            <br />
            • кабинет психолога
            <br />
            <br />
            • кабинет логопеда.
            <br />
            <br />
            <br />
            <DesktopBr />
            Справа от вестибюльной группы размещен пищеблок с обеденным залом.
            Для организации питания предусмотрен набор помещений в соответствии
            с санитарно-эпидемиологическими требованиями к организации питания
            обучающихся в общеобразовательных организациях. Площадь обеденного
            зала столовой принята из расчета не менее 0,8 м2 на одно посадочное
            место. Число посадочных мест определено из расчета посадки в одну
            перемену учащихся, а также преподавателей и администрации
            общеобразовательного учреждения (всего 702 посадочных места). Вход в
            обеденный зал предусмотрен для начальных, средних и старших классов
            отдельно.
            <br />
            <br />
            Объемно-планировочные решения пищеблока предусматривают
            последовательность технологических процессов, исключающих встречные
            потоки сырой и готовой продукции. Загрузка продуктов производится
            через отдельный вход с загрузочной. Вход для персонала столовой
            осуществляется непосредственно с улицы со стороны загрузочной.
            <br />
            <br />С 1 этажа общешкольного корпуса запроектирована каскадная
            рекреационная зона, которая является связующим звеном учебной и
            общешкольной части в зоне основной и старшей школы.
            <br />
            <br />
            Из рекреации предусмотрен выход на эксплуатируемую кровлю
            общественного блока, где при благоприятных погодных условиях создана
            возможность проведения уроков на открытом воздухе. Над блоком
            старших классов запланирован выход на смотровую площадку с видом на
            бухту Патрокл.
            <br />
            <br />
            На 2 этаже, справа от актового зала, организован переход в зону
            начальной школы для организации связи учебного и общешкольного
            корпуса.
            <br />
            <br />В центре общешкольной части размещен актовый зал с
            сопутствующими помещениями. Размеры определены числом посадочных
            мест из расчета не менее 0,65 м2 на одно посадочное место. Актовый
            зал занимает два уровня (первый и второй этаж).
            <br />
            <br />
            На 2 этаже общешкольной части размещена библиотек (медиатека),
            являющаяся одним из структурных подразделений школы. Она включает
            совокупность фонда книг и разнообразных технических и информационных
            средств. Общая площадь библиотеки принята по расчетному показателю
            на 1 учащегося не менее 0,6 м2.
            <br />
            <br />В школьной библиотеке предусмотрены зоны:
            <br />
            <br />
            • информационный пункт для выдачи-приема литературы;
            <br />
            <DesktopBr />
            • фонд открытого доступа читателей
            <br />
            <DesktopBr />
            • фонд закрытого хранения литературы
            <br />
            <DesktopBr />
            • место для работы с каталогами
            <br />
            <DesktopBr />
            • читальный зал для начальной школы, оборудованный мультимедийными
            средствами обучения
            <br />
            <DesktopBr />
            • читальный зал для основной школы, оборудованный мультимедийными
            средствами обучения
            <br />
            <DesktopBr />
            • компьютерный зал с лабораторией проектной деятельности.
            <br />
            <br />
            Также на 2 этаже размещены спортивные залы и зал хореографии с
            раздевалками и душевыми.
            <br />
            <br />
            На 2 этаже расположены учебные кабинеты, кабинеты заместителей по
            воспитательной работе и учебной части, кабинет социального педагога.
            <br />
            <br />
            На 3 этаже учебного корпуса школы расположены учебные кабинеты,
            практикумы, лаборантские.
            <br />
            <br />
            Кровля плоская с внутренним водостоком, с эксплуатируемыми
            участками, предназначенными для проведения занятий на открытом
            воздухе и отдыха учащихся в свободное время. Высота ограждений на
            эксплуатируемой кровле – 1200 мм. На кровлю предусмотрены
            рассредоточенные выходы непосредственно из лестничных клеток каждого
            блока.
            <br />
            <br />
            Для инженерного оборудования и хозяйственных нужд предусмотрены
            следующие помещения: в техническом подполье – венткамеры, ИТП,
            насосная и водомерный узел, серверная, помещения для обслуживания
            бассейна на 1 этаже с отдельным входом с улицы, помещение
            электрощитовой..
          </Container>

          {/* <VideoWrapper>
            <VideoBlock
              img={project}
              subtitle={'Видеопрезентация проекта «Школа №1»'}
            />
          </VideoWrapper> */}
        </Col>
      </Row>
    </ProjectsCommon>
  );
};

const AsideContainer = styled.div`
  position: relative;
  margin-right: -14px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin: 0;
    :after {
      content: '';
      position: absolute;
      left: -13px;
      bottom: 0;
      width: calc(100% + 26px);
      height: 1px;
      background: #d9d9d9;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    :after {
      left: -7px;
      width: calc(100% + 14px);
    }
  }
`;
const AsideIllRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 49px;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding-bottom: 56px;
    position: relative;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    flex-direction: column-reverse;
    gap: 32px;
    margin-top: 2px;
    padding-bottom: 23px;
  }
`;
const Img = styled.img`
  width: 100%;
  display: block;
  object-fit: cover;
`;

const TextMargin = styled.p`
  margin-top: 60px;
`;

const Ill1Wrapper = styled(Col)`
  margin-top: 61px;
  > img {
    max-width: 363px;
    margin-left: 15px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: auto;
    flex-grow: unset;
    margin-top: 14px;
    > img {
      max-width: 261px;
      margin: 0;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    > img {
      max-width: unset;
    }
  }
`;

const Ill2WrapperMobile = styled.div`
  display: none;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: block;
    margin-top: 11px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 15px;
  }
`;
const Ill3Wrapper = styled.div`
  display: none;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: block;
  }
`;
const Ill4Wrapper = styled.div`
  display: none;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: block;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 14px;
  }
`;
const VideoWrapper = styled.div`
  margin-top: 60px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 84px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 37px;
  }
`;
const DesktopBr = styled.br`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`;
const DesktopTabletBr = styled.br`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    display: none;
  }
`;
