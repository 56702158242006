export const figure1 = require('./figure1.svg').default;
export const figure2 = require('./figure2.png');
export const map_img = require('./map.svg').default;
export const apartment_icon = require('./apartment_icon.svg').default;
export const apartment_icon_mob = require('./apartment_icon_mob.svg').default;
export const experience_icon = require('./experience_icon.svg').default;
export const experience_icon_mob = require('./experience_icon_mob.png');
export const factory_icon = require('./factory_icon.svg').default;
export const factory_icon_mob = require('./factory_icon_mob.svg').default;
export const professionals_icon = require('./professionals_icon.svg').default;
export const professionals_icon_mob =
  require('./professionals_icon_mob.svg').default;
export const road_icon = require('./road_icon.svg').default;
export const road_icon_mob = require('./road_icon_mob.svg').default;
export const square_icon = require('./square_icon.svg').default;
export const square_icon_mob = require('./square_icon_mob.svg').default;
export const square_buildings_icon =
  require('./square_buildings_icon.svg').default;
export const square_buildings_mob =
  require('./square_buildings_mob.svg').default;
export const stadium_icon = require('./stadium_icon.svg').default;
export const stadium_icon_mob = require('./stadium_icon_mob.svg').default;
export const stadium_arrow = require('./stadium_arrow.svg').default;
export const slider_arrow = require('./slider-arrow.svg').default;
