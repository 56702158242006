import React from 'react';
import styled from 'styled-components';
import { Container, Img, MainContainer } from 'ui/layout';
import { BREAKPOINTS, COLORS } from 'utils';
import illustration from 'assets/nextPurposes/illustration_tablet.png';

const POINTS = [
  {
    text: 'Создать легко масштабируемую модель бизнеса.',
    bg: '#23B5B6',
    color: COLORS.white,
    paddingTop: 85,
    smPaddingTop: 41
  },
  {
    text: 'Стать крупным игроком на рынке концессий дорожного и промышленно-гражданского строительства.',
    bg: '#0C67B1',
    marginTop: -6,
    color: COLORS.white,
    paddingTop: 91,
    smMarginTop: -3,
    smPaddingTop: 44
  },
  {
    text: 'Занять лидирующие позиции в сфере контрактов жизненного цикла по объектам гражданского строительства.',
    bg: '#EAE98C',
    marginTop: -7,
    paddingTop: 96,
    smMarginTop: -3,
    smPaddingTop: 46
  }
];

export const PurposesTablet = (props) => (
  <Wrapper {...props}>
    {POINTS.map((item, key) => (
      <Point
        key={key}
        $bg={item.bg}
        $color={item.color || COLORS.default}
        $zIndex={POINTS.length - key}
        marginTop={item.marginTop}
        paddingTop={item.paddingTop}
        smMarginTop={item.smMarginTop}
        smPaddingTop={item.smPaddingTop}
      >
        <MainContainer>
          <InnerContainer>
            <Number>{key + 1}</Number>
            <Description $color={item.color || COLORS.default}>
              {item.text}
            </Description>
          </InnerContainer>
        </MainContainer>
      </Point>
    ))}

    <Illustration src={illustration} alt={''} />
  </Wrapper>
);

const Wrapper = styled(Container)`
  display: none;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: block;
  }
`;
const Point = styled(Container)`
  height: 308px;
  width: 100%;
  ${(p) => (p.$bg ? `background: ${p.$bg};` : '')}
  ${(p) => (p.$zIndex ? `z-index: ${p.$zIndex};` : '')}
  position: relative;
  ${(p) => (p.$color ? `color: ${p.$color};` : '')}

  :after {
    content: '';
    position: absolute;
    left: -4px;
    bottom: -90px;
    width: 0;
    height: 0;
    border-left: calc(50vw + 3px) solid transparent;
    border-right: calc(50vw + 3px) solid transparent;
    border-top: 91px solid ${(p) => p.$bg};
    z-index: -1;
  }

  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 146px;

    :after {
      bottom: -43px;
      border-top: 44px solid ${(p) => p.$bg};
    }
  }
`;
const InnerContainer = styled.div`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    max-width: 275px;
  }
`;
const Number = styled.div`
  font-weight: 600;
  font-size: 100px;
  line-height: 110px;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 50px;
    line-height: 55px;
  }
`;
const Description = styled.div`
  border-top: 1px solid ${(p) => p.$color};
  margin-top: -1px;
  padding-top: 19px;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin-top: -4px;
    padding-top: 9px;
    text-align: center;
  }
`;
const Illustration = styled(Img)`
  width: 100%;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: -120px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    margin-top: -10px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin-top: -2px;
  }
`;
