import React, { useRef } from 'react';
import { BREAKPOINTS } from '../../utils';
import styled from 'styled-components';
import { Header, Footer } from '../../components/common';
import { Col, Container, Img, MainContainer, Row } from '../../ui/layout';
import { TeamCard } from './TeamCard';
import member1 from '../../assets/team/member1.png';
import member2 from '../../assets/team/member2.png';
import member3 from '../../assets/team/member3.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import arrow_icon from '../../assets/common/arrow_right_black.svg';
import ill1 from '../../assets/partners/ill1.png';

const DIRECTORS = [
  {
    photo: member1,
    name: 'Беляков Дмитрий Дмитриевич',
    position: 'Генеральный директор',
    descr: 'Характеристика как профессионала'
  },
  {
    photo: member2,
    name: 'Потоцкий Владислав Игоревич',
    position: 'Исполнительный директор',
    descr: 'Характеристика как профессионала'
  },
  {
    photo: member3,
    name: 'Емельянов Андрей Анатольевич',
    position: 'Директор департамента\n по кап. строительству',
    descr: 'Характеристика как профессионала'
  }
];

const MANAGERS = [
  {
    photo: member1,
    name: 'Иванов Иван Иванович',
    position: 'Менеджер',
    descr: 'Характеристика как профессионала'
  },
  {
    photo: member1,
    name: 'Иванов Иван Иванович',
    position: 'Бухгалтер',
    descr: 'Характеристика как профессионала'
  },
  {
    photo: member1,
    name: 'Иванов Иван Иванович',
    position: 'Главный инженер',
    descr: 'Характеристика как профессионала'
  },
  {
    photo: member1,
    name: 'Иванов Иван Иванович',
    position: 'Менеджер',
    descr: 'Характеристика как профессионала'
  },
  {
    photo: member1,
    name: 'Иванов Иван Иванович',
    position: 'Оператор',
    descr: 'Характеристика как профессионала'
  },
  {
    photo: member1,
    name: 'Иванов Иван Иванович',
    position: 'Главный инженер',
    descr: 'Характеристика как профессионала'
  },
  {
    photo: member1,
    name: 'Иванов Иван Иванович',
    position: 'Оператор',
    descr: 'Характеристика как профессионала'
  }
];

export const TeamPage = () => {
  const sliderDirectors = useRef(null);
  const sliderManagers = useRef(null);

  const slidePrev1 = () => {
    try {
      sliderDirectors.current.swiper.slidePrev();
    } catch (e) {}
  };
  const slideNext1 = () => {
    try {
      sliderDirectors.current.swiper.slideNext();
    } catch (e) {}
  };

  const slidePrev2 = () => {
    try {
      sliderManagers.current.swiper.slidePrev();
    } catch (e) {}
  };
  const slideNext2 = () => {
    try {
      sliderManagers.current.swiper.slideNext();
    } catch (e) {}
  };

  return (
    <Wrapper>
      <Header active={'team'} />

      <ContentWrapper>
        <MainContainer>
          <Row gap={32}>
            <Col width={'32%'} xlWidth={'60%'} lgWidth={'100%'}>
              <H1>Команда</H1>

              <Container marginTop={51} lgMarginTop={53} smdMarginTop={28}>
                Мы – команда профессионалов, объединённых общей созидательной
                работой в интересах страны.
                <br />
                <br />
                Мы строим масштабные объекты социальной инфраструктуры, изменяем
                пространство, делая его более комфортным и удобны для людей, при
                этом, бережно сохраняя изначальную эко-систему.
                <br />
                <br />
                Мы вносим свой вклад в развитие нашей Родины, создавая фундамент
                для перспективного развития и процветания следующих поколений.
              </Container>
            </Col>

            <Col width={'68%'} xlWidth={'100%'} xlHidden>
              <DirectorsRow gap={24} nowrap>
                {DIRECTORS.map((item) => (
                  <Col auto>
                    <TeamCard data={item} />
                  </Col>
                ))}
              </DirectorsRow>
            </Col>
          </Row>
        </MainContainer>

        <DirectorsContainer>
          <MainContainer hidden lgVisible>
            <Row gap={17} smdGap={8} nowrap>
              <ArrowLeft onClick={slidePrev1} src={arrow_icon} alt={''} />
              <ArrowRight onClick={slideNext1} src={arrow_icon} alt={''} />
            </Row>
          </MainContainer>

          <StyledSwiper
            spaceBetween={23}
            slidesPerView={'auto'}
            ref={sliderDirectors}
            breakpoints={{
              [BREAKPOINTS.smd]: {
                spaceBetween: 48
              }
            }}
          >
            {DIRECTORS.map((item, key) => {
              return (
                <SwiperSlide key={key}>
                  <TeamCard data={item} />
                </SwiperSlide>
              );
            })}
          </StyledSwiper>
        </DirectorsContainer>

        <ManagersContainer>
          <MainContainer>
            <Row gap={17} smdGap={8} nowrap>
              <ArrowLeft onClick={slidePrev2} src={arrow_icon} alt={''} />
              <ArrowRight onClick={slideNext2} src={arrow_icon} alt={''} />
            </Row>
          </MainContainer>

          <StyledSwiper
            spaceBetween={23}
            slidesPerView={'auto'}
            ref={sliderManagers}
            breakpoints={{
              [BREAKPOINTS.smd]: {
                spaceBetween: 48
              }
            }}
          >
            {MANAGERS.map((item, key) => {
              return (
                <SwiperSlide key={key}>
                  <TeamCard data={item} small />
                </SwiperSlide>
              );
            })}
          </StyledSwiper>
        </ManagersContainer>
      </ContentWrapper>

      <TopSection>
        <MainContainer>
          <TopSectionRow>
            <Col width={'35%'} lgWidth={'100%'}>
              <Ill1 src={ill1} alt={''} />
            </Col>
            <Col width={'65%'} lgWidth={'100%'}>
              <StyledH1>
                Корпоративная
                <br />
                культура компании
              </StyledH1>
              <DescrContainer>
                Корпоративная культура нашей компании основана на принципе
                «Профессионализм во всем». Это правило корпоративной культуры
                важно и ценно для каждого из нас!
                <Container marginTop={32} smdMarginTop={10}>
                  Мы ценим каждого сотрудника и дорожим отношениями с нашими
                  сотрудниками.
                </Container>
                <ColoredContainer>
                  Сфера деятельности компании предполагает постоянный рост и
                  совершенствование наших сотрудников, как профессионалов и
                  поэтому у нас предусмотрены инвестиции в их развитие.
                </ColoredContainer>
              </DescrContainer>
            </Col>
          </TopSectionRow>
        </MainContainer>
      </TopSection>

      <Footer />
    </Wrapper>
  );
};

const StyledH1 = styled.h1`
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 38px;
    line-height: 46px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 25px;
    line-height: 30px;
  }
`;
const Wrapper = styled.div`
  font-size: 16px;
  line-height: 21px;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 16px;
    line-height: 21px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 12px;
    line-height: 15px;
  }
`;
const ContentWrapper = styled.div`
  position: relative;
  padding-top: 44px;
  padding-bottom: 94px;

  :before {
    content: '';
    position: absolute;
    left: -76px;
    top: 0;
    height: 89px;
    width: 100px;
    background: #e1f5f5;
    transform: skew(-30deg);
  }

  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding-top: 53px;
    padding-bottom: 113px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-top: 31px;
    padding-bottom: 32px;
    :before {
      height: 26px;
      left: -90px;
      top: 32px;
    }
  }
`;
const H1 = styled.h1`
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 38px;
    line-height: 46px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 25px;
    line-height: 30px;
  }
`;
const DirectorsRow = styled(Row)`
  justify-content: flex-end;
  margin-top: 46px;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    justify-content: flex-start;
  }
`;
const DirectorsContainer = styled.div`
  display: none;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    display: block;
    margin-top: 60px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 23px;
  }
`;
const ManagersContainer = styled.div`
  margin-top: 48px;
  position: relative;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 86px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 29px;
  }
`;
const ArrowLeft = styled(Img)`
  width: 46px;
  height: 46px;
  box-sizing: content-box;
  z-index: 5;

  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 22px;
    height: 22px;
  }
`;
const ArrowRight = styled(ArrowLeft)`
  transform: rotate(180deg);
`;
const StyledSwiper = styled(Swiper)`
  margin-top: 27px;
  padding: 0 calc((100% - 1200px) / 2);
  .swiper-slide {
    width: 230px;
  }

  :after,
  :before {
    content: '';
    position: absolute;
    left: 0;
    top: -5px;
    width: calc((100% - 1200px - 2px) / 2);
    height: 584px;
    background: rgba(255, 255, 255, 0.5);
    z-index: 3;
  }
  :after {
    left: unset;
    right: 0;
  }

  @media (max-width: ${BREAKPOINTS.xl}px) {
    padding: 0 calc((100% - 950px) / 2);
    :after,
    :before {
      width: calc((100% - 950px - 2px) / 2);
    }
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding: 0 calc((100% - 700px) / 2);
    :after,
    :before {
      width: calc((100% - 700px - 2px) / 2);
    }
    margin-top: 16px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    :after,
    :before {
      width: calc((100% - 581px - 2px) / 2);
    }
    padding: 0 calc((100% - 581px) / 2);
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding: 0 calc((100% - 410px) / 2);
    margin-top: 8px;
    .swiper-slide {
      width: 109px;
    }
    :after,
    :before {
      width: calc((100% - 410px - 2px) / 2);
    }
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding: 0 calc((100% - 329px - 2px) / 2);
    :after,
    :before {
      width: calc((100% - 329px - 2px) / 2);
    }
  }
  @media (max-width: 369px) {
    padding: 0 20px;
    :after,
    :before {
      width: 19px;
    }
  }
`;

const TopSection = styled.div`
  padding-bottom: 107px;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding-bottom: 0;
  }
`;
const TopSectionRow = styled(Row)`
  padding-top: 132px;
  :after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 1px;
    width: 100%;
    background: #bdbdbd;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding-top: 53px;
    flex-direction: column-reverse;
    :after {
      width: 100%;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-top: 30px;
  }
`;
const DescrContainer = styled.div`
  margin-top: 68px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 60px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 29px;
  }
`;
const ColoredContainer = styled.div`
  margin-top: 34px;
  padding: 27px 31px 33px 27px;
  background: #0c66b0;
  color: #ffffff;
  font-size: 18px;
  line-height: 23px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 29px;
    padding: 28px 23px 42px 20px;
    font-size: 16px;
    line-height: 21px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 15px;
    padding: 15px 26px 23px 22px;
    font-size: 12px;
    line-height: 16px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 100vw;
    margin-left: calc((100% - 100vw) / 2);
  }
`;
const Ill1 = styled(Img)`
  position: absolute;
  bottom: 0;
  left: -212px;
  max-height: calc(100% + 70px);
  width: 677px;
  object-fit: contain;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    left: -290px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    position: relative;
    height: 482px;
    margin-top: 38px;
    left: -110px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    height: 246px;
    width: 346px;
    left: -40px;
    margin-top: 20px;
  }
`;
