export const ill1 = require('./ill1-min.png');
export const ill2 = require('./ill2-min.png');
export const ill3 = require('./ill3-min.png');
export const ill4 = require('./ill4-min.png');
export const ill5 = require('./ill5-min.png');
export const ill6 = require('./ill6-min.png');
export const ill7 = require('./ill7-min.png');
export const ill8 = require('./ill8-min.png');
export const ill9 = require('./ill9-min.png');
export const ill10 = require('./ill10-min.png');
export const ill11 = require('./ill11-min.png');
export const ill12 = require('./ill12-min.png');
export const ill13 = require('./ill13-min.png');
export const ill14 = require('./ill14-min.png');
export const ill15 = require('./ill15-min.png');
