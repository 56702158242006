import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../../../utils';

export const ProjectAsideInfo = ({ info, minHeight }) => {
  if (info) {
    return (
      <Wrapper minHeight={minHeight}>
        {info.map((item, key) => (
          <InfoPair key={key}>
            <StrongText>{item.title}</StrongText>
            {item.value}

            {item.details &&
              item.details.map((detail, key) => (
                <div key={key}>
                  <StrongText>{detail.title}</StrongText>
                  {detail.value}
                </div>
              ))}
          </InfoPair>
        ))}
      </Wrapper>
    );
  }

  return null;
};

const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #d9d9d9;
  padding: 22px 14px 30px 18px;
  ${(p) => (p.minHeight ? `min-height: ${p.minHeight}px;` : '')}

  @media (max-width: ${BREAKPOINTS.lg}px) {
    border: none;
    padding: 0;
    min-height: unset;
  }
`;
const InfoPair = styled.div`
  :not(:first-child) {
    margin-top: 20px;
  }

  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: #23222f;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 24px;
    line-height: 31px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 16px;
    line-height: 21px;
  }
`;
const StrongText = styled.p`
  font-weight: 500;
`;
