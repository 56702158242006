export const ill1 = require('./ill1.png');
export const ill2 = require('./ill2.png');
export const ill3 = require('./ill3.png');
export const ill4 = require('./ill4.png');
export const ill5 = require('./ill5.png');
export const ill6 = require('./ill6.png');
export const ill7 = require('./ill7.png');
export const ill8 = require('./ill8.png');
export const ill9 = require('./ill9.png');
export const ill10 = require('./ill10.png');
