import React from 'react';
import {
  Col,
  Container,
  Img,
  MainContainer,
  PageContainer,
  Row
} from 'ui/layout';
import { fontMagra, fontMontserrat, H1, H4 } from 'ui/typography';
import { Purposes, PurposesTablet } from './blocks';
import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils';
import {
  idea_icon,
  ill1,
  ill1_tablet,
  ill2,
  ill2_tablet,
  ill3,
  ill4,
  ill5,
  man1,
  man2,
  fig1,
  fig2,
  fig3
} from 'assets/nextPurposes';

export const NextPurposes = (props) => {
  return (
    <Container {...props}>
      <MainContainer>
        <Title lineHeight={53}>
          <div>
            Перспективные цели по результатам реализации стратегии «Альянс –
            2022{'\u2060'}-{'\u2060'}2023»
          </div>
        </Title>
      </MainContainer>

      <Purposes marginTop={67} />
      <PurposesTablet marginTop={46} smMarginTop={30} />

      <PageContainer marginTop={32} lgMarginTop={'0'}>
        <Row>
          <FirstCol width={'50%'} smdWidth={'100%'}>
            <Row>
              <Col width={'50%'} xlHidden style={{ background: '#FAB43A' }}>
                <Block bg={'#93DBDB'} relative>
                  <FigureImg1 src={fig1} alt={''} />
                </Block>
                <Block bgUrl={ill1} relative />
                <Block bg={'#FAB43A'} relative>
                  <FigureImg2 src={fig2} alt={''} />
                </Block>
              </Col>

              <Col width={'50%'} xlWidth={'100%'}>
                {/* <Block
                  bg={'#FAB43A'}
                  xlHeight={200}
                  paddingBottom={'0'}
                  relative
                >
                  <Row
                    direction={'column'}
                    spaceBetween
                    height={'100%'}
                    minHeight={'400px'}
                    xlMinHeight={'unset'}
                  >
                    <BlockInner
                      paddingTop={40}
                      paddingRight={18}
                      paddingLeft={46}
                      xlMaxWidth={'262px'}
                      xlAlignRight
                      xlPaddingX={'0'}
                      xlPaddingTop={34}
                      smdPaddingTop={37}
                    >
                      <Img src={idea_icon} width={54} block alt={''} />
                      <SubTitle
                        marginTop={13}
                        xlMarginTop={19}
                        smdMarginTop={16}
                      >
                        Новые продуктовые ориентиры.
                      </SubTitle>
                    </BlockInner>
                    <Block
                      bgUrl={ill2}
                      height={189}
                      paddingTop={19}
                      paddingRight={18}
                      paddingLeft={46}
                      xlHidden
                    >
                      <SubTitle>Команда профессионалов своего дела.</SubTitle>
                    </Block>
                  </Row>
                  <Figure2Tablet src={fig2} alt={''} />
                </Block> */}
                <Block
                  bg={'#23B5B6'}
                  height={'800'}
                  smdHeight={449}
                  paddingTop={41}
                  paddingRight={26}
                  paddingLeft={46}
                  color={COLORS.white}
                  xlPaddingTop={55}
                >
                  <BlockInner xlMaxWidth={'261px'} xlAlignRight>
                    <SubTitle>Географическая экспансия</SubTitle>
                    <Container
                      marginTop={27}
                      xlMarginTop={16}
                      smdMarginTop={40}
                    >
                      <Text>
                        Сложившаяся на рынке ситуация формирует для ГК «Альянс»
                        новые возможности для активного расширения географии
                        своего присутствия.
                        <br />
                        <br />
                        Основной формой географической экспансии ГК «Альянс»
                        является работа в партнерстве с сильным локальным
                        игроком при обязательном привлечении нескольких местных
                        субподрядчиков на отдельные виды работ, с целью
                        равномерного распределения операционных и финансовых
                        рисков.
                      </Text>
                    </Container>
                  </BlockInner>
                </Block>
              </Col>

              <Block bgUrl={ill3} xlHeight={200} smdHidden />

              <Block bg={'#29235C'} width={'50%'} relative xlHidden>
                <ManImg1 src={man1} alt={''} />
              </Block>
              <Col width={'50%'} xlWidth={'100%'} smdHidden>
                <EvolutionPoints />
              </Col>
            </Row>
          </FirstCol>

          <SecondCol width={'25%'} xlWidth={'50%'} smdWidth={'100%'}>
            {/* <Block
              bgUrl={ill2}
              mdBgUrl={ill2_tablet}
              height={200}
              paddingTop={19}
              paddingRight={18}
              paddingLeft={46}
              xlPaddingTop={22}
              hidden
              xlVisible
            >
              <BlockInner
                xlMaxWidth={'199px'}
                smdMaxWidth={'270px'}
                smMaxWidth={'207px'}
                smdAlignLeft
              >
                <SubTitle smdMarginLeft={71} smMarginLeft={8}>
                  Команда профессионалов своего дела.
                </SubTitle>
              </BlockInner>
            </Block> */}
            <Block
              bg={'#0C67B1'}
              paddingTop={116}
              paddingRight={34}
              paddingLeft={46}
              xlPaddingTop={28}
              color={COLORS.white}
              lineHeight={22}
              xlLineHeight={19}
              smdLineHeight={14}
              xlHeight={200}
              smdHeight={163}
              relative
            >
              <BlockInner
                xlMaxWidth={'261px'}
                smdPaddingLeft={5}
                smdPaddingRight={8}
              >
                <SubTitle>
                  Команда профессионалов своего дела. Комфортная и справедливая
                  корпоративная среда
                </SubTitle>
                <Container marginTop={27}>
                  <Text>
                    Для привлечения лучших специалистов, позволяющие каждому
                    сотруднику реализовать свой потенциал.
                  </Text>
                </Container>
              </BlockInner>

              <Figure1Mob src={fig1} alt={''} />
            </Block>
            <Block
              bg={'#29235C'}
              color={COLORS.white}
              paddingTop={82}
              paddingRight={32}
              paddingLeft={48}
              xlPaddingTop={51}
              lineHeight={22}
              xlLineHeight={19}
              smdLineHeight={14}
              xlHeight={294}
              smdHeight={232}
            >
              <BlockInner xlMaxWidth={'260px'} smdPaddingLeft={5}>
                <SubTitle display={'inline'}>
                  Участие в проектах промышленно-гражданского и
                  инфраструктурного строительства
                </SubTitle>
                <Container marginTop={27}>
                  <Text>
                    ГК «Альянс» реализует строительные проекты в форме
                    государственно-частного партнерства и в рамках
                    государственного заказа - контрактах жизненного цикла.
                  </Text>
                </Container>
              </BlockInner>
            </Block>

            <Block bgUrl={ill3} height={189} hidden smdVisible />
            <EvolutionPoints hidden smdVisible />

            <Block
              bg={'#FAB43A'}
              paddingTop={101}
              paddingRight={32}
              paddingLeft={46}
              xlPaddingTop={41}
              smdPaddingTop={72}
              height={1200}
              xlHeight={906}
              smdHeight={627}
              relative
            >
              <BlockInner xlMaxWidth={'320px'} smdPaddingLeft={5}>
                <SubTitle maxWidth={208}>
                  Увеличение доходности бизнеса
                </SubTitle>
                <Container marginTop={28} xlMarginTop={17} smdMarginTop={18}>
                  <Text>
                    Фокусирование на долгосрочном пополнении портфеля проектов.
                    Выявление инфраструктурных потребностей регионов и
                    предложение своевременных решений.
                    <br />
                    <br />
                    Жесткий контроль издержек на всех этапах цепочки
                    формирования стоимости. Внедрение трехчастной системы
                    контроля, базирующейся на внутренних компетенциях Группы,
                    внутригрупповом и внешнем (независимом) строительном
                    аудите/финансово-техническом контроле (ИНТЭНС).
                    <br />
                    <br />
                    Применение проектного управления для идентификации и
                    снижения транзакционных расходов, а также усиления контроля
                    за расходами.
                    <br />
                    <br />
                    Совершенствование ИТ инструментов планирования, учета и
                    контроля. Формирование единого информационного пространства
                    для планирования и контроля финансовой деятельности Группы.
                    <br />
                    <br />
                    Совершенствование методов анализа, контроля и управления
                    основными рисками в деятельности Группы. Внедрение
                    методологии риск-менеджмента, изменение внутренних
                    нормативных документов с учетом требований риск-менеджмента.
                  </Text>
                </Container>
              </BlockInner>
              <Figure1Mob2 src={fig1} alt={''} />
            </Block>
          </SecondCol>

          <Col width={'25%'} xlHidden style={{ background: '#93DBDB' }}>
            <Block bgUrl={ill4} />
            <Block bg={'#0C67B1'} relative>
              <FigureImg3 src={fig3} alt={''} />
            </Block>
            <Block bg={'#EFEFEF'} relative>
              <ManImg2 src={man2} alt={''} />
            </Block>
            <Block bg={'#93DBDB'} relative>
              <FigureImg4 src={fig1} alt={''} />
            </Block>
            <Block bgUrl={ill5} />
          </Col>
        </Row>

        <Block
          bgUrl={ill1_tablet}
          height={400}
          smdHeight={185}
          hidden
          xlVisible
        ></Block>
      </PageContainer>
    </Container>
  );
};

const EvolutionPoints = (props) => (
  <Block
    bg={'#0C67B1'}
    paddingTop={50}
    paddingRight={38}
    paddingLeft={46}
    color={COLORS.white}
    lineHeight={22}
    xlPaddingTop={43}
    xlLineHeight={21}
    smdHeight={271}
    smdPaddingTop={32}
    smdLineHeight={15}
    {...props}
  >
    <BlockInner xlMaxWidth={'262px'} xlAlignRight smdPaddingLeft={5}>
      <SubTitle>Точки развития</SubTitle>
      <Container marginTop={32} xlMarginTop={35} smdMarginTop={47}>
        <Text>
          Устойчивая финансовая позиция.
          <br />
          <br />
          Проектное управление.
          <br />
          <br />
          Собственные строительные мощности.
          <br />
          <br />
          Высокопрофессиональная команда.
          <br />
          <br />
          Корпоративная культура.
        </Text>
      </Container>
    </BlockInner>
  </Block>
);

const Title = styled(H1)`
  ${fontMagra}
  @media (max-width: ${BREAKPOINTS.lg}px) {
    max-width: 549px;
    line-height: 33px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    line-height: 22px;
    padding-left: 26px;
  }
`;

const SubTitle = styled(H4)`
  font-size: 24px;
  ${fontMagra}
`;

const Text = styled.p`
  ${fontMontserrat}
`;

const FirstCol = styled(Col)`
  background: #0c67b1;
`;
const SecondCol = styled(Col)`
  background: #fab43a;
`;
const Block = styled(Container)`
  min-height: ${(p) => (p.height ? `${p.height}px` : '400px')};
  width: ${(p) => (p.width ? p.width : '100%')};
  ${(p) => (p.bg ? `background: ${p.bg};` : '')}
  ${(p) =>
    p.bgUrl ? `background: url("${p.bgUrl}") center/cover no-repeat;` : ''}
  ${(p) => (p.color ? `color: ${p.color};` : '')}
  ${(p) => (p.lineHeight ? `line-height: ${p.lineHeight}px;` : '')}
  padding-bottom: ${(p) => (p.paddingBottom ? `${p.paddingBottom}px` : '18px')};

  @media (max-width: ${BREAKPOINTS.xl}px) {
    ${(p) => (p.xlHeight ? `min-height: ${p.xlHeight}px;` : '')}
    padding-left: 80px;
    padding-right: 80px;
    ${(p) => (p.xlLineHeight ? `line-height: ${p.xlLineHeight}px;` : '')}
  }

  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding-left: 31px;
    padding-right: 28px;
  }

  @media (max-width: ${BREAKPOINTS.md}px) {
    ${(p) =>
      p.mdBgUrl ? `background: url("${p.mdBgUrl}") top/cover no-repeat;` : ''}
  }

  @media (max-width: ${BREAKPOINTS.smd}px) {
    ${(p) => (p.smdHeight ? `min-height: ${p.smdHeight}px;` : '')}
    padding-left: 20px;
    padding-right: 20px;
    ${(p) => (p.smdLineHeight ? `line-height: ${p.smdLineHeight}px;` : '')}
  }
`;
const BlockInner = styled(Container)`
  ${(p) => (p.xlAlignRight ? 'margin-left: auto;' : '')}

  @media (max-width: ${BREAKPOINTS.smd}px) {
    max-width: ${(p) => (p.smdMaxWidth ? p.smdMaxWidth : '329px')};
    ${(p) =>
      p.smdAlignLeft
        ? `
    margin-left: 0;
   `
        : `
    margin-left: auto;
    margin-right: auto;
   `}
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${(p) => (p.smMaxWidth ? `max-width: ${p.smMaxWidth};` : '')}
  }
`;
const ManImg1 = styled(Img)`
  position: absolute;
  right: -61px;
  bottom: -1px;
  width: 522px;
`;
const ManImg2 = styled(ManImg1)`
  right: 58px;
  width: 509px;
  @media (max-width: ${BREAKPOINTS.xxl}px) {
    right: 10px;
  }
`;
const FigureImg1 = styled(Img)`
  position: absolute;
  width: 297px;
  bottom: -74px;
  left: -121px;
`;
const FigureImg2 = styled(FigureImg1)`
  width: 415px;
  bottom: -185px;
  left: -156px;
`;
const FigureImg3 = styled(FigureImg1)`
  top: -132px;
  left: -143px;
`;
const FigureImg4 = styled(FigureImg1)`
  width: 576px;
  top: -54px;
  left: unset;
  right: -329px;
`;
const Figure2Tablet = styled(FigureImg1)`
  display: none;
  width: 216px;
  bottom: -94px;
  left: -122px;

  @media (max-width: ${BREAKPOINTS.xl}px) {
    display: block;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    left: -144px;
  }
`;
const Figure1Mob = styled(FigureImg1)`
  display: none;
  width: 148px;
  bottom: -65px;
  right: -83px;
  left: unset;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    display: block;
  }
`;
const Figure1Mob2 = styled(Figure1Mob)`
  width: 202px;
  top: -90px;
  bottom: unset;
  right: -128px;
`;
