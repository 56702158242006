import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../../../utils';
import { Col, Row } from '../../../../ui/layout';
import geo_icon from 'assets/common/geo.svg';

export const ProjectInfo = ({ ill, location, title, children }) => {
  return (
    <Article>
      <Illustration src={ill} alt={''} />

      <Title>{title}</Title>
      <Location>
        <GeoIcon src={geo_icon} alt={''} />
        <p>{location}</p>
      </Location>
      <Text>{children}</Text>
    </Article>
  );
};

const Article = styled.div`
  border: 1px solid #d9d9d9;
  margin-left: -10px;
  margin-right: -3px;
  padding: 12px 13px 22px 9px;
  background: #ffffff;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding: 14px 11px 32px 12px;
    margin-right: -12px;
    margin-left: -14px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding: 6px 7px 21px 6px;
    margin-right: -8px;
    margin-left: -8px;
  }
`;
const Img = styled.img`
  width: 100%;
  display: block;
`;
const Illustration = styled(Img)`
  margin-bottom: 44px;
  object-fit: cover;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-bottom: 48px;
    max-height: 321px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-bottom: 32px;
    max-height: 183px;
  }
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  color: #23222f;
  margin-bottom: 6px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 12px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 8px;
  }
`;
const Location = styled(Row)`
  margin-bottom: 32px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 24px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 12px;
  }
`;
const GeoIcon = styled.img`
  width: 13px;
  height: 18px;
  margin-right: 8px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 12px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 8px;
    height: 11px;
    margin-right: 6px;
  }
`;

const Text = styled.p`
  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 24px;
    line-height: 31px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 16px;
    line-height: 21px;
  }
`;
