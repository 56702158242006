import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { SkewedLine } from 'components';
import {
  Title,
  Figure,
  Illustration,
  StyledMainContainer,
  SlideRow,
  IllustrationWrapper,
  TextWrapper,
  NavLeft,
  NavRight,
  PaginationDot,
  PaginationRow
} from './style';
import { Col, Container } from 'ui/layout';
import { Text25 } from 'ui/typography';
import { BREAKPOINTS } from 'utils';
import figure from 'assets/technologies/figure.svg';
import ill1 from 'assets/technologies/ill1.svg';
import ill2 from 'assets/technologies/ill2.svg';
import ill3 from 'assets/technologies/ill3.svg';
import ill4 from 'assets/technologies/ill4.svg';
import ill5 from 'assets/technologies/ill5.svg';
import ill6 from 'assets/technologies/ill6.svg';
import ill7 from 'assets/technologies/ill7.svg';
import ill8 from 'assets/technologies/ill8.svg';
import ill9 from 'assets/technologies/ill9.svg';
import ill10 from 'assets/technologies/ill10.svg';
import ill5_tablet from 'assets/technologies/ill5_tablet.svg';
import arrow_left from 'assets/technologies/arrow_left.svg';

const SLIDES = [
  {
    text: 'Технология скоростной бесшовной укладки асфальтобетонных смесей с применением широкозахватного асфальтоукладчика, оснащенного активной жесткой плитой, обеспечивающей укладку шириной до 12 м. с использованием длиннобазовых (13 м.) ультрозвуковых и лазерных систем нивелирования Moba-Matic для обеспечения ровности асфальтобетонных покрытий. Достигаемая скорость укладки до 3,2 погонных метра.',
    textWidth: 480,
    ill: { icon: ill1, width: 382, top: 142, left: 80 },
    lg: { textWidth: 318, ill: { width: 194, top: 72, left: 41 } },
    sm: { ill: { width: 92, top: 34, left: 20 } }
  },
  {
    text:
      'Укрепление существующих дорожных одежд методом ресайклинга:\n' +
      '\n' +
      '• увеличение срока службы;\n' +
      '• достижение темпа работ до 300м/сутки;\n' +
      '• повышение несущей способности основания;\n' +
      '• снижение материалоемкости и энергоемкости;\n' +
      '• достижение проектной прочности основания.\n',
    textWidth: 474,
    ill: { icon: ill2, width: 397, top: 39, left: 71 },
    lg: { textWidth: 318, ill: { width: 202, top: 20, left: 36 } },
    sm: { ill: { width: 96, top: 9, left: 17 } }
  },
  {
    text:
      `Применение современных технологий управления работой дорожно-строительной техники, оборудованной 3D системами нивелирования \nGPS/ГЛОНАСС:\n` +
      '\n' +
      '• исключение перерасхода материалов;\n' +
      '• повышение точности воспроизведения высотных отметок до 1 см. с 5 см. (без системы);\n' +
      '• получение возможности качественно выполнять работы в темное время суток;\n' +
      '• повышение эффективности и качества работ;\n' +
      '• сокращение объемов геодезических работ.\n',
    ill: { icon: ill3, width: 339, top: 52, left: 110, zIndex: 1 },
    lg: { textWidth: 318, ill: { width: 172, top: 26, left: 56 } },
    sm: { ill: { width: 82, top: 13, left: 27 } }
  },
  {
    text:
      'Использование систем автоматического контроля за режимом уплотнения: \n' +
      '\n' +
      '• контроль в режиме онлайн работы уплотняющей техники; \n' +
      '• повышение однородности покрытия по плотности; \n' +
      '• повышение эффективности использования уплотняющей техники; \n' +
      '• снижение влияния человеческого фактора за счет визуального контроля проходов катка в определенной точке уплотняемого покрытия.',
    textWidth: 474,
    ill: { icon: ill4, width: 352, top: 131, left: 155 },
    lg: { textWidth: 318, ill: { width: 179, top: 66, left: 79 } },
    sm: { ill: { width: 85, top: 32, left: 38 } }
  },
  {
    text:
      'Технология строительства покрытий из дренирующего асфальтобетона:\n' +
      '\n' +
      '• повышение безопасности дорожного движения;\n' +
      '• технология устройства покрытия аналогичная устройству покрытий из ЩМА;\n' +
      '• высокая стойкость к колееобразованию вследствие абразивного износа;\n' +
      '• обеспечение видимости;\n' +
      '• снижение уровня шума до 5 дБ;\n' +
      '• исключение эффекта аквапланирования;\n' +
      '• обеспечение коэффцента фильтрации покрытия до 9 см/сек.',
    textWidth: 474,
    ill: { icon: ill5, width: 367, top: 92, left: 80 },
    lg: {
      textWidth: 318,
      ill: { icon: ill5_tablet, width: 207, top: 30, left: 31 }
    },
    sm: { ill: { width: 98, top: 14, left: 15 } }
  },
  {
    text: 'Применение теплых асфальтобетонных смесей на основе присадки EVOTHERM J1, позволяющих в процессе приготовления смеси снизить степень старения битума, а также за счет снижения температуры уплотнения на 30-50°С, раздвинуть климатические границы строительного сезона',
    textWidth: 445,
    ill: { icon: ill6, width: 277, top: 136, left: 125 },
    lg: { textWidth: 318, ill: { width: 141, top: 69, left: 64 } },
    sm: { ill: { width: 67, top: 33, left: 30 } }
  },
  {
    text: 'Применение перегружателя Shuttle Buggi для устранения фракционной и температурной сегрегации асфальтобетонных смесей.',
    textWidth: 445,
    ill: { icon: ill7, width: 408, top: 147, left: 40 },
    lg: { textWidth: 318, ill: { width: 207, top: 75, left: 20 } },
    sm: { ill: { width: 99, top: 36, left: 10 } }
  },
  {
    text: 'Использование современных катков, оборудованных системами автоматического контроля температуры и плотности Asphalt Manager, для обеспечения эффективного уплотнения и однородности плотности конструктивных слоев дорожных одежд.',
    textWidth: 445,
    ill: { icon: ill8, width: 352, top: 104, left: 105 },
    lg: { textWidth: 318, ill: { width: 179, top: 53, left: 54 } },
    sm: { ill: { width: 85, top: 25, left: 25 } }
  },
  {
    text: 'Строительство слоев оснований дорожных одежд из оптимально-подобранных щебеночнопесчаных смесей ЩПС-80; ЩПС-40 и ЩПС-20. Модуль упругости оснований из ЩПС достигает 494 МП/см., что значительно выше показателей для широко практикуемых технологий устройства оснований из фракционированных щебней методом заклинки.',
    textWidth: 445,
    ill: { icon: ill9, width: 291, top: 144, left: 112 },
    lg: { textWidth: 318, ill: { width: 148, top: 73, left: 57 } },
    sm: { ill: { width: 71, top: 35, left: 27 } }
  },
  {
    text: 'Использование геосинтетических материалов (геотекстиль, геосетки, объемные георешетки) в конструкциях земляного полотна и дорожной одежды.',
    textWidth: 424,
    ill: { icon: ill10, width: 369, top: 92, left: 84 },
    lg: { textWidth: 318, ill: { width: 187, top: 47, left: 43 } },
    sm: { ill: { width: 89, top: 22, left: 20 } }
  }
];

export const Technologies = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const ref = useRef(null);

  const prevSlide = () => {
    if (ref && ref.current) ref.current.swiper.slidePrev();
  };
  const nextSlide = () => {
    if (ref && ref.current) ref.current.swiper.slideNext();
  };
  const slideTo = (index) => {
    if (ref && ref.current) ref.current.swiper.slideTo(index, 500);
  };

  return (
    <Container {...props} relative>
      <SkewedLine
        top={-20}
        left={-55}
        lg={{ top: -10, left: -124 }}
        sm={{ top: '0', left: -164 }}
      />
      <StyledMainContainer
        paddingBottom={40}
        lgPaddingBottom={20}
        smPaddingBottom={11}
      >
        <Container smPaddingLeft={26}>
          <Title smStyles={Text25}>Технологии</Title>
        </Container>

        <Container
          marginTop={71}
          lgMarginTop={68}
          smdMarginTop={27}
          paddingX={43}
          lgPaddingX={'0'}
          lgMarginX={-24}
          relative
        >
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={(e) => setCurrentSlide(e.activeIndex)}
            ref={ref}
            breakpoints={{
              [BREAKPOINTS.lg]: {
                autoHeight: false
              }
            }}
          >
            {SLIDES.map((item, key) => (
              <SwiperSlide key={key}>
                <Slide
                  text={item.text}
                  textWidth={item.textWidth}
                  illustrationObj={item.ill}
                  lg={item.lg}
                  sm={item.sm}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <NavLeft src={arrow_left} onClick={prevSlide} />
          <NavRight src={arrow_left} onClick={nextSlide} />
        </Container>

        <Pagination
          activeIndex={currentSlide}
          count={SLIDES.length}
          slideTo={slideTo}
          marginTop={115}
          lgMarginTop={96}
          smMarginTop={40}
        />
      </StyledMainContainer>
    </Container>
  );
};

const Slide = ({ text, illustrationObj, textWidth, lg, sm }) => {
  return (
    <SlideRow gap={10} lgGap={8} alignCenter nowrap smdWrap>
      <Col auto>
        <IllustrationWrapper>
          <Figure src={figure} />
          <Illustration
            src={illustrationObj.icon}
            width={illustrationObj.width}
            top={illustrationObj.top}
            left={illustrationObj.left}
            zIndex={illustrationObj.zIndex}
            lg={lg.ill}
            sm={sm.ill}
          />
        </IllustrationWrapper>
      </Col>
      <Col smdMarginTop={30}>
        <TextWrapper
          maxWidth={textWidth ? textWidth : 480}
          marginTop={20}
          lgMaxWidth={lg.textWidth || 318}
          lgMarginTop={'0'}
        >
          {text}
        </TextWrapper>
      </Col>
    </SlideRow>
  );
};

const Pagination = ({ activeIndex, count, slideTo = () => {}, ...props }) => {
  const dots = new Array(count).fill(null);

  return (
    <PaginationRow
      {...props}
      gap={8}
      lgGap={4.5}
      smGap={2}
      justifyContent={'center'}
      nowrap
    >
      {dots.map((item, key) => (
        <Col auto key={key}>
          <PaginationDot
            active={activeIndex === key}
            onClick={() => slideTo(key)}
          />
        </Col>
      ))}
    </PaginationRow>
  );
};
