import React from 'react';
import { ProjectInfo, ProjectsCommon, ProjectAsideInfo } from '../components';
import { Col, Container, Row } from '../../../ui/layout';
import { VideoBlock } from '../components/VideoBlock';
import { BREAKPOINTS } from '../../../utils';
import {
  ill1,
  ill2,
  ill3,
  ill4,
  ill5,
  project
} from 'assets/projects/residentialComplex';
import styled from 'styled-components';

export const ResidentialComplex = () => {
  return (
    <ProjectsCommon>
      <Row gap={14}>
        <Col width={'66.66%'} lgWidth={'100%'}>
          <ProjectInfo
            ill={project}
            title={'Комплекс жилых домов'}
            location={'Владивосток'}
          >
            Группа жилых домов в районе ул. Русская, 57 в г. Владивостоке (4
            дома)
          </ProjectInfo>

          <MainWrapper>
            <Container marginTop={30}>
              Объект расположен в Советском районе г. Владивостока. Окружение
              участка: с южной стороны расположена жилая застройка (дома средней
              этажности), с северной и восточной сторон расположена жилая
              застройка (малоэтажные жилые дома), с западной стороны –
              многоэтажная жилая застройка, административные учреждения и
              торговые предприятия. Подъезд к участку имеется с восточной
              стороны с ул. Волжская, которая в свою очередь примыкает к ул.
              Русская и далее к улично-дорожной сети г. Владивостока. Рельеф на
              участке – сложный, представляет собой склоновую часть сопки
              северо-восточной экспозиции. Перепад отметок по участку составляет
              около 40 м. Абсолютные отметки возрастают в северо-западном
              направлении и составляют от 80 до 118 м в Балтийской системе
              высот(1977). Территория участка в основном залесена. На участке
              имеются существующие ограждения, в северной части – разрушенные
              строения. Площадь участка составляет 20000 кв.м. На выделенном
              участке согласно задания на проектирование предусмотрено
              размещение многоквартирных жилых домов, гостевых
              <br />
              <br />
              автостоянок, а также площадок различного назначения, проездов с
              твердым покрытием, газонов и других элементов благоустройства,
              озеленения и инженерного обеспечения. Градостроительный план
              земельного участка № РФ 25-2-04-0-00-2020-0120 выдан УГиА г.
              Владивостока 7.08.2020г. Согласно вышеуказанному
              градостроительному плану земельный участок расположен в
              территориальной зоне застроки многоэтажными жилыми домами (Ж4),
              максимальный процент застройки участка не более 60%, максимальный
              коэффициент плотности застройки жилыми домами 2,5, минимальный
              процент озеленения – не менее 30 %, минимальное количество мест
              хранения автомобилей – 1 машиноместо на 100 кв.м. жилой площади. В
              случае отклонения от предельно допустимых параметров в части
              обеспечения местами парковки автомобилей необходимо обоснование
              наличия мест хранения автомобилей, доступных для неограниченного
              круга лиц, в пределах пешеходной территориальной доступности – 500
              м.
            </Container>

            <Ill4Wrapper>
              <Img src={ill4} alt={''} />
            </Ill4Wrapper>
            <Ill5Wrapper>
              <Img src={ill5} alt={''} />
            </Ill5Wrapper>
          </MainWrapper>
        </Col>
        <Col width={'33.33%'} lgWidth={'100%'}>
          <AsideContainer>
            <AsideIllRow gap={31} lgGap={14} cols={1} lgCols={2} smdCols={1}>
              <AsideCol lgWidth={'100%'}>
                <ProjectAsideInfo
                  info={[
                    {
                      title: 'Площадь земельного участка в границах отвода',
                      value: '20000 кв.м.'
                    },
                    {
                      title: 'Площадь застройки, всего:',
                      value: '7053 кв.м.',
                      details: [
                        {
                          title: '- в том числе жилые дома',
                          value: '2998 кв.м.'
                        },
                        {
                          title:
                            '- в том числе другие сооружения: подземные автостоянки, ТП, подпорные стены и т.д.)',
                          value: '4055 кв.м.'
                        }
                      ]
                    },
                    {
                      title:
                        'Площадь твердых покрытий в границах отвода, всего:',
                      value: '10585 кв.м.'
                    },
                    {
                      title:
                        'В том числе площадь твердых покрытий на кровле подземных автостоянок',
                      value: '3645 кв.м.'
                    },
                    {
                      title:
                        'В том числе площадь твердых покрытий по грунту (без учета площадок с покрытием газоной решеткой)',
                      value: '6715 кв.м.'
                    },
                    {
                      title: 'Площадь озеленения в границах отвода, всего:',
                      value: '6232 (31%) кв.м.'
                    },
                    {
                      title:
                        'В том числе площадь озеленения по грунту с учетом площадок с покрытием газонной решеткой',
                      value: '6232 (31%) кв.м.'
                    },
                    {
                      title:
                        'Площадь озеленяемых откосов планировки за территорией выделенного земельного участка',
                      value: '6670 кв.м.'
                    }
                  ]}
                  minHeight={353}
                />
              </AsideCol>
              <Ill1Wrapper>
                <Img src={ill1} alt={''} />
              </Ill1Wrapper>
              <Ill2Wrapper>
                <Img src={ill2} alt={''} />
              </Ill2Wrapper>
              <Ill3Wrapper>
                <Img src={ill3} alt={''} />
              </Ill3Wrapper>
            </AsideIllRow>
          </AsideContainer>
        </Col>
      </Row>
      <Row>
        <Container marginTop={60} lgMarginTop={48} smdMarginTop={53}>
          В состав группы жилых домов с отдельно стоящими подземными
          автостоянками в районе ул. Русская, д. 57, в г. Владивостоке входят
          три подземные автостоянки. Количество этажей в проектируемых зданиях
          автостоянок – 1, габариты в осях 67,8х18,3 м. Высота этажа – 2,7 м в
          чистоте. Здания оснащены необходимыми видами инженерного оборудования
          в соответствии с нормами и заданием заказчика. Расстояния между
          проектируемым и соседними зданиями приняты на основе расчета
          освещенности и в соответствии с противопожарными требованиями.
          <br />
          <br />
          Общие характеристики жилых домов:
          <br />
          <br />- уровень ответственности - II нормальный;
          <br />
          <br />- этажность - 10 этажей на базе серии 83;
          <br />
          <br />- здание с размерами между осей 13,8 х 48,0 м. высота этажей 2,8
          м.
          <br />
          <br />
          Жилое здание в панельном исполнении с продольными и поперечными
          несущими стенами. Прочность и устойчивость обеспечивается совместной
          работой вертикальных панельных стен, объединенных дисками перекрытий.
          <br />
          <br />В соответствии со СП 131.13330.2020 «Строительная климатология»
          характеризуется следующими данными: климатический подрайон - II Г,
          расчётная температура наружного воздуха - минус 22 °С, сейсмичность -
          6 баллов.
          <br />
          <br />
          Габариты жилого здания в осях - 48,0х13,8 м. Высота этажей - 2.8 м. В
          здании предусмотрено техническое подполье и технический чердак для
          прокладки инженерных коммуникаций. В жилых домах предусмотрены
          однокомнатные, двухкомнатные, трехкомнатные, четырехкомнатная и
          пятикомнатная квартиры с односторонней ориентацией.
          <br />
          <br />
          Жилое здание секционного типа с одной лестничной клеткой типа Л1.
          Каждая квартира, начиная с 5 этажа, имеет аварийный выход на лоджию,
          оборудованную люками и лестницами соединяющие смежные этажи.
        </Container>

        {/* <VideoWrapper>
          <VideoBlock
            img={project}
            subtitle={'Видеопрезентация проекта «Комплекс жилых домов»'}
          />
        </VideoWrapper> */}
      </Row>

      <MainWrapperMobile
        gap={30}
        marginTop={31}
        lgMarginTop={41}
        smdMarginTop={14}
      >
        <Col width={'66.66%'} lgWidth={'100%'}>
          <Container>
            Объект расположен в Советском районе г. Владивостока. Окружение
            участка: с южной стороны расположена жилая застройка (дома средней
            этажности), с северной и восточной сторон расположена жилая
            застройка (малоэтажные жилые дома), с западной стороны –
            многоэтажная жилая застройка, административные учреждения и торговые
            предприятия. Подъезд к участку имеется с восточной стороны с ул.
            Волжская, которая в свою очередь примыкает к ул. Русская и далее к
            улично-дорожной сети г. Владивостока. Рельеф на участке – сложный,
            представляет собой склоновую часть сопки северо-восточной
            экспозиции. Перепад отметок по участку составляет около 40 м.
            Абсолютные отметки возрастают в северо-западном направлении и
            составляют от 80 до 118 м в Балтийской системе высот(1977).
            Территория участка в основном залесена. На участке имеются
            существующие ограждения, в северной части – разрушенные строения.
            Площадь участка составляет 20000 кв.м. На выделенном участке
            согласно задания на проектирование предусмотрено размещение
            многоквартирных жилых домов, гостевых
            <br />
            <br />
            автостоянок, а также площадок различного назначения, проездов с
            твердым покрытием, газонов и других элементов благоустройства,
            озеленения и инженерного обеспечения. Градостроительный план
            земельного участка № РФ 25-2-04-0-00-2020-0120 выдан УГиА г.
            Владивостока 7.08.2020г. Согласно вышеуказанному градостроительному
            плану земельный участок расположен в территориальной зоне застроки
            многоэтажными жилыми домами (Ж4), максимальный процент застройки
            участка не более 60%, максимальный коэффициент плотности застройки
            жилыми домами 2,5, минимальный процент озеленения – не менее 30 %,
            минимальное количество мест хранения автомобилей – 1 машиноместо на
            100 кв.м. жилой площади. В случае отклонения от предельно допустимых
            параметров в части обеспечения местами парковки автомобилей
            необходимо обоснование наличия мест хранения автомобилей, доступных
            для неограниченного круга лиц, в пределах пешеходной территориальной
            доступности – 500 м.
          </Container>

          <Ill4Wrapper>
            <Img src={ill4} alt={''} />
          </Ill4Wrapper>
          <Ill5Wrapper>
            <Img src={ill5} alt={''} />
          </Ill5Wrapper>
          <Ill3WrapperMobile>
            <Img src={ill3} alt={''} />
          </Ill3WrapperMobile>

          <Container lgMarginTop={62} smdMarginTop={69}>
            В состав группы жилых домов с отдельно стоящими подземными
            автостоянками в районе ул. Русская, д. 57, в г. Владивостоке входят
            три подземные автостоянки. Количество этажей в проектируемых зданиях
            автостоянок – 1, габариты в осях 67,8х18,3 м. Высота этажа – 2,7 м в
            чистоте. Здания оснащены необходимыми видами инженерного
            оборудования в соответствии с нормами и заданием заказчика.
            Расстояния между проектируемым и соседними зданиями приняты на
            основе расчета освещенности и в соответствии с противопожарными
            требованиями.
            <br />
            <br />
            Общие характеристики жилых домов:
            <br />
            <br />- уровень ответственности - II нормальный;
            <br />
            <br />- этажность - 10 этажей на базе серии 83;
            <br />
            <br />- здание с размерами между осей 13,8 х 48,0 м. высота этажей
            2,8 м.
            <br />
            <br />
            Жилое здание в панельном исполнении с продольными и поперечными
            несущими стенами. Прочность и устойчивость обеспечивается совместной
            работой вертикальных панельных стен, объединенных дисками
            перекрытий.
            <br />
            <br />В соответствии со СП 131.13330.2020 «Строительная
            климатология» характеризуется следующими данными: климатический
            подрайон - II Г, расчётная температура наружного воздуха - минус 22
            °С, сейсмичность - 6 баллов.
            <br />
            <br />
            Габариты жилого здания в осях - 48,0х13,8 м. Высота этажей - 2.8 м.
            В здании предусмотрено техническое подполье и технический чердак для
            прокладки инженерных коммуникаций. В жилых домах предусмотрены
            однокомнатные, двухкомнатные, трехкомнатные, четырехкомнатная и
            пятикомнатная квартиры с односторонней ориентацией.
            <br />
            <br />
            Жилое здание секционного типа с одной лестничной клеткой типа Л1.
            Каждая квартира, начиная с 5 этажа, имеет аварийный выход на лоджию,
            оборудованную люками и лестницами соединяющие смежные этажи.
          </Container>

          {/* <VideoWrapper>
            <VideoBlock
              img={project}
              subtitle={'Видеопрезентация проекта «Комплекс жилых домов»'}
            />
          </VideoWrapper> */}
        </Col>
      </MainWrapperMobile>
    </ProjectsCommon>
  );
};

const MainWrapper = styled.div`
  max-width: 780px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`;
const MainWrapperMobile = styled(Row)`
  display: none;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: block;
    padding-bottom: 14px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-bottom: 0px;
  }
`;
const AsideContainer = styled.div`
  position: relative;
  margin-right: -14px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin: 0;
    :after {
      content: '';
      position: absolute;
      left: -13px;
      bottom: 0;
      width: calc(100% + 26px);
      height: 1px;
      background: #d9d9d9;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    :after {
      left: -7px;
      width: calc(100% + 14px);
    }
  }
`;
const AsideIllRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 30px;
    padding-bottom: 38px;
    justify-content: space-between;
    position: relative;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 11px;
    padding-bottom: 33px;
  }
`;
const AsideCol = styled(Col)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    order: 3;
    margin-top: 44px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 26px;
  }
`;
const Img = styled.img`
  width: 100%;
  display: block;
  object-fit: cover;
`;
const Ill1Wrapper = styled.div`
  margin-top: 15px;
  > img {
    max-width: 362px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: ${BREAKPOINTS.lg}px) {
    > img {
      max-width: 265px;
      margin: 0;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    > img {
      max-width: unset;
    }
  }
`;
const Ill2Wrapper = styled.div`
  margin-top: 25px;
  > img {
    max-width: 362px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: -1px;
    max-width: 294px;
    > img {
      max-width: 269px;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 26px;
    max-width: unset;
    > img {
      max-width: unset;
    }
  }
`;
const Ill3Wrapper = styled.div`
  margin-top: 70px;
  > img {
    max-width: 359px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: none;
    margin-top: -8px;
    max-width: 294px;
    > img {
      max-width: 259px;
      margin-left: 0;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: -3px;
    max-width: unset;
    > img {
      max-width: unset;
    }
  }
`;
const Ill3WrapperMobile = styled.div`
  display: none;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: block;
    max-width: 261px;
    margin-top: 62px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    max-width: 149px;
    margin-top: 37px;
  }
`;
const Ill4Wrapper = styled.div`
  margin-top: 58px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 49px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 21px;
  }
`;
const Ill5Wrapper = styled.div`
  margin-top: 50px;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 62px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 36px;
  }
`;
const VideoWrapper = styled.div`
  width: 100%;
  margin-top: 60px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 76px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 18px;
  }
`;
