import React from 'react';
import { Route, Routes as Switch, Navigate } from 'react-router-dom';
import {
  MainPage,
  NewsPage,
  PartnersPage,
  TeamPage,
  ContactsPage,
  ProjectsPage,
  ArticlePage,
  StadiumProject,
  SchoolNefteyuganskProject,
  IFNSProject,
  SchoolVladivostok,
  SchoolBerezovo,
  ResidentialComplex,
  TollRoad
} from 'pages';

export const Routes = () => {
  return (
    <Switch>
      <Route exact path='/' element={<MainPage />} />
      {/*<Route exact path='/team' element={<TeamPage />} />*/}
      <Route exact path='/news' element={<NewsPage />} />
      <Route exact path='/news/:id' element={<ArticlePage />} />
      <Route exact path='/partners' element={<PartnersPage />} />
      <Route exact path='/contacts' element={<ContactsPage />} />
      <Route exact path='/projects' element={<ProjectsPage />} />
      {/* <Route
        exact
        path='/project/school-nefteyugansk'
        element={<SchoolNefteyuganskProject />}
      /> */}
      <Route
        exact
        path='/project/school-vladivostok'
        element={<SchoolVladivostok />}
      />
      {/* <Route exact path='/project/ifns' element={<IFNSProject />} /> */}
      <Route exact path='/project/stadium' element={<StadiumProject />} />
      <Route
        exact
        path='/project/school-berezovo'
        element={<SchoolBerezovo />}
      />
      <Route
        exact
        path='/project/residential-complex'
        element={<ResidentialComplex />}
      />
      <Route exact path='/project/toll-road' element={<TollRoad />} />
      <Route path='*' element={<Navigate to='/' replace />} />
    </Switch>
  );
};
