import React, { useState, useMemo } from 'react';
import { Header, Footer, YearsFilter, Pagination } from 'components/common';
import { Col, MainContainer, Row } from 'ui/layout';
import { BREAKPOINTS } from 'utils';
import styled from 'styled-components';
import {
  project1,
  project2,
  project3,
  project4,
  project5,
  project6,
  project7
} from 'assets/projects';
import figure from 'assets/common/figure_yellow.svg';
import geo_icon from 'assets/common/geo.svg';
import { Link } from 'react-router-dom';

const PROJECTS = [
  // {
  //   title: 'Школа Интернат',
  //   city: 'Нефтеюганск',
  //   descr:
  //     '«Специальное (коррекционное) образовательное учреждение для обучающихся, воспитанников с отклонениями в развитии «Нефтеюганская специальная (коррекционная)общеобразовательная школа-интернат VIII вида», расположенного по адресу: Тюменская область, Ханты-Мансийский автономный округ-Югра, г. Нефтеюганск, мкр.17, земельный участок №37 вр.',
  //   photo: project1,
  //   year: 2022,
  //   link: '/project/school-nefteyugansk'
  // },
  {
    title: 'Школа №1',
    city: 'Владивосток',
    descr:
      'Сложный, многокомпонентный объем школьного комплекса инкрустирован в\n' +
      '            рельеф участка и своим основанием повторяет его естественную,\n' +
      '            ненарушенную структуру (рисунок рельефа). Данное решение позволяет\n' +
      '            максимально сохранить существующий рельеф, минимизировать\n' +
      '            перемещение земляных масс и сократить время на подготовку участка к\n' +
      '            строительству.',
    photo: project2,
    year: 2022,
    link: '/project/school-vladivostok'
  },
  // {
  //   title: 'ИФНС № 7',
  //   city: 'Нефтеюганск',
  //   descr:
  //     'Здание ИФНС предполагается разместить на земельном участке с\n' +
  //     '            кадастровым номером 86:20:0000000:11356 (cм. ГПЗУ №RU86304000-2143),\n' +
  //     '            расположенном в 17-м микрорайоне г. Нефтеюганска Ханты-Мансийского\n' +
  //     '            автономного округа Тюменской области. С севера от участка\n' +
  //     '            строительства расположены строящиеся 16-этажные жилые дома, строение\n' +
  //     '            2 и строение 3, по ул. Романа Кузоваткина...',
  //   photo: project3,
  //   year: 2022,
  //   link: '/project/ifns'
  // },
  {
    title: 'Стадион Шахтер',
    city: 'Шахты',
    descr:
      '«Реконструкция стадиона «Шахтер» МОУ ДОД ДЮСШ №5» в г.Шахты\n' +
      '              Ростовской области. Местоположение объекта: Ростовская область, г.\n' +
      '              Шахты, проспект Василия Алексеева, 1.',
    photo: project4,
    year: 2022,
    link: '/project/stadium'
  },
  {
    title: 'Школа',
    city: 'пгт Берёзово',
    descr:
      'Школа на 700 учащихся запроектирована для обучения с 1 по 11 класс в\n' +
      '            одну смену. Общее количество классов – 31, из них в начальной школе\n' +
      '            – 12 классов (276 чел.), в основной школе – 15 классов (345 чел.), в\n' +
      '            старшей школе – 4 класса (92 чел.). Соотношение количества\n' +
      '            параллелей по ступеням образования составляет 3:3:2. Количество\n' +
      '            обучающихся в одном классе принято с фронтальной формой занятий не\n' +
      '            более 23 школьников.',
    photo: project5,
    year: 2022,
    link: '/project/school-berezovo'
  },
  {
    title: 'Комплекс жилых домов',
    city: 'Владивосток',
    descr:
      'Группа жилых домов в районе ул. Русская, 57 в г. Владивостоке (4\n' +
      '            дома)',
    photo: project6,
    year: 2022,
    link: '/project/residential-complex'
  },
  {
    title: 'Лыткаринская платная дорога',
    city: 'Подмосковье',
    descr:
      'Масштабный проект системного улучшения дорожной инфраструктуры\n' +
      '            Подмосковья, предусматривающий строительство современной скоростной\n' +
      '            магистрали Солнцево – Бутово – Видное – Лыткарино – Томилино –\n' +
      '            Красково – Железнодорожный (ЛБВ-ЛТКЖ), известный также как\n' +
      '            «Южно-Лыткаринская автодорога» (ЮЛА).',
    photo: project7,
    year: 2022,
    link: '/project/toll-road'
  }
];
// const YEARS = [2022, 2023, 2024];
// let PROJECTS_ON_PAGE = 100;
// if (window.innerWidth < BREAKPOINTS.lg) PROJECTS_ON_PAGE = 100;

export const ProjectsPage = () => {
  // const [selectedYear, setSelectedYear] = useState(
  //   YEARS.includes(new Date().getFullYear())
  //     ? new Date().getFullYear()
  //     : YEARS[YEARS.length - 1]
  // );
  // const [page, setPage] = useState(1);

  // const changeYear = (year) => {
  //   setSelectedYear(year);
  //   setPage(1);
  // };
  //
  // const FILTERED_PROJECTS = useMemo(
  //   () => PROJECTS.filter((item) => item.year === selectedYear),
  //   [selectedYear]
  // );

  return (
    <div>
      <Header active={'projects'} />

      <Wrapper>
        <Figure1 src={figure} alt={''} />

        <MainContainer>
          <Title>Проекты компании</Title>

          {/*<FiltersContainer>*/}
          {/*  <YearsFilter*/}
          {/*    options={YEARS}*/}
          {/*    selected={selectedYear}*/}
          {/*    onChange={(value) => changeYear(value)}*/}
          {/*    color={'#FAB43A'}*/}
          {/*  />*/}
          {/*</FiltersContainer>*/}

          <ProjectsList>
            {PROJECTS.map((item, key) => (
              <Card key={key}>
                <Row>
                  <Col width={'65%'} lgWidth={'100%'}>
                    <ProjectImg src={item.photo} alt={''} />
                  </Col>
                  <Col width={'35%'} lgWidth={'100%'}>
                    <TextWrapper>
                      <CardTitle>{item.title}</CardTitle>

                      <GeoWrapper>
                        <GeoIcon src={geo_icon} alt={''} />
                        <GeoText>{item.city}</GeoText>
                      </GeoWrapper>
                      <Descr>{item.descr}</Descr>

                      <StyledLink to={item.link || '/projects'}>
                        Посмотреть проект
                      </StyledLink>
                    </TextWrapper>
                  </Col>
                </Row>
              </Card>
            ))}
          </ProjectsList>

          {/*<PaginationContainer>*/}
          {/*  <Pagination*/}
          {/*    active={page}*/}
          {/*    changeActive={setPage}*/}
          {/*    pages={Math.ceil(FILTERED_PROJECTS.length / PROJECTS_ON_PAGE)}*/}
          {/*    color={'#FAB43A'}*/}
          {/*  />*/}
          {/*</PaginationContainer>*/}
        </MainContainer>

        <Figure2 src={figure} alt={''} />
      </Wrapper>

      <Footer />
    </div>
  );
};

const Title = styled.h1`
  font-size: 48px;
  line-height: 58px;
  font-weight: 600;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 38px;
    line-height: 46px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 25px;
    line-height: 30px;
  }
`;
const Wrapper = styled.div`
  position: relative;
  padding-top: 89px;
  padding-bottom: 120px;
  :after {
    content: '';
    position: absolute;
    left: -76px;
    top: 0;
    height: 89px;
    width: 100px;
    background: #fab43a;
    transform: skew(-30deg);
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding-top: 55px;
    padding-bottom: 101px;
    :after {
      left: -76px;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-top: 30px;
    padding-bottom: 84px;
    :after {
      height: 26px;
      left: -90px;
      top: 32px;
    }
  }
`;
const Figure1 = styled.img`
  position: absolute;
  right: -249px;
  top: 84px;
  object-fit: contain;
  width: 499px;
  z-index: -1;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 429px;
    top: 32px;
    right: -276px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 282px;
    top: 44px;
    right: -192px;
  }
`;
const Figure2 = styled(Figure1)`
  top: unset;
  right: unset;
  left: -245px;
  bottom: 2px;
  width: 375px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 263px;
    bottom: -2px;
    left: -183px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 214px;
    bottom: 0;
    left: -162px;
  }
`;
const FiltersContainer = styled.div`
  margin-top: 48px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 87px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 15px;
  }
`;
const ProjectsList = styled.div`
  margin-top: 14px;
  margin-left: -10px;
  margin-right: -10px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 16px;
    margin-left: -15px;
    margin-right: -15px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 29px;
    margin-left: -7px;
    margin-right: -7px;
  }
`;
const Card = styled.div`
  padding: 11px 10px 9px 10px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  :not(:last-child) {
    margin-bottom: 48px;
  }

  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding: 13px 13px 12px 14px;
    :not(:last-child) {
      margin-bottom: 29px;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding: 6px 7px 16px 6px;
    :not(:last-child) {
      margin-bottom: 19px;
    }
  }
`;
const ProjectImg = styled.img`
  height: 459px;
  width: 100%;
  object-fit: cover;
  display: block;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    height: 332px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    height: 189px;
  }
`;
const TextWrapper = styled.div`
  padding: 78px 10px 16px 60px;

  @media (max-width: ${BREAKPOINTS.xl}px) {
    padding: 70px 10px 16px 30px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding: 29px 10px 0 0;
    margin-left: -2px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding: 16px 0 0 0;
    margin-left: -1px;
  }
`;
const CardTitle = styled.p`
  font-size: 25px;
  line-height: 32px;
  font-weight: 700;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 14px;
    line-height: 18px;
  }
`;
const GeoWrapper = styled.div`
  margin-top: 18px;
  display: flex;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 9px;
  }
`;
const GeoIcon = styled.img`
  width: 13px;
  height: 18px;
  object-fit: contain;
  margin-right: 8px;

  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 8px;
    height: 12px;
    margin-right: 5px;
  }
`;
const GeoText = styled.p`
  display: inline-block;
  font-size: 16px;
  line-height: 21px;

  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 18px;
    line-height: 23px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
  }
`;
const Descr = styled.p`
  margin-top: 24px;
  font-size: 16px;
  line-height: 21px;
  font-weight: 300;

  @media (max-width: ${BREAKPOINTS.md}px) {
    font-size: 16px;
    line-height: 21px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-right: -4px;
    margin-top: 11px;
    font-size: 12px;
    line-height: 16px;
  }
`;
const StyledLink = styled(Link)`
  margin-top: 62px;
  display: inline-block;
  font-size: 16px;
  line-height: 21px;
  font-weight: 700;
  color: #fab43a;
  text-decoration: unset;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 32px;
    font-size: 18px;
    line-height: 23px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 22px;
    font-size: 12px;
    line-height: 16px;
  }
`;
const PaginationContainer = styled.div`
  margin-top: 64px;
  margin-left: -10px;
  margin-right: -10px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 86px;
    margin-left: 0;
    margin-right: 0;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 36px;
  }
`;
