import React from 'react';
import styled from 'styled-components';
import { Col, Container, Img, PageContainer, Row } from 'ui/layout';
import { Caption, fontMagra, fontMontserrat, H1, H2 } from 'ui/typography';
import ill1 from 'assets/history/ill1.webp';
import ill2 from 'assets/history/ill2.webp';
import { BREAKPOINTS, COLORS } from '../../../../utils';

export const History = (props) => {
  return (
    <MainContainerStyled {...props}>
      <Row nowrap minHeight={'780px'} mdMinHeight={'unset'}>
        <Col
          width={'50%'}
          xlWidth={'60%'}
          lgWidth={'70%'}
          mdWidth={'100%'}
          paddingRight={30}
          mdPaddingRight={'0'}
        >
          <Container
            maxWidth={590}
            marginTop={97}
            paddingLeft={20}
            style={{ marginLeft: 'auto' }}
            mdMarginTop={95}
            mdPaddingLeft={'0'}
            smMarginTop={85}
          >
            <Title>История компании</Title>
            <PointRow
              gap={30}
              xsGap={22}
              marginTop={64}
              mdMarginTop={43}
              smMarginTop={50}
            >
              <Col auto alignSelf={'stretch'}>
                <NumberWrapper>
                  <NumberText>2012</NumberText>
                </NumberWrapper>
              </Col>
              <Description>
                ГК «Альянс» — это многопрофильная производственно-строительная
                компания, имеющая шесть подразделений в Рязанской области. Лидер
                дорожно-строительного сегмента Рязанской области.
              </Description>
            </PointRow>
            <PointRow gap={30} xsGap={22}>
              <Col auto alignSelf={'stretch'}>
                <NumberWrapper>
                  <NumberText>2015</NumberText>
                </NumberWrapper>
              </Col>
              <Description>
                В 2015 году открывается значимый объект, построенный компанией,
                – транспортная развязка возле села Шумашь.
              </Description>
            </PointRow>
          </Container>
        </Col>
        <Col width={'50%'} xlWidth={'40%'} lgWidth={'30%'} mdHidden>
          <Illustration src={ill1} alt={''} />
        </Col>
      </Row>

      <Row minHeight={'919px'} mdMinHeight={'unset'}>
        <Col width={'50%'} xlWidth={'40%'} lgWidth={'30%'} mdHidden>
          <Illustration src={ill2} alt={''} />
        </Col>

        <Col width={'50%'} xlWidth={'60%'} lgWidth={'70%'} mdWidth={'100%'}>
          <Container
            maxWidth={570}
            marginTop={100}
            paddingRight={20}
            paddingLeft={30}
            mdPaddingX={'0'}
            mdMarginTop={'0'}
            style={{ marginRight: 'auto' }}
          >
            <PointRow gap={30} xsGap={22}>
              <Col auto alignSelf={'stretch'}>
                <NumberWrapper>
                  <NumberText>2019</NumberText>
                </NumberWrapper>
              </Col>
              <Description>
                В 2019 году возведен мобильный асфальтобетонный завод
                беспрерывного действия АLmix TP-72 UF-150, а также комплект
                мобильных лабораторий для оперативного контроля качества
                выполняемых работ.
              </Description>
            </PointRow>
            <PointRow gap={30} xsGap={22}>
              <Col auto alignSelf={'stretch'}>
                <NumberWrapper>
                  <NumberText>2021</NumberText>
                </NumberWrapper>
              </Col>
              <Description>
                Приход новой команды профессионалов, перестройка компании,
                начало географической экспансии и диверсификации бизнеса. Смена
                модели управления и структуры.
              </Description>
            </PointRow>
            <PointRow gap={30} xsGap={22}>
              <Col auto alignSelf={'stretch'}>
                <NumberWrapper color={COLORS.accent1}>
                  <NumberText color={COLORS.accent1}>2022</NumberText>
                </NumberWrapper>
              </Col>
              <Description smMarginLeft={-10}>
                ГК «Альянс» - предприятие федерального масштаба в области
                строительства инфраструктурных объектов и
                промышленно-гражданского строительства, работающее с крупным
                государственным и муниципальным заказчиком. География проектов
                охватывает территорию от Москвы до Нефтеюганска.
              </Description>
            </PointRow>
          </Container>
        </Col>
      </Row>
    </MainContainerStyled>
  );
};

const MainContainerStyled = styled(PageContainer)`
  @media (max-width: ${BREAKPOINTS.md}px) {
    max-width: 581px;
    padding-left: 10px;
    padding-right: 2px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    max-width: 410px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    max-width: 369px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const Title = styled(H1)`
  ${fontMagra}
`;

const Illustration = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const PointRow = styled(Row)`
  min-height: 240px;
  align-items: center;
  flex-wrap: nowrap;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    min-height: unset;
  }
`;
const NumberWrapper = styled(Row)`
  height: 100%;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;

  :after {
    content: '';
    position: absolute;
    left: 180px;
    top: 0;
    height: 100%;
    width: ${(p) => (p.color ? '3px' : '1px')};
    background: ${(p) => (p.color ? p.color : COLORS.lightGray)};
    ${(p) => (p.color ? 'transform: translateX(-2px);' : '')}
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    :after {
      left: 132px;
    }
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    :after {
      left: 105px;
    }
  }
`;
const NumberText = styled.div`
  font-weight: 300;
  font-size: 48px;
  line-height: 67px;
  letter-spacing: -3px;
  ${(p) => (p.color ? `color: ${p.color};` : '')}
  text-align: center;
  min-width: 150px;
  box-sizing: content-box;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 40px;
    line-height: 56px;
    min-width: 102px;
    text-align: left;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    min-width: 90px;
  }
`;
const Description = styled.p`
  ${fontMontserrat}
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding-top: 20px;
    padding-bottom: 20px;
    ${(p) => (p.smMarginLeft ? `margin-left: ${p.smMarginLeft}px;` : '')}
  }
`;
