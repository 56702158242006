import React from 'react';
import styled from 'styled-components';
import { fontMontserrat } from 'ui/typography';
import { BREAKPOINTS } from '../../../utils';

export const Button = ({ title = '', smNoRadius = false, size = '' }) => {
  return (
    <StyledButton smNoRadius={smNoRadius} size={size}>
      {title}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  height: 68px;
  background: #fab43a;
  padding: 19px 62px;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  transition: 0.1s;
  cursor: pointer;
  ${fontMontserrat}
  :hover {
    background: #106eb7;
  }
  :active {
    background: #074a87;
  }

  ${(p) =>
    p.size === 'medium'
      ? `
      height: 59px;
      font-size: 17px;
      line-height: 21px;
    `
      : ''}

  @media (max-width: ${BREAKPOINTS.lg}px) {
    height: 59px;
    font-size: 17px;
    line-height: 21px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 39px;
    padding: 12px 37px;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: unset;
    ${(p) => (p.smNoRadius ? 'border-radius: unset;' : '')}
  }
`;
