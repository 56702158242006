import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Img, Row } from 'ui/layout';
import {
  main_icon,
  projects_icon,
  team_icon,
  news_icon,
  partners_icon,
  close_icon
} from 'assets/menu';
import { InteractLink } from '../../../ui/typography';
import { BREAKPOINTS } from '../../../utils';

export const Menu = ({ opened = false, onClose }) => {
  return (
    <MenuWrapper opened={opened}>
      <Scrolled>
        <Title>Меню</Title>
        <CloseIcon onClick={onClose} src={close_icon} alt={''} />

        <LinksWrapper>
          <StyledLink to={'/'}>
            <LinkRow gap={21} smdGap={8}>
              <IconBlock>
                <MainIcon src={main_icon} alt={''} />
              </IconBlock>
              <Subtitle>Главная</Subtitle>
            </LinkRow>
          </StyledLink>
          <StyledLink to={'/projects'}>
            <LinkRow gap={21} smdGap={8}>
              <IconBlock>
                <ProjectsIcon src={projects_icon} alt={''} />
              </IconBlock>
              <Subtitle>Проекты</Subtitle>
            </LinkRow>
          </StyledLink>
          <StyledLink to={'/contacts'}>
            <LinkRow gap={21} smdGap={8}>
              <IconBlock>
                <TeamIcon src={team_icon} alt={''} />
              </IconBlock>
              <Subtitle>Контакты</Subtitle>
            </LinkRow>
          </StyledLink>
          <StyledLink to={'/news'}>
            <LinkRow gap={21} smdGap={8}>
              <IconBlock>
                <NewsIcon src={news_icon} alt={''} />
              </IconBlock>
              <Subtitle>Новости</Subtitle>
            </LinkRow>
          </StyledLink>
          <StyledLink to={'/partners'}>
            <LinkRow gap={21} smdGap={8}>
              <IconBlock>
                <PartnersIcon src={partners_icon} alt={''} />
              </IconBlock>
              <Subtitle>Поставщикам</Subtitle>
            </LinkRow>
          </StyledLink>
        </LinksWrapper>

        <TelLink href='tel:84958990564'>+ 8-495-899-05-64</TelLink>
      </Scrolled>
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div`
  ${(p) => `
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  transform : ${p.opened ? 'unset' : 'translateX(100%)'};
  transition: .2s;
  background: #106EB7;
  color: #FFFFFF;
  overflow: auto;
  z-index: 100;
  display: none;
  
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: block;
  }
`}
`;
const Scrolled = styled.div`
  min-height: 100%;
  padding: 70px 55px 140px 55px;
  position: relative;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding: 37px 23px 140px 23px;
  }
`;
const Title = styled.p`
  font-size: 38px;
  line-height: 60px;
  font-weight: 600;
  border-bottom: 1px solid #ffffff;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    line-height: 50px;
  }
`;
const CloseIcon = styled(Img)`
  position: absolute;
  right: 44px;
  top: 44px;
  width: 46px;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    right: 18px;
    top: 24px;
    width: 22px;
  }
`;
const LinksWrapper = styled.div`
  margin-top: 73px;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 29px;
  }
`;
const StyledLink = styled(Link)`
  height: 76px;
  display: block;
  color: #ffffff;
  text-decoration: unset;
  border-bottom: 1px solid #ffffff;
  padding: 0 3px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  :last-child {
    border: none;
  }
  :active {
    background: #074a87;
  }

  @media (max-width: ${BREAKPOINTS.smd}px) {
    height: 59px;
    padding: 0 4px;
  }
`;
const LinkRow = styled(Row)`
  height: 100%;
  align-items: center;
  flex-wrap: nowrap;
`;
const Subtitle = styled.p`
  font-size: 25px;
  line-height: 32px;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 16px;
    line-height: 21px;
  }
`;
const IconBlock = styled.div`
  width: 31px;
  box-sizing: content-box;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 24px;
  }
`;
const MainIcon = styled(Img)`
  width: 31px;
  max-width: 100%;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 24px;
  }
`;
const ProjectsIcon = styled(Img)`
  width: 26px;
  max-width: 100%;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 20px;
  }
`;
const TeamIcon = styled(Img)`
  width: 29px;
  max-width: 100%;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 22px;
  }
`;
const NewsIcon = styled(Img)`
  width: 28px;
  max-width: 100%;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 22px;
  }
`;
const PartnersIcon = styled(Img)`
  width: 30px;
  max-width: 100%;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 23px;
  }
`;
const TelLink = styled(InteractLink)`
  color: #ffffff;
  position: absolute;
  left: 50%;
  bottom: 72px;
  transform: translateX(-50%);

  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding: 7px 0 11px 0;
    bottom: 68px;
    margin-left: -3px;
  }
`;
