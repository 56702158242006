import React from 'react';
import styled from 'styled-components';
import { Container, MainContainer } from 'ui/layout';
import { fontMagra, fontMontserrat, H1, Text25, Text38 } from 'ui/typography';
import { BREAKPOINTS } from 'utils';
import bg_img from 'assets/mainScreen/bg.png';
import bg_tablet from 'assets/mainScreen/bg_tablet.png';

export const MainScreen = () => {
  return (
    <Wrapper relative paddingTop={104} mdPaddingTop={64} smPaddingTop={34}>
      <MainContainer>
        <TextWrapper>
          <StyledH1 lgStyles={Text38} smStyles={Text25}>
            Группа компаний «Альянс»
          </StyledH1>
          <Container marginTop={23} lgMarginTop={36} smMarginTop={21}>
            <Text>
              Предприятие федерального масштаба в строительстве инфраструктурных
              объектов и промышленно-гражданского строительства, работающее с
              крупным государственным и муниципальным заказчиком.
              <br />
              <br />
              География проектов включает в себя многие ключевые регионы России:
              Московскую область, Ростовскую область, Ханты-Мансийский
              автономный округ, Приморский край. Количество регионов, где строят
              наши специалисты, постоянно увеличивается.
            </Text>
          </Container>
        </TextWrapper>
      </MainContainer>

      <Bg className='bg-img' src={bg_img} width='1598' alt='' />
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-top: 89px solid #54ccc9;
    border-right: 52px solid transparent;
  }

  @media (max-width: ${BREAKPOINTS.md}px) {
    :before {
      display: none;
    }
  }
`;

const StyledH1 = styled(H1)`
  ${fontMagra}
`;

const Text = styled.p`
  ${fontMontserrat}
`;

const TextWrapper = styled.div`
  max-width: 660px;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 16px;
    line-height: 21px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 12px;
    line-height: 16px;
  }
`;
const Bg = styled.img`
  margin-top: -14px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    content: url('${bg_tablet}');
    width: 1316px;
    margin-top: -90px;
    margin-left: -30px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    width: 164.5vw;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 150vw;
    margin-top: -50px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 618px;
    margin-top: -41px;
    margin-left: -16px;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin-left: -30px;
  }
`;
