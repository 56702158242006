import React from 'react';
import { Header, Footer } from 'components';
import {
  MainScreen,
  WorkFor,
  OurUnique,
  OurGoal,
  Stats,
  NextPurposes,
  History,
  Principles,
  Technologies,
  TechnologiesTabs,
  Credo
} from './sections';

export const MainPage = () => {
  return (
    <div>
      <Header />

      <MainScreen />
      <WorkFor marginTop={104} lgMarginTop={90} smMarginTop={40} />
      <OurUnique marginTop={46} />
      <OurGoal marginTop={-71} lgMarginTop={-49} smMarginTop={-4} />
      <Stats marginTop={-92} lgMarginTop={-43} smMarginTop={-21} />
      <NextPurposes marginTop={104} lgMarginTop={104} smMarginTop={52} />
      <History marginTop={1} />
      <Principles />
      <Technologies marginTop={192} lgMarginTop={112} smdMarginTop={39} />
      <TechnologiesTabs marginTop={161} lgMarginTop={79} smdMarginTop={35} />
      <Credo marginTop={185} lgMarginTop={125} smdMarginTop={55} />
      <Footer marginTop={100} lgMarginTop={167} smdMarginTop={50} />
    </div>
  );
};
