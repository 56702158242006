import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {API_URL, BREAKPOINTS, COLORS, formatDateFromStrapi, getImageUrl} from 'utils';
import play_icon from 'assets/news/play_icon.svg';

export const NewsBlock = ({ data, tall = false, wide = false }) => {
  const imgUrl = getImageUrl(data.img?.data?.attributes?.url);

  return (
    <Wrapper tall={tall} wide={wide}>
      <NewsPreview
        data={{
          img: imgUrl
        }}
        wide={wide}
      />
      <Date>{formatDateFromStrapi(data.date)}</Date>
      <Title>
        <strong>{data.title}</strong>
      </Title>
      <Descr tall={tall} wide={wide}>
        {data.description}
      </Descr>

      <StyledLink to={`/news/${data.id}`}>Читать далее</StyledLink>
    </Wrapper>
  );
};

export const NewsPreview = ({ data, wide = false, lgLarge = false }) => {
  if (data) {
    return (
      <Preview video={data.video} wide={wide} lgLarge={lgLarge}>
        <img src={data.img} alt={''} />
        {data.video && <VideoBadge lgLarge={lgLarge}>Видео</VideoBadge>}
      </Preview>
    );
  }

  return null;
};

const Date = styled.p`
  margin-top: 12px;
  color: ${COLORS.darkGray};
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 19px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 12px;
    line-height: 16px;
  }
`;
const Title = styled.p`
  max-height: 42px;
  overflow: hidden;
  margin-top: 6px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 2px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    max-height: unset;
  }
`;
const Descr = styled.p`
  ${(p) => `
  margin-top: 9px;
  font-weight: 300;
  max-height: 63px;
  overflow: hidden;
  ${p.tall ? 'max-height: 276px;' : ''}
  ${p.wide ? 'max-height: 64px;' : ''}
  
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 6px;
    ${p.tall ? 'max-height: 63px;' : ''}
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 12px;
    line-height: 16px;
    max-height: 48px;
  }
`}
`;
const Wrapper = styled.div`
  ${(p) => `
  height: ${p.tall || p.wide ? '658px' : '413px'};
  background: #ffffff;
  padding: 9px 11px 57px ${p.wide ? '10px' : '8px'};
  border: 1px solid #D9D9D9;
  font-size: 16px;
  line-height: 21px;
  position: relative;
  overflow: hidden;
  
  @media (max-width: ${BREAKPOINTS.lg}px) {
    height: ${p.wide ? '552px' : '376px'};
    padding: ${p.wide ? '11px' : '14px'} 13px 57px 14px;
    font-size: 16px;
    line-height: 21px;
    ${
      p.wide
        ? `
      ${Date} {
        margin-top: 34px;
      }
      ${Title} {
        margin-top: 10px;
      }
      ${Descr} {
        margin-top: 9px;
      }
    `
        : ''
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    height: auto;
    min-height: 342px;
    padding: 7px 7px 38px 7px;
    font-size: 14px;
    line-height: 18px;
    ${Date} {
      margin-top: 14px;
    }
    ${Title} {
      margin-top: 6px;
    }
    ${Descr} {
      margin-top: 4px;
    }
  }
`}
`;
const Preview = styled.div`
  ${(p) => `
  width: 100%;
  height: ${p.wide ? '446px' : '205px'};
  position: relative;
  overflow: hidden;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  ${
    p.video
      ? `
    :before {
      content: "";
      position: absolute;
      top: calc(50% + 5px);
      left: 50%;
      transform: translate(-50%, -50%);
      width: ${p.wide ? '104px' : '48px'};
      height: ${p.wide ? '182px' : '84px'};
      background: url("${play_icon}") center/contain no-repeat;
    }
    :after {
      content: "";
      position: absolute;
      right: -67px;
      bottom: -1px;
      width: 150px;
      height:28px;
      background: #67CCCD;
      transform: skewX(-30deg);
    }
  `
      : ''
  }
  
  @media (max-width: ${BREAKPOINTS.lg}px) {
   height: ${p.wide ? '332px' : '149px'};
   :before {
    width: ${p.lgLarge ? '79px' : '35px'};
    height: ${p.lgLarge ? '137px' : '61px'};
    ${p.lgLarge ? 'top: calc(50% + 9px);' : ''}
   }
   :after {
    height: ${p.lgLarge ? '27px' : '20px'};
    right: ${p.lgLarge ? '-67px' : '-78px'};
   }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
   height: 188px;
   :before {
    width: 45px;
    height: 79px;
    top: calc(50% + 5px);
   }
   :after {
    height: 23px;
    right: -86px;
   }
  }
`}
`;
const VideoBadge = styled.p`
  color: ${COLORS.white};
  position: absolute;
  right: 12px;
  bottom: 2px;
  z-index: 1;
  font-size: 18px;
  line-height: 23px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    bottom: 0;
    ${(p) =>
      p.lgLarge
        ? ''
        : `
       font-size: 16px;
      line-height: 21px;
    `}
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    right: 9px;
    bottom: 2px;
    font-size: 14px;
    line-height: 18px;
  }
`;
const StyledLink = styled(Link)`
  position: absolute;
  left: ${(p) => (p.wide ? '10px' : '8px')};
  bottom: 20px;
  font-weight: 700;
  color: #54ccc9;
  text-decoration: none;
  cursor: pointer;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    left: 15px;
    bottom: 16px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 12px;
    line-height: 16px;
    left: 7px;
    bottom: 8px;
  }
`;
