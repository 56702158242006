import React from 'react';
import { Slider } from 'components/common';
import { ProjectInfo, ProjectsCommon, ProjectAsideInfo } from '../components';
import { project8 } from 'assets/projects';
import { Col, Container, Row } from '../../../ui/layout';
import styled from 'styled-components';
import {
  ill1,
  ill2,
  ill3,
  ill4,
  ill5,
  ill6,
  ill7,
  ill8,
  ill9,
  ill10,
  ill11,
  ill12,
  ill13,
  ill14,
  ill15
} from 'assets/projects/schoolNefteyugansk';
import { VideoBlock } from '../components/VideoBlock';
import { BREAKPOINTS } from '../../../utils';

export const SchoolNefteyuganskProject = () => {
  return (
    <ProjectsCommon>
      <Row gap={14}>
        <Col width={'66.66%'} lgWidth={'100%'}>
          <MainColWrapper>
            <ProjectInfo
              ill={project8}
              title={'Школа Интернат '}
              location={'Нефтеюганск'}
            >
              «Специальное (коррекционное) образовательное учреждение для
              обучающихся, воспитанников с отклонениями в развитии
              «Нефтеюганская специальная (коррекционная)общеобразовательная
              школа-интернат VIII вида», расположенного по адресу: Тюменская
              область, Ханты-Мансийский автономный округ-Югра, г. Нефтеюганск,
              мкр.17, земельный участок №37 вр.
              <br />
              <br />
              Школа-интернат VIII вида – общеобразовательное учебное заведение,
              для обучающихся воспитанников с ограниченными возможностями
              здоровья.
            </ProjectInfo>
          </MainColWrapper>
        </Col>
        <Col width={'33.33%'} lgWidth={'100%'}>
          <AsideContainer>
            <AsideIllRow gap={31} lgGap={14} cols={1} lgCols={2} smdCols={1}>
              <Col>
                <ProjectAsideInfo
                  info={[
                    {
                      title: 'Площадь земельного участка',
                      value: '22043 м2'
                    },
                    {
                      title: 'Общая площадь здания',
                      value: '7504,65 м2'
                    },
                    {
                      title: 'Количество учащихся',
                      value: '230'
                    },
                    {
                      title: 'Количество надземных этажей',
                      value: '4'
                    }
                  ]}
                />
              </Col>
              <Ill1Wrapper>
                <Img src={ill1} alt={''} />
              </Ill1Wrapper>
            </AsideIllRow>
          </AsideContainer>
        </Col>
      </Row>

      <Ill2Wrapper>
        <Img src={ill2} alt={''} />
      </Ill2Wrapper>

      <Row gap={29} marginTop={48} smdMarginTop={24}>
        <Col lgWidth={'100%'}>
          <MainColWrapper>
            <P>
              Здание общеобразовательного комплекса состоит из следующих
              функциональных объемов:
              <br />
              <br />- блок школы с учебными и специализированными помещениями
              <br />- блок спортивных помещений с раздевальными и сопутствующими
              помещениями
              <br />- блок интерната, рассчитанный на 30 человек.
              <br />
              <br />
              Объём школьного блока разделен на несколько функциональных зон:
              <br />
              <br />
              На первом этаже:
              <br />
              <br />- зона подготовительных групп с помещениями для групп
              продленного дня и спальными помещениями;
              <br />- начальная школа (1-4 классы);
              <br />- актовый зал, обеденный зал и пищеблок;
              <br />- блок специализированных учебных помещений с теплицей
              <br />
              <br />
              На втором этаже:
              <br />
              <br />- учебные кабинеты средней и старшей школы (5-12 классы):
              <br />- специализированные и профессиональные учебные помещения;
              <br />- административные помещения
              <br />
              <br />
              Для обеспечения качественным питанием учащихся и проживающих
              пищеблок предусмотрен на сырье.
              {/* SLIDER */}
              <Container lgHidden marginTop={60}>
                <Slider images={[ill3, ill4]} />
              </Container>
              <br />
              <br />
              Образовательная организация рассчитана на 230 учащихся с 1-12
              классы и 2 подготовительные группы. Классы-комплекты формируются
              без учета ступени образования, так как уровни обучения в
              реализуемых программах не определены.
              <br />
              <br />
              В образовательном учреждении предусмотрена возможность обучения
              воспитанников из маломобильных групп населения в количестве 10
              (десять) обучающихся категории М1-3 и 7 (семь) обучающихся
              категории М4. В интернате места для воспитанников из маломобильных
              групп населения предусмотрены в количестве 3 (три) категории М1-3
              (жилые комнаты расположены на 2-м этаже) и 2(два) категории М4
              (жилые комнаты расположены на 1-м этаже в непосредственной
              близости от наружного выхода).
              <br />
              <br />
              В спортивном блоке предусмотрен большой спортивный зал (12х24м);
              зал ритмики (6х9м); тренажерный зал и зал ЛФК 6х12м каждый. Все
              спортивные залы имеют прилегающие помещения раздевален для
              учащихся с душевыми и санузлами, а также помещения для дежурного
              тренера на каждом этаже.
              <br />
              <br />
              В блоке интерната размещены 10 жилых комнат. Каждая комната
              рассчитана на 3 проживающих. На первом этаже предусмотрена жилая
              ячейка, с возможностью заселения МГН на инвалидной коляске. Жилые
              комнаты попарно сблокированы в ячейки со своими санузлами,
              душевыми и зонами для хранения личных вещей. На каждом этаже
              интерната размещаются игровые, комнаты для самоподготовки, комнаты
              дежурных воспитателей и медицинские кабинеты.
              <br />
              <br />
              В блоке интерната предусмотрен медицинский блок с палатой
              изолятора для кратковременного пребывания 2-х человек.
              <br />
              <br />
              В здании предусмотрены два грузопассажирских лифта,
              грузоподъемностью 1100кг и один подъемник для МГН в блоке теплицы.
              Лифты предназначены для транспортировки МГН и пожарных
              подразделений, оба лифта имеют поэтажные лифтовые холлы. Холлы при
              лифтах являются зонами безопасности для МГН IV группы на этажах,
              расположенных выше первого. В дополнение к лифтовым
              <br />
              холлам запроектированы ещё 3 зоны безопасности для МГН в осях 3-4
              И/1-Б, в осях 11-12 Е/1-Ж/1, в осях 29-31 А’-И'/3, выходящих
              непосредственно в лестничные клетки.
              <br />
              <br />
              Количество МГН, размещаемых в зонах безопасности на 2 этаже
              принято не менее 7 инвалидов- колясочников с сопровождающими –
              согласно технического задания.
              <br />
              <br />В подвальных помещениях расположены часть помещений
              пищеблока с кратковременным пребыванием не более 6 человек
              персонала, венткамеры, тепловой пункт, водомерный узел и насосная,
              другие технические и хозяйственные помещения, в т.ч. помещение для
              хранения инвентаря в блоке учебного корпуса, предусмотренное по
              процессу деятельности.
            </P>
          </MainColWrapper>
        </Col>
      </Row>

      {/* SLIDER */}
      <Container lgHidden marginTop={60}>
        <Slider
          images={[ill5, ill6, ill7, ill8, ill9, ill10, ill11, ill12, ill13]}
        />
      </Container>

      {/* MobileSlider */}
      <Container hidden lgVisible lgMarginTop={48} smdMarginTop={24}>
        <Slider
          images={[
            ill3,
            ill4,
            ill5,
            ill6,
            ill7,
            ill8,
            ill9,
            ill10,
            ill11,
            ill12,
            ill13,
            ill14,
            ill15
          ]}
        />
      </Container>

      <Row gap={30} marginTop={53} lgMarginTop={48} smdMarginTop={24}>
        <Col>
          <P>
            Площадь учебных кабинетов принята из расчета: при фронтальных формах
            занятий не менее 2,5 м2 /уч.
            <br />
            <br />
            не менее 3,5 м2 /уч. при организации групповых форм работы и
            индивидуальных занятий.
            <br />
            <br />
            специализированных кабинетах и лаборатории по естественным наукам -
            3,5 м2 /уч.
            <br />
            <br />
            кабинета информатики - 4,5 м2 /уч.
            <br />
            <br />
            мастерских по изучению технологий и труда - 4,0-7,0 м2 /уч.
            <br />
            <br />
            Число специализированных учебных кабинетов и предметных кабинетов
            определено учебными часами по каждой дисциплине с дифференциацией на
            теоретические и практические занятия. Количество предметных
            кабинетов определено, исходя из количества часов по предметам,
            изучаемых в предметных кабинетах, занятий в спортивном зале,
            количества параллелей классов, предельной и дневной нагрузкой на
            обучающихся.
            <br />
            <br />
            Учебные помещения начальной школы (1-4 классы) располагаются в
            частично в правом крыле на 1 этаже, а также занимают половину 2
            этажа учебного корпуса школы. Ученики начальной общеобразовательной
            школы обучаются в закрепленных за каждым классом универсальных
            учебных помещениях, сгруппированных в учебные секции. Так же в
            учебном блоке начальных классов запроектированы 3 универсальных
            помещения для групп продленного дня, кабинет труда. В учебной секции
            для обучающихся первых классов, посещающих группы продленного дня,
            предусмотрены спальни с игровыми зонами. Для учащихся основного
            общего - среднего образования предусмотрена организация
            образовательной деятельности по классно-кабинетной системе.
            <br />
            <br />
            Учебные помещения и кабинеты для 5-11 классов включают: кабинеты
            химии и физики и биологии с практикумами и лаборантскими; кабинеты
            истории, кабинет ОБЖ, классы для занятий по общеобразовательным
            предметам, 6 кабинетов иностранного языка, 2 кабинета информатики с
            лаборантскими, кабинет музыки, кабинет ИЗО, 2 кабинета трудовой
            подготовки для девочек (кулинария и домоводство; мастерская по
            <br />
            <br />
            обработке тканей и технологии) с подсобным помещением, 2 кабинета
            трудовой подготовки для мальчиков (слесарная и столярная мастерские)
            с подсобным помещением.
            {/* SLIDER */}
            <Container lgHidden marginTop={60}>
              <Slider images={[ill14, ill15]} />
            </Container>
            <br />
            <br />
            Для детей начальных, средних и старших классов, нуждающихся в
            психолого-педагогической помощи, предусмотрены отдельные кабинеты:
            <br />
            <br />
            кабинет учителя-логопеда
            <br />
            <br />
            кабинет педагога-психолога
            <br />
            <br />
            В помещениях начальных классов, лаборантских, учебных кабинетах
            химии, физики, ИЗО, биологии, слесарной и столярной мастерских,
            кабинетах домоводства и помещениях медицинского назначения
            установлены умывальные раковины.
            <br />
            <br />
            Слесарная и столярная мастерские расположены на первом этаже здания,
            имеют вход из коридора и общий обособленный выход через тамбур
            непосредственно на улицу. При мастерских предусмотрено помещение
            инструментальной.
            <br />
            <br />
            Расположение рекреационных помещений находится в непосредственной
            близости к учебным помещениям. Площадь зальной рекреации принята из
            расчета не менее 2,0 м2 /уч. Площадь коридорной рекреации принята из
            расчета не менее 0,6 м2 /уч.
            <br />
            <br />
            Спортивные залы расположены в общешкольном корпусе школы и имеют
            рассредоточенный доступ из начальной и основной и средней школ через
            рекреационный коридор и переход. Проектом предусмотрены два
            спортивных зала, бассейн, тренажерный зал, зал для хореографии.
            Количество и размеры спортзалов определены исходя из назначения и
            количества учебных часов с учетом разделения использования начальной
            и старшей школы.
            <br />
            <br />
            спортивный зал размером 18,0х30,0 м, (Ь до низа выступающих
            конструкций 7,0 м) с зоной для болельщиков
            <br />
            <br />
            спортивный зал размером 12,0х24,0 м, (Ь до низа выступающих
            конструкций 6,0 м)
            <br />
            <br />
            зал для хореографии - 16,80х8,00 м, (Ь до низа выступающих
            конструкций 4,0 м)
            <br />
            <br />
            тренажерный зал - 19,00х13,00 м, (Ь до низа выступающих конструкций
            4,0 м)
            <br />
            <br />
            При спортивных залах предусмотрены: снарядные, помещение для
            хранения уборочного инвентаря и приготовления дезинфицирующих и
            моющих растворов, раздевальные, душевые и санузлы для мальчиков и
            девочек. Для МГН в санузлах предусмотрена универсальная кабина, в
            душевой - универсальный душ. При каждом зале есть кабинет учителя
            <br />
            <br />
            физкультуры с душевой и санузлом. При туалетах и раздевалках
            оборудованы раковины для мытья рук.
            <br />
            <br />
            Также на 1 этаже расположен бассейн с размером чаши 11,0х25,0 м.
            <br />
            <br />
            При нем предусмотрены раздевальные, душевые и санузлы для мальчиков
            и девочек. Для МГН предусмотрены отдельные универсальные кабины с
            санузлом и душем. Рядом расположено помещение инструктора-тренера с
            душем и санузлом, снарядная, кабинет медсестры, комната для хранения
            реагентов, лаборатория анализа воды, зал управления, помещение для
            хранения уборочного инвентаря и приготовления дезинфицирующих и
            моющих растворов.
            <br />
            <br />
            Центральная входная группа учебного корпуса предусматривает
            отдельные входы в здание для начальной и основной школы,
            расположенные по разные стороны от поста охраны.
            <br />
            <br />
            Гардеробные для учащихся предусмотрены раздельные для каждого
            класса. Для учащихся 1-4 классов гардероб расположен в вестибюле со
            стороны входа начальной школы; для учащихся 5-11 классов в вестибюле
            со стороны входа учеников основной школы.
            <br />
            <br />
            На каждом этаже запроектированы туалеты для персонала и учащихся, в
            том числе для групп МГН. Количество приборов установлено согласно СП
            251.1325800.2016. Для персонала выделены санузлы из расчета не менее
            1 унитаз на 20 человек. Для персонала и обучающихся основного общего
            и среднего общего образования предусмотрены комнаты личной гигиены.
            <br />
            <br />
            На каждом этаже предусмотрено помещение для хранения и обработки
            уборочного инвентаря, приготовления дезинфекционных растворов,
            оборудованное поддоном и подводкой к нему холодной и горячей воды.
            Общая площадь помещений принята из расчета не менее 0,8 м2 на каждые
            100 м2 полезной площади, но не менее 2,0 м2.
            <br />
            <br />
            На 1 -ом этаже рядом с вестибюлем общешкольного корпуса расположены
            помещения медицинского назначения.
            <br />
            <br />
            Помещения медицинского назначения сгруппированы в одном блоке:
            <br />
            <br />
            кабинет врача
            <br />
            <br />
            процедурная и прививочный кабинеты
            <br />
            <br />
            стоматологический кабинет
            <br />
            <br />
            помещение для приготовления дезинфицирующих растворов и
            <br />
            <br />
            хранения уборочного инвентаря
            <br />
            <br />
            санузел с учетом размещения МГН на кресле-коляске
            <br />
            <br />
            кабинет психолога
            <br />
            <br />
            кабинет логопеда.
            <br />
            <br />
            <br />
            Справа от вестибюльной группы размещен пищеблок с обеденным залом.
            Для организации питания предусмотрен набор помещений в соответствии
            с санитарно-эпидемиологическими требованиями к организации питания
            обучающихся в общеобразовательных организациях. Площадь обеденного
            зала столовой принята из расчета не менее 0,8 м2 на одно посадочное
            место. Число посадочных мест определено из расчета посадки в одну
            перемену учащихся, а также преподавателей и администрации
            общеобразовательного учреждения (всего 702 посадочных места). Вход в
            обеденный зал предусмотрен для начальных, средних и старших классов
            отдельно.
            <br />
            <br />
            Объемно-планировочные решения пищеблока предусматривают
            последовательность технологических процессов, исключающих встречные
            потоки сырой и готовой продукции. Загрузка продуктов производится
            через отдельный вход с загрузочной. Вход для персонала столовой
            осуществляется непосредственно с улицы со стороны загрузочной.
            <br />
            <br />
            С 1 -го этажа общешкольного корпуса запроектирована каскадная
            рекреационная зона, которая является связующим звеном учебной и
            общешкольной части в зоне основной и старшей школы.
            <br />
            <br />
            Из рекреации предусмотрен выход на эксплуатируемую кровлю
            общественного блока, где при благоприятных погодных условиях создана
            возможность проведения уроков на открытом воздухе. Над блоком
            старших классов запланирован выход на смотровую площадку с видом на
            бухту Патрокл.
            <br />
            <br />
            На 2 этаже, справа от актового зала, организован переход в зону
            начальной школы, для организации связи учебного и общешкольного
            корпуса.
            <br />
            <br />
            В центре общешкольной части размещен актовый зал с сопутствующими
            помещениями. Размеры определены числом посадочных мест из расчета не
            менее 0,65 м2 на одно посадочное место. Актовый зал занимает два
            уровня (первый и второй этаж).
            <br />
            <br />
            На 2-ом этаже общешкольной части размещена библиотек (медиатека),
            являющаяся одним из структурных подразделений школы, которое
            включает совокупность фонда книг и разнообразных технических и
            информационных средств. Общая площадь библиотеки принята по
            расчетному показателю на 1 учащегося не менее 0,6 м2.
            <br />
            <br />
            В школьной библиотеке предусмотрены зоны:
            <br />
            <br />
            информационный пункт для выдачи-приема литературы
            <br />
            <br />
            фонд открытого доступа читателей
            <br />
            <br />
            фонд закрытого хранения литературы
            <br />
            <br />
            место для работы с каталогами
            <br />
            <br />
            читальный зал для начальной школы, оборудованный
            <br />
            <br />
            мультимедийными средствами обучения
            <br />
            <br />
            читальный зал для основной школы, оборудованный
            <br />
            <br />
            мультимедийными средствами обучения
            <br />
            <br />
            компьютерный зал с лабораторией проектной деятельности.
            <br />
            <br />
            Также на 2 этаже размещены спортивные залы и зал хореографии с
            <br />
            <br />
            раздевалками и душевыми.
            <br />
            <br />
            На 2-ом этаже расположены учебные кабинеты, кабинеты заместителей по
            воспитательной работе и учебной части, кабинет социального педагога.
            <br />
            <br />
            На 3-м этаже учебного корпуса щколы расположены учебные кабинеты,
            практикумы, лаборантские.
            <br />
            <br />
            Кровля плоская с внутренним водостоком, с эксплуатируемыми
            участками, предназначенными для проведения занятий на открытом
            воздухе и отдыха учащихся в свободное время. Высота ограждений на
            эксплуатируемой кровле - 1200 мм. На кровлю предусмотрены
            рассредоточенные выходы непосредственно из лестничных клеток каждого
            блока
            <br />
            <br />
            Для инженерного оборудования и хозяйственных нужд предусмотрены
            следующие помещения:
            <br />
            <br />в техническом подполье: венткамеры, ИТП, насосная и водомерный
            узел, серверная, помещения для обслуживания бассейна на 1-ом этаже,
            с отдельным входом с улицы, помещение электрощитовой.
          </P>
        </Col>
      </Row>

      {/* <VideoWrapper>
        <VideoBlock
          img={project8}
          subtitle={'Видеопрезентация проекта «Школа интернат»'}
        />
      </VideoWrapper> */}
    </ProjectsCommon>
  );
};

const MainColWrapper = styled.div``;
const AsideContainer = styled.div`
  position: relative;
  margin-right: -14px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin: 0;
  }
`;
const AsideIllRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 48px;
    align-items: center;
    padding-bottom: 48px;
    position: relative;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    flex-direction: column-reverse;
    gap: 19px;
    margin-top: 32px;
    padding-bottom: 24px;
  }
`;
const Img = styled.img`
  width: 100%;
  display: block;
  object-fit: cover;
`;

const Ill1Wrapper = styled(Col)`
  margin-top: 5px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: auto;
    flex-grow: unset;
    > img {
      max-width: 350px;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    > img {
      max-width: unset;
    }
  }
`;
const Ill2Wrapper = styled.div`
  margin-top: 25px;
  max-width: 1193px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 3px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 14px;
  }
`;

const VideoWrapper = styled.div`
  margin-top: 60px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 61px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 35px;
  }
`;

const P = styled.p`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 24px;
    line-height: 31px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 16px;
    line-height: 21px;
  }
`;
