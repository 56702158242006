import { gql } from '@apollo/client';

export const ALL_ARTICLES = gql`
  query ($page: Int!, $pageSize: Int!) {
    articles(sort: "date:desc", pagination: { page: $page, pageSize: $pageSize}) {
        data {
            id
            attributes {
                title
                description
                date
                img {
                    data {
                        attributes {
                            url
                        }
                    }
                }
            }
        }
        meta {
            pagination {
                pageCount
            }
        }
    }
  }
`;

export const GET_ARTICLE = gql`
  query GetArticle($id: ID) {
    article(id: $id) {
      data {
        id
        attributes {
          title
          description
          date
          img {
            data {
              attributes {
                url
              }
            }
          }
          Selection {
            ... on ComponentSelectSection {
              Text
            }
            ... on ComponentSelectImgBox {
              img {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            ... on ComponentSelectSliderImgBox {
              slider {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_OTHER_NEWS = gql`
  query GetOtherNews($id: ID) {
    articles(
      sort: "createdAt:desc"
      pagination: { limit: 5 }
      filters: { id: { notContains: $id } }
    ) {
      data {
        id
        attributes {
          date
          title
          description
          img {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
