import React, { useRef } from 'react';
import { Slider } from 'components/common';
import { ProjectInfo, ProjectsCommon } from '../components';
import { project4 } from 'assets/projects';
import { Col, Container, Row } from '../../../ui/layout';
import styled from 'styled-components';
import {
  ill1,
  ill2,
  ill3,
  ill4,
  ill5,
  ill2_mobile,
  ill5_mobile
} from 'assets/projects/stadium';
import { VideoBlock } from '../components/VideoBlock';
import { BREAKPOINTS } from '../../../utils';

export const StadiumProject = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <ProjectsCommon>
      <Row gap={29}>
        <Col width={'66.66%'} lgWidth={'100%'}>
          <MainColWrapper>
            <ProjectInfo
              ill={project4}
              title={'Стадион Шахтер'}
              location={'Шахты'}
            >
              «Реконструкция стадиона «Шахтер» МОУ ДОД ДЮСШ №5» в г.Шахты
              Ростовской области. Местоположение объекта: Ростовская область, г.
              Шахты, проспект Василия Алексеева, 1.
            </ProjectInfo>
          </MainColWrapper>
        </Col>
        <Col width={'33.33%'} lgWidth={'100%'}>
          <AsideIllRow gap={31} cols={1} lgCols={2} smdCols={1}>
            <Img1Wrapper>
              <Img src={ill1} alt={''} />
            </Img1Wrapper>
            <Img2Wrapper>
              <Img src={ill2} alt={''} />
            </Img2Wrapper>
          </AsideIllRow>
        </Col>
      </Row>

      <Row gap={29} marginTop={60} lgMarginTop={36} smdMarginTop={38}>
        <IllustrationsWrapper>
          {/* SLIDER */}
          <Container lgHidden marginTop={60}>
            <Slider images={[ill5, ill4, ill3]} />
          </Container>
        </IllustrationsWrapper>
        <MainColWrapper>
          <IllustrationsWrapperTablet>
            <Row
              gap={31}
              smdGap={17}
              xsGap={8}
              colsMB={62}
              smdColsMB={36}
              xsColsMB={16}
            >
              <Col width={'50%'}>
                <Img src={ill5_mobile} alt={''} />
              </Col>
              <Col width={'50%'}>
                <Img src={ill4} alt={''} />
              </Col>
              <Col width={'50%'}>
                <Img src={ill3} alt={''} />
              </Col>
            </Row>
          </IllustrationsWrapperTablet>
        </MainColWrapper>
      </Row>
      <Row marginTop={60}>
        <p>
          Стадион состоит из трех трибун на 8309 мест с подтрибунными
          помещениями: западной на 1776 мест, восточной на 3087 мест и южной на
          3446 мест, которые размещены вокруг спортивного ядра. Этажность трибун
          1–3 этажа. Легкоатлетическое спортивное ядро: поле для игры в футбол
          размером 105x68 м с искусственным покрытием и дренажной системой,
          круговая беговая дорожка на шесть дорожек с сектором забега на 100 м с
          синтетическим покрытием, яма с водой (для стипль-чеза), сектор для
          прыжков в длину, сектор для прыжков в высоту, сектор для прыжков с
          шестом. В подтрибунном пространстве: судейская, рабочие помещения СМИ,
          санузлы для зрителей, кладовая спортивного инвентаря, аппаратная,
          гардероб, две командные раздевалки с душевыми кабинами и санузлами,
          массажная, тренерские, кабинет врача, процедурная, помещение для
          допинг-контроля, методический кабинет, четыре легкоатлетические
          раздевалки с душевыми кабинами и санузлами, комната отдыха,
          технические помещения. Блочно-модульная газовая котельная – 1,032
          Гкал/час. Трансформаторная подстанция, парковки для временного
          хранения автомобилей S= 11 000 м2. Наружное освещение участка 1 = 1440
          м. Существующие вспомогательные объекты комплекса: гараж, мачты
          освещения, светодиодное табло. Площадь отведенного участка 4,0092 га.
          Кадастровый номер земельного участка 61:59:0030330:2723.
        </p>
        {/* <VideoWrapper>
          <VideoBlock
            img={project4}
            subtitle={'Видеопрезентация проекта «Стадион Шахтер»'}
          />
        </VideoWrapper> */}
      </Row>
    </ProjectsCommon>
  );
};

const MainColWrapper = styled.div`
  padding-right: 2px;
`;
const AsideIllRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 66px;
    padding-bottom: 36px;
    position: relative;

    :after {
      content: '';
      position: absolute;
      left: 17px;
      bottom: 0;
      width: calc(100% - 36px);
      height: 1px;
      background: #d9d9d9;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 24px;
    padding-bottom: 38px;
    :after {
      left: 23px;
      width: calc(100% - 46px);
    }
  }
`;

const Img = styled.img`
  width: 100%;
  display: block;
  object-fit: cover;
`;

const Img1Wrapper = styled(Col)`
  margin-top: 20px;
  margin-right: 2px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin: 0;
    > img {
      height: 100%;
    }
    @media (max-width: ${BREAKPOINTS.smd}px) {
      margin-bottom: 31px;
      > img {
        height: 233px;
      }
    }
  }
`;
const Img2Wrapper = styled(Col)`
  margin-top: 58px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin: 0;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    > img {
      height: 239px;
      content: url('${ill2_mobile}');
    }
  }
`;
const IllustrationsWrapper = styled.div`
  max-width: 100%;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`;
const IllustrationsWrapperTablet = styled.div`
  display: none;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: block;
    margin-top: 60px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 42px;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  margin-top: 60px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 61px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 35px;
  }
`;
