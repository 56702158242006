import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../../utils';

export const TeamCard = ({ data, small = false }) => {
  return (
    <Wrapper>
      <Photo src={data.photo} alt={''} />

      <TextWrapper small={small}>
        <Name>{data.name}</Name>
        <Position>{data.position}</Position>
        <Descr>{data.descr}</Descr>
      </TextWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 18px;
    line-height: 23px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 12px;
    line-height: 16px;
  }
`;
const Photo = styled.img`
  display: block;
  height: 345px;
  width: 100%;
  object-fit: cover;

  @media (max-width: ${BREAKPOINTS.smd}px) {
    height: 163px;
  }
`;
const TextWrapper = styled.div`
  position: relative;
  min-height: 187px;
  margin-top: 25px;
  padding-left: 14px;
  :before {
    content: '\\25CF';
    height: 0;
    width: 0;
    color: #000000;
    font-size: 12px;
    position: absolute;
    top: ${(p) => (p.small ? '0' : '-12px')};
    left: -4px;
  }
  :after {
    content: '';
    position: absolute;
    top: ${(p) => (p.small ? '12px' : '0')};
    left: -1px;
    height: ${(p) => (p.small ? '72px' : '107px')};
    border-right: 1px dashed #000000;
  }

  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-right: -12px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    min-height: 160px;
    margin-top: 12px;
    margin-right: 0;
    padding-left: 7px;
    :before {
      top: ${(p) => (p.small ? '-5px' : '-8px')};
      left: -3px;
      font-size: 10px;
    }
    :after {
      top: ${(p) => (p.small ? '5px' : '0')};
      height: ${(p) => (p.small ? '34px' : '51px')};
    }
  }
`;
const Name = styled.p`
  font-weight: 600;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    max-width: 200px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    max-width: 100px;
  }
`;
const Position = styled.p`
  margin-top: 20px;
  white-space: pre-line;
`;
const Descr = styled.p`
  font-weight: 600;
  color: #bdbdbd;
  margin-top: 20px;
`;
