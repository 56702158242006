import React from 'react';
import { Container } from 'ui/layout';
import { Tabs } from './Tabs';

export const TechnologiesTabs = (props) => {
  return (
    <Container {...props}>
      <Tabs />
    </Container>
  );
};
