import React from 'react';
import styled from 'styled-components';
import { Tabs } from './Tabs';
import { Container } from 'ui/layout';
import { BREAKPOINTS } from 'utils';

export const Principles = () => {
  return (
    <Wrapper paddingTop={135} lgPaddingTop={116} smdPaddingTop={48}>
      <Tabs />
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  background: #f9f9f9;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    background: transparent;
  }
`;
