export const API_URL = process.env.REACT_APP_API_URL;

export const COLORS = {
  default: '#23222F',
  primary1: '#106EB7',
  primary2: '#54CCC9',
  accent1: '#FAB43A',
  accent2: '#DD563A',
  accent3: '#68A740',
  black: '#23222F',
  darkGray: '#696969',
  gray: '#909090',
  lightGray: '#BDBDBD',
  white: '#FFFFFF',
  dark: '#074A87'
};

export const BREAKPOINTS = {
  xxl: 1600,
  xl: 1400,
  lg: 1100,
  md: 850,
  smd: 650,
  sm: 460,
  xs: 360
};

export * from './helpers';
