import React from 'react';
import { Header, Footer } from 'components/common';
import { MainContainer } from 'ui/layout';
import { BREAKPOINTS } from 'utils';
import styled from 'styled-components';
import figure from 'assets/common/figure_yellow.svg';

export const ProjectsCommon = ({ children }) => {
  return (
    <div>
      <Header active={'projects'} />

      <Wrapper>
        <Figure1 src={figure} alt={''} />

        <MainContainer
          lgMaxWidth={800}
          mdMaxWidth={800}
          smdMaxWidth={800}
          mdPaddingX={50}
        >
          <Title>Проекты компании</Title>

          <div>{children}</div>
        </MainContainer>

        <Figure2 src={figure} alt={''} />
      </Wrapper>

      <Footer />
    </div>
  );
};

const Wrapper = styled.div`
  position: relative;
  font-weight: 300;
  padding-top: 39px;
  padding-bottom: 120px;
  :after {
    content: '';
    position: absolute;
    left: -76px;
    top: 0;
    height: 89px;
    width: 100px;
    background: #fab43a;
    transform: skew(-30deg);
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding-top: 80px;
    padding-bottom: 160px;
    :after {
      left: -76px;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-top: 48px;
    padding-bottom: 96px;
    :after {
      height: 26px;
      left: -90px;
      top: 32px;
    }
  }
`;

const Figure1 = styled.img`
  position: absolute;
  right: -249px;
  top: 84px;
  object-fit: contain;
  width: 499px;
  z-index: -1;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 324px;
    top: 45px;
    right: -213px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 131px;
    top: 38px;
    right: -58px;
  }
`;
const Figure2 = styled(Figure1)`
  top: unset;
  right: unset;
  left: -245px;
  bottom: 2px;
  width: 375px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 263px;
    bottom: -2px;
    left: -183px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    display: none;
    width: 214px;
    bottom: -27px;
    left: -162px;
  }
`;
const Title = styled.p`
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  background: #fab43a;
  height: 44px;
  padding: 12px 47px 10px 42px;
  margin-left: 15px;
  clip-path: polygon(24px 0, calc(100% - 24px) 0%, 100% 100%, 0% 100%);
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-left: 0;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 12px;
    line-height: 15px;
    height: 29px;
    padding: 8px 30px 8px 30px;
    clip-path: polygon(16px 0, calc(100% - 16px) 0%, 100% 100%, 0% 100%);
  }
`;
