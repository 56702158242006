import React, { useRef } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import { BREAKPOINTS } from '../../../utils';
import { slider_arrow } from 'assets/stats';

export const Slider = ({ images, anotherSlider = false }) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  return (
    <WrapperSwiper>
      <StyledSwiper
        modules={[Navigation]}
        spaceBetween={8}
        autoHeight
        slidesPerView={1}
        breakpoints={{
          [BREAKPOINTS.lg]: {
            spaceBetween: 40
          },
          [BREAKPOINTS.smd]: {
            spaceBetween: 16
          }
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
      >
        {images.map((item, index) => (
          <StyledSlide key={index}>
            <StyledImg src={item} alt={''} />
          </StyledSlide>
        ))}

        <NavPrevEl src={slider_arrow} ref={navigationPrevRef} />
        <NavNextEl src={slider_arrow} ref={navigationNextRef} />
      </StyledSwiper>
    </WrapperSwiper>
  );
};

const WrapperSwiper = styled.div`
  width: 100%;
  max-width: 1200px;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    max-width: 950px;
  }
`;

const StyledSwiper = styled(Swiper)`
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 606px;
    max-width: 1200px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    overflow: visible;
    .swiper-slide {
      height: auto;
    }
  }
`;

const StyledSlide = styled(SwiperSlide)``;

const StyledImg = styled.img`
  object-fit: cover;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 100%;
    height: 400px;
  }
  /* @media (max-width: ${BREAKPOINTS.md}px) {
    height: 280px;
  } */
  @media (max-width: ${BREAKPOINTS.smd}px) {
    height: 230px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 188px;
  }
`;

const NavPrevEl = styled.img`
  width: 64px;
  height: 64px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: rotate(180deg) translateY(50%);
  z-index: 1;
  cursor: pointer;
  :active {
    opacity: 0.6;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    left: -20px;
    width: 48px;
    height: 48px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 40px;
    height: 40px;
    left: -12px;
  }
`;

const NavNextEl = styled(NavPrevEl)`
  top: calc(50% - 28px);
  left: unset;
  right: 0;
  transform: unset;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    left: unset;
    right: -20px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    top: calc(50% - 18px);
    right: -12px;
    left: unset;
  }
`;
