import React from 'react';
import styled from 'styled-components';
import { Container, MainContainer, Row, Col, Img } from 'ui/layout';
import { fontMagra, fontMontserrat, H1 } from 'ui/typography';
import bg from 'assets/ourUnique/bg.png';
import flagIcon from 'assets/ourUnique/flag.svg';
import bgFigure from 'assets/ourUnique/bg-figure.svg';
import { BREAKPOINTS, COLORS } from 'utils';

export const OurUnique = (props) => {
  return (
    <Wrapper {...props}>
      <MainContainer>
        <Row
          gap={7}
          spaceBetween
          relative
          paddingRight={22}
          mdPaddingRight={'0'}
        >
          <Col width={'31.4%'} lgWidth={'100%'} smMarginLeft={-2}>
            <StyledH1>
              Нас <br />
              отличает
            </StyledH1>
            <FlagImg src={flagIcon} alt='' />
          </Col>

          <PointsWrapper
            width={'68.6%'}
            marginTop={6}
            lgMarginTop={85}
            smMarginTop={35}
            lgMarginX={-60}
            smMarginX={-10}
          >
            <Row gap={13} colsMB={35} lgColsMB={18} smColsMB={14}>
              {POINTS.map((item, key) => (
                <Point
                  title={item.title}
                  index={key}
                  lastChild={POINTS.length === key + 1}
                  key={key}
                >
                  {item.descr}
                </Point>
              ))}
            </Row>
          </PointsWrapper>
        </Row>
      </MainContainer>
    </Wrapper>
  );
};

const POINTS = [
  {
    title: 'Современная техника',
    descr:
      'Наличие собственного парка современных технических средств - свыше 200 единиц строительной техники, полностью покрывающей нужды компании.'
  },
  {
    title: 'Гарантии',
    descr:
      'Высокое качество работ и безусловное выполнение договорных обязательств.'
  },
  {
    title: 'Проектный цикл',
    descr:
      'Все виды работ (работаем по системе «каскадная модель»): от широкого спектра необходимых исследований в собственных лабораториях, технико-экономического обоснования, проектирования и расчёта, до проведения строительных работ «под ключ» любого масштаба и сложности, в любых климатических условиях, а главное - точно в срок, определенный заказчиком.'
  },
  {
    title: 'Инновации',
    descr:
      'Постоянное совершенствование процессов и внедрение инноваций позволяет:\n\n' +
      'Снижать затраты на разработку, проектирование и выполнение работ.\n\n' +
      'Контролировать качество и сроки выполнения заказов.\n\n' +
      'Выполнять большое количество проектов одновременно, в рамках запланированных сроков и с неизменным качеством.'
  }
];
const pointConfig = {
  1: {
    bg: '#13CEA3',
    titlePadding: 19,
    descrPadding: 73,
    borderLeft: 54
  },
  2: {
    bg: '#0C67B1',
    titlePadding: 62,
    descrPadding: 70,
    descrMaxWidth: 370,
    borderLeft: 41
  },
  3: {
    bg: '#67CCCD',
    titlePadding: 19,
    descrPadding: 73,
    borderLeft: 54
  },
  4: {
    bg: '#D9D889',
    titlePadding: 38,
    descrPadding: 70,
    descrMaxWidth: 370,
    borderLeft: 41
  }
};
const pointConfigTablet = {
  1: {
    order: 1,
    descrPaddingBottom: 9
  },
  2: {
    order: 3,
    descrPaddingBottom: 14
  },
  3: {
    order: 2
  },
  4: {
    order: 4
  }
};
const pointConfigMobile = {
  1: {},
  2: {},
  3: { descrPaddingBottom: 12 },
  4: {}
};

const Point = ({ title = '', index, lastChild, children }) => {
  return (
    <PointCol
      width={'50%'}
      lgWidth={'100%'}
      lgOrder={pointConfigTablet[index + 1]?.order || ''}
    >
      <SkewedWrapper>
        <TitleCol
          wide={true}
          bg={pointConfig[index + 1]?.bg}
          paddingRight={pointConfig[index + 1]?.titlePadding}
        >
          <Title>{title}</Title>
        </TitleCol>
      </SkewedWrapper>
      <Description
        marginTop={14}
        paddingLeft={pointConfig[index + 1]?.descrPadding || 70}
        maxWidth={pointConfig[index + 1]?.descrMaxWidth}
        borderLeft={pointConfig[index + 1]?.borderLeft}
        lgPaddingBottom={pointConfigTablet[index + 1]?.descrPaddingBottom || ''}
        smPaddingBottom={pointConfigMobile[index + 1]?.descrPaddingBottom || ''}
        lastChild={lastChild}
      >
        {children}
      </Description>
    </PointCol>
  );
};

const Wrapper = styled(Container)`
  position: relative;
  min-height: 1461px;
  background: url('${bg}') bottom right/1600px 1461px no-repeat;
  padding-top: 277px;
  padding-bottom: 100px;

  :after {
    content: '';
    position: absolute;
    left: 0;
    top: 176px;
    width: 180px;
    height: 606px;
    background: url('${bgFigure}') left top/100% 100% no-repeat;
    z-index: -1;
  }
  @media (max-width: 1500px) {
    :after {
      left: -50px;
    }
  }
  @media (max-width: ${BREAKPOINTS.xl}px) {
    background-size: 1420px 1300px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    background-size: 1120px 1100px;
    padding-top: 77px;
    min-height: 1152px;
    :after {
      width: 139px;
      height: 156px;
      top: 28px;
      left: -44px;
    }
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    background-size: 898px 905px;
    :after {
      width: 139px;
      height: 156px;
      top: 28px;
      left: -44px;
    }
  }
  @media (max-width: ${BREAKPOINTS.md - 120}px) {
    :after {
      left: -64px;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    background-size: 800px 600px;
    :after {
      left: -44px;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd - 100}px) {
    :after {
      left: -64px;
    }
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding-top: 26px;
    min-height: 729px;
    background-size: 100% 344px;
    :after {
      width: 154px;
      height: 82px;
      top: 1px;
      left: -72px;
    }
  }
`;
const PointsWrapper = styled(Col)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    max-width: unset;
    width: unset;
  }
`;

const StyledH1 = styled(H1)`
  ${fontMagra}
`;

const PointCol = styled(Col)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    ${(p) => (p.lgOrder ? `z-index: ${p.lgOrder};` : '')}
  }
  @media (max-width: ${BREAKPOINTS.md - 120}px) {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-left: 0px;
    margin-right: 0px;
  }
  @media (max-width: ${BREAKPOINTS.smd - 100}px) {
    margin-left: 40px;
    margin-right: 40px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;
const SkewedWrapper = styled(Row)`
  transform: skewX(-30deg);
  height: 38px;
  padding: 0 11px 0 10px;
  overflow: hidden;
  z-index: 1;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 16px;
    padding: 0 6px 0 4px;
  }
`;
const TitleCol = styled(Col)`
  background: ${(p) => (p.bg ? p.bg : '#13CEA3')};
  padding: ${(p) =>
    p.paddingRight ? `3px ${p.paddingRight}px 5px 62px` : '3px 20px 5px 62px'};
  margin-right: 7px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding: ${(p) =>
      p.paddingRight
        ? `3px ${p.paddingRight + 13}px 5px 49px`
        : '3px 20px 5px 62px'};
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    padding: 1px 8px 0px 21px;
    min-width: 174px;
  }
`;
const RestCol = styled(Col)`
  background: #eeeeee;
`;
const Title = styled.p`
  ${fontMagra}
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${COLORS.white};
  transform: skewX(30deg);
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 12px;
    line-height: 14px;
  }
`;
const Description = styled(Container)`
  ${fontMontserrat}
  white-space: pre-line;
  position: relative;
  :before {
    content: '\\25CF';
    color: #000000;
    font-size: 12px;
    position: absolute;
    top: -45px;
    left: ${(p) => (p.borderLeft ? `${p.borderLeft - 3}px` : '38px')};
  }
  :after {
    content: '';
    position: absolute;
    top: -28px;
    left: ${(p) => (p.borderLeft ? `${p.borderLeft}px` : '41px')};
    height: calc(100% + 29px);
    border-right: 1px dashed #000000;
  }

  @media (max-width: ${BREAKPOINTS.xl}px) {
    padding-left: 59px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding-right: 61px;
    padding-left: 59px;
    max-width: unset;
    :before {
      left: 39px;
    }
    :after {
      left: 42px;
      ${(p) => (p.lastChild ? '' : 'height: calc(100% + 56px);')}
    }
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    margin-top: 7px;
    padding-right: 0;
    padding-left: 25px;
    max-width: unset;
    line-height: 14px;
    :before {
      top: -23px;
      left: 15px;
    }
    :after {
      left: 18px;
      top: -14px;
      ${(p) => (p.lastChild ? '' : 'height: calc(100% + 56px);')}
    }
  }
`;
const FlagImg = styled(Img)`
  margin-top: 207px;
  width: 180px;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    position: absolute;
    top: -16px;
    right: -54px;
    width: 122px;
    margin: 0;
  }
  @media (max-width: ${BREAKPOINTS.md - 120}px) {
    right: -14px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    right: -54px;
  }
  @media (max-width: ${BREAKPOINTS.smd - 100}px) {
    right: -14px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    top: 6px;
    right: 7px;
    width: 35px;
  }
`;
