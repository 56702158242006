import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../../utils';

export const SkewedLine = ({ bg = '#EDECA5', top = 0, left = 0, lg, sm }) => {
  return <Line bg={bg} top={top} left={left} lg={lg} sm={sm} />;
};

const Line = styled.div`
  position: absolute;
  left: ${(p) => p.left}px;
  top: ${(p) => p.top}px;
  height: 92px;
  width: 200px;
  transform: skewX(-30deg);
  background: ${(p) => p.bg};
  @media (max-width: ${BREAKPOINTS.lg}px) {
    height: 53px;
    ${(p) =>
      p.lg
        ? `
         ${p.lg.left ? `left: ${p.lg.left}px;` : ''}
         ${p.lg.top ? `top: ${p.lg.top}px;` : ''}
      `
        : ''}
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    height: 26px;
    ${(p) =>
      p.sm
        ? `
         ${p.sm.left ? `left: ${p.sm.left}px;` : ''}
         ${p.sm.top ? `top: ${p.sm.top}px;` : ''}
      `
        : ''}
  }
`;
