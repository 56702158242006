import React from 'react';
import { NewsPreview } from 'components/common';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../../../utils';

export const VideoBlock = ({ img, subtitle }) => {
  return (
    <Wrapper>
      <NewsPreview
        data={{
          video: true,
          img: img
        }}
        wide
        lgLarge
      />

      <Subtitle>{subtitle}</Subtitle>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 -13px 0 -10px;
  padding: 10px 12px 38px 9px;
  border: 1px solid #d9d9d9;
  background: #ffffff;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin: 0 -13px;
    padding: 10px 12px 19px 9px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin: 0 -7px;
    padding: 6px 6px 7px 6px;
  }
`;
const Subtitle = styled.p`
  margin-top: 11px;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 5px;
  }
`;
