import React from 'react';
import { ProjectInfo, ProjectsCommon, ProjectAsideInfo } from '../components';
import { Col, Container, Row } from '../../../ui/layout';
import styled from 'styled-components';
import { ill1, ill2, ill3, ill4, ill5, project } from 'assets/projects/IFNS';
import { VideoBlock } from '../components/VideoBlock';
import { BREAKPOINTS } from '../../../utils';

export const IFNSProject = () => {
  return (
    <ProjectsCommon>
      <Row gap={14}>
        <Col width={'66.66%'} lgWidth={'100%'}>
          <ProjectInfo
            ill={project}
            title={'ИФНС № 7'}
            location={'Нефтеюганск'}
          >
            Здание ИФНС предполагается разместить на земельном участке с
            кадастровым номером 86:20:0000000:11356 (cм. ГПЗУ №RU86304000-2143),
            расположенном в 17-м микрорайоне г. Нефтеюганска Ханты-Мансийского
            автономного округа Тюменской области. С севера от участка
            строительства расположены строящиеся 16-этажные жилые дома, строение
            2 и строение 3, по ул. Романа Кузоваткина, с западной части
            размещена частная малоэтажная жилая застройка 17-го микрорайона, с
            юго-востока земельный участок граничит с ул. Мамонтовская, также с
            юго-восточной стороны проходит автодорога федерального значения
            Тюмень – Ханты-Мансийск.
          </ProjectInfo>
        </Col>
        <Col width={'33.33%'} lgWidth={'100%'}>
          <AsideContainer>
            <AsideIllRow gap={31} lgGap={14} cols={1} lgCols={2} smdCols={1}>
              <Col>
                <ProjectAsideInfo
                  info={[
                    {
                      title: 'Площадь земельного участка',
                      value: '16 179 кв. м'
                    },
                    {
                      title: 'Площадь застройки',
                      value: '1 089 кв. м'
                    },
                    {
                      title: ' Общая площадь здания',
                      value: '4 328 кв. м'
                    },
                    {
                      title: 'Количество надземных этажей',
                      value: '4'
                    }
                  ]}
                  minHeight={353}
                />
              </Col>
              <Ill1Wrapper>
                <Img src={ill1} alt={''} />
              </Ill1Wrapper>
            </AsideIllRow>
          </AsideContainer>
        </Col>
      </Row>

      <Row gap={29} marginTop={6} lgMarginTop={31} smdMarginTop={19}>
        <Col>
          <Row direction={'row'}>
            <Col width={'66.66%'} lgWidth={'100%'}>
              <Ill2Wrapper>
                <Img src={ill2} alt={''} />
              </Ill2Wrapper>
              <Ill3Wrapper>
                <Img src={ill3} alt={''} />
              </Ill3Wrapper>
            </Col>
            <Col width={'33.33%'} lgWidth={'100%'} lgHidden>
              <Ill4Wrapper>
                <Img src={ill4} alt={''} />
              </Ill4Wrapper>
              <Ill5Wrapper>
                <Img src={ill5} alt={''} />
              </Ill5Wrapper>
            </Col>
          </Row>
          <MobileIllRow
            gap={32}
            smdGap={20}
            cols={2}
            marginTop={38}
            smdMarginTop={24}
          >
            <Ill4Wrapper>
              <Img src={ill4} alt={''} />
            </Ill4Wrapper>
            <Ill5Wrapper>
              <Img src={ill5} alt={''} />
            </Ill5Wrapper>
          </MobileIllRow>

          <Container marginTop={60} lgMarginTop={41} smdMarginTop={45}>
            Межрайонная ИФНС является территориальным подразделением Федеральной
            налоговой службы, осуществляющим функции по контролю и надзору за
            соблюдением законодательства о налогах и сборах, за правильностью
            исчисления, полнотой и своевременностью внесения в соответствующий
            бюджет налогов и сборов, в случаях, предусмотренных
            законодательством Российской Федерации, за правильностью исчисления,
            полнотой и своевременностью внесения в соответствующий бюджет иных
            обязательных платежей, а также за производством и оборотом этилового
            спирта, спиртосодержащей, алкогольной и табачной продукции и за
            соблюдением валютного законодательства Российской Федерации в
            пределах компетенции налоговых органов.
            <br />
            <br />
            Инспекция осуществляет государственную регистрацию юридических лиц,
            физических лиц в качестве индивидуальных предпринимателей,
            крестьянских (фермерских) хозяйств, представляет в делах о
            банкротстве и в процедурах банкротства требования об уплате
            обязательных платежей и требования Российской Федерации по денежным
            обязательствам.
          </Container>

          <VideoWrapper>
            <VideoBlock
              img={project}
              subtitle={'Видеопрезентация проекта «ИФНС № 7 »'}
            />
          </VideoWrapper>
        </Col>
      </Row>
    </ProjectsCommon>
  );
};

const AsideContainer = styled.div`
  position: relative;
  margin-right: -14px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin: 0;
    :after {
      content: '';
      position: absolute;
      left: -13px;
      bottom: 0;
      width: calc(100% + 26px);
      height: 1px;
      background: #d9d9d9;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    :after {
      left: -7px;
      width: calc(100% + 14px);
    }
  }
`;
const AsideIllRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 44px;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding-bottom: 102px;
    position: relative;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    flex-direction: column-reverse;
    gap: 35px;
    margin-top: 27px;
    padding-bottom: 32px;
  }
`;
const Img = styled.img`
  width: 100%;
  display: block;
  object-fit: cover;
`;
const Ill1Wrapper = styled(Col)`
  margin-top: 52px;
  > img {
    max-width: 359px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: auto;
    flex-grow: unset;
    margin-top: -9px;
    > img {
      max-width: 256px;
      margin: 0;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    > img {
      max-width: unset;
    }
  }
`;
const Ill2Wrapper = styled.div`
  margin-top: 25px;
  max-width: 1193px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 3px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 14px;
  }
`;
const Ill3Wrapper = styled.div`
  margin-top: 39px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 29px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 34px;
  }
`;
const Ill4Wrapper = styled.div`
  margin-top: -8px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 0;
  }
`;
const Ill5Wrapper = styled.div`
  margin-top: 18px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 0;
  }
`;
const VideoWrapper = styled.div`
  margin-top: 60px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 43px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 20px;
  }
`;
const MobileIllRow = styled(Row)`
  display: none;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: flex;
  }
`;
