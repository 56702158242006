import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import {
  Col,
  Container,
  Img,
  MainContainer,
  PageContainer,
  Row
} from 'ui/layout';
import { fontMagra, Text } from 'ui/typography';
import { BREAKPOINTS } from 'utils';
import tabImg from 'assets/techTabs/tab.png';
import tabActiveImg from 'assets/techTabs/tab_active.png';
import tab_tablet from 'assets/techTabs/tab_tablet.png';
import tab_active_tablet from 'assets/techTabs/tab_active_tablet.png';
import icon1 from 'assets/techTabs/icon1.svg';
import icon2 from 'assets/techTabs/icon2.svg';
import icon3 from 'assets/techTabs/icon3.svg';
import icon4 from 'assets/techTabs/icon4.svg';
import icon5 from 'assets/techTabs/icon5.svg';
import bg1 from 'assets/techTabs/bg1.png';
import bg2 from 'assets/techTabs/bg2.png';
import bg3 from 'assets/techTabs/bg3.png';
import bg4 from 'assets/techTabs/bg4.png';
import bg5 from 'assets/techTabs/bg5.png';
import bg3_tablet from 'assets/techTabs/bg3_tablet.png';
import bg4_tablet from 'assets/techTabs/bg4_tablet.png';
import bg5_tablet from 'assets/techTabs/bg5_tablet.png';
import figure_yellow from 'assets/techTabs/figure_yellow.svg';
import figure_white from 'assets/techTabs/figure_white.svg';
import arrow_left from 'assets/techTabs/arrow_left.svg';

const headers = [
  {
    title: 'Комплекс ИТС'
  },
  {
    title: 'Производство'
  },
  {
    title: 'Система контроля \nкачества'
  },
  {
    title: 'Стандартизация'
  },
  {
    title: 'Лаборатории'
  }
];

export const Tabs = () => {
  const [activeTab, setActiveTab] = useState(0);

  const nextTab = () => {
    if (activeTab === 4) return;
    setActiveTab(activeTab + 1);
  };

  const prevTab = () => {
    if (activeTab === 0) return;
    setActiveTab(activeTab - 1);
  };

  if (headers) {
    return (
      <Container>
        <MainContainer>
          <Header>
            <Row nowrap>
              {headers.map((item, key) => (
                <TabCol
                  width={`${100 / headers.length}%`}
                  index={
                    activeTab === key
                      ? headers.length + 1
                      : headers.length - key
                  }
                  active={activeTab === key}
                  activeTab={activeTab}
                  key={key}
                >
                  <Tab
                    active={activeTab === key}
                    index={key}
                    onClick={() => setActiveTab(key)}
                  >
                    <Row
                      gap={7}
                      alignCenter
                      justifyContent={'center'}
                      nowrap
                      height={'100%'}
                    >
                      <Title>{item.title}</Title>
                    </Row>
                  </Tab>
                </TabCol>
              ))}
            </Row>
          </Header>
        </MainContainer>

        <Slide1 nextTab={nextTab} prevTab={prevTab} visible={activeTab === 0} />
        <Slide2 nextTab={nextTab} prevTab={prevTab} visible={activeTab === 1} />
        <Slide3 nextTab={nextTab} prevTab={prevTab} visible={activeTab === 2} />
        <Slide4 nextTab={nextTab} prevTab={prevTab} visible={activeTab === 3} />
        <Slide5 nextTab={nextTab} prevTab={prevTab} visible={activeTab === 4} />
      </Container>
    );
  }

  return null;
};

const Slide1 = ({ visible = false, nextTab, prevTab }) => (
  <SlideWrapper visible={visible}>
    <PageContainer paddingY={65} paddingLeft={74} paddingRight={80} relative>
      <Container relative>
        <Bg1Tablet src={bg1} />
        <NavNext onClick={nextTab} />
        <NavPrev onClick={prevTab} />
      </Container>
      <Slide1Row
        gap={9}
        lgGap={19}
        smdGap={8}
        nowrap
        lgMarginTop={120}
        smdMarginTop={80}
      >
        <Col auto lgMarginLeft={-24}>
          <Icon1 src={icon1} width={82} alt={''} />
        </Col>
        <Col paddingTop={32} lgPaddingTop={9} smdPaddingTop={5} auto>
          <p>
            Автоматизация процесса управления
            <br /> и содержанием автодорог.
          </p>
        </Col>
      </Slide1Row>
      <Bg1 src={bg1} />

      <FigureYellow1 src={figure_yellow} alt={''} />
      <FigureWhite1 src={figure_white} alt={''} />
    </PageContainer>
  </SlideWrapper>
);
const Slide2 = ({ visible = false, nextTab, prevTab }) => (
  <SlideWrapper visible={visible}>
    <PageContainer
      paddingTop={71}
      paddingBottom={60}
      paddingLeft={142}
      paddingRight={75}
      relative
    >
      <Slide2Row gap={62}>
        <Col
          width={'35.5%'}
          lgWidth={'100%'}
          paddingBottom={23}
          alignSelf={'flex-end'}
          zIndex={1}
          lgMarginTop={85}
          smdMarginTop={52}
        >
          <Container lgPaddingLeft={6}>
            <p>Производство смесей с использованием асфальтогранулята:</p>
            <Text marginTop={20}>• вторичное использование материала;</Text>
            <Text marginTop={20}>
              • уменьшение дефицита минеральных и вяжущих материалов;
            </Text>
            <Text marginTop={20}>
              • снижение негативного влияния на окружающую среду.
            </Text>
          </Container>

          <Icon2 src={icon2} width={'98'} alt={''} />
        </Col>
        <Col width={'64.5%'} lgWidth={'100%'}>
          <Container relative>
            <Bg2 src={bg2} alt={''} />
            <NavNext onClick={nextTab} />
            <NavPrev onClick={prevTab} />
          </Container>
        </Col>
      </Slide2Row>

      <FigureYellow2 src={figure_yellow} alt={''} />
      <FigureWhite2 src={figure_white} alt={''} />
    </PageContainer>
  </SlideWrapper>
);
const Slide3 = ({ visible = false, nextTab, prevTab }) => (
  <SlideWrapper visible={visible}>
    <PageContainer
      paddingTop={60}
      paddingBottom={50}
      paddingLeft={76}
      paddingRight={54}
      relative
    >
      <Slide3Row gap={50}>
        <Col
          width={'51.3%'}
          lgWidth={'100%'}
          zIndex={1}
          lgMarginTop={22}
          lgMarginLeft={6}
          smdMarginTop={12}
        >
          <TextRow3 gap={13} lgGap={12} smdGap={6} alignCenter>
            <Col auto>
              <Icon3 src={icon3} width={65} alt={''} />
            </Col>
            <Col auto lgMarginRight={31}>
              <p>
                Система контроля качества
                <br /> дорожно-строительных работ
              </p>
            </Col>
          </TextRow3>

          <Container marginTop={21} lgMarginTop={17} smdMarginTop={10}>
            <p>
              Внутрикорпоративная система управления качеством при выполнении
              СМР обеспечивается комплексом организационно-технических
              мероприятий в соответствии с СМК:
            </p>
            <Text marginTop={8}>
              • планирование мероприятия по обеспечению качества для конкретного
              проекта;
            </Text>
            <Text marginTop={8}>• контроль проектной документации;</Text>
            <Text marginTop={8}>
              • входной контроль закупаемых строительных материалов и изделий;
            </Text>
            <Text marginTop={8}>
              • операционный контроль при выполнении строительных работ;
            </Text>
            <Text marginTop={8}>
              • освидетельствование и испытание построенных конструктивных
              элементов и ответственных конструкций;
            </Text>
            <Text marginTop={8}>
              • постоянный геодезический контроль пространственного положения
              конструкций;
            </Text>
            <Text marginTop={8}>• контроль выполнения субподрядных работ.</Text>
          </Container>
          <Container marginTop={34} lgMarginTop={24} smdMarginTop={14}>
            <p>
              Для осуществления входного контроля приобретаемых
              дорожно-строительных материалов, конструкций и изделий,
              операционного и приёмочного контроля качества выполняемых
              дорожно-строительных работ подразделения оснащены
              дорожно-строительными лабораториями.
            </p>
          </Container>
        </Col>
        <Col width={'48.7%'} lgWidth={'100%'}>
          <Container relative>
            <Bg3 src={bg3} alt={''} />
            <NavNext onClick={nextTab} />
            <NavPrev onClick={prevTab} />
          </Container>
        </Col>
      </Slide3Row>

      <FigureYellow3 src={figure_yellow} alt={''} />
      <FigureWhite3 src={figure_white} alt={''} />
    </PageContainer>
  </SlideWrapper>
);
const Slide4 = ({ visible = false, nextTab, prevTab }) => (
  <SlideWrapper visible={visible}>
    <PageContainer paddingY={59} paddingX={69} relative>
      <Row gap={36}>
        <Col width={'46%'} lgWidth={'100%'}>
          <Container relative>
            <Bg4 src={bg4} alt={''} />
            <NavNext onClick={nextTab} />
            <NavPrev onClick={prevTab} />
          </Container>{' '}
        </Col>
        <Col
          width={'39.1%'}
          lgWidth={'100%'}
          paddingTop={115}
          lgPaddingTop={44}
          lgMarginRight={-2}
          zIndex={1}
        >
          <Container>
            <p>
              Работа с соблюдением национального (государственного) стандарта,
              принятого органом по стандартизации государства - члена
              Евразийского экономического союза при укладке асфальтобетонных
              покрытий.
            </p>
          </Container>

          <Container marginTop={37} lgMarginTop={61} smdMarginTop={22}>
            <p>
              Технология устройства верхнего слоя покрытия из дренирующего
              асфальтобетона:
            </p>
            <ListPoint>
              <p>
                • позволяет существенно повысить безопасность движения за счет
                полного отвода воды с покрытия и увеличения сцепления колеса с
                покрытием;
              </p>
            </ListPoint>
            <ListPoint>
              <p>
                • повышает долговечность покрытий за счет устойчивости материала
                колееобразованию;
              </p>
            </ListPoint>
            <ListPoint>
              <p>• значительно уменьшает уровень шума от колес;</p>
            </ListPoint>
            <ListPoint>
              <p>
                • исключает появление водных аэрозолей при выпадении осадков.
              </p>
            </ListPoint>
          </Container>
          <Icon4 src={icon4} alt={''} />
        </Col>
      </Row>

      <FigureYellow4 src={figure_yellow} alt={''} />
      <FigureWhite4 src={figure_white} alt={''} />
    </PageContainer>
  </SlideWrapper>
);
const Slide5 = ({ visible = false, nextTab, prevTab }) => (
  <SlideWrapper visible={visible}>
    <PageContainer paddingTop={64} paddingBottom={10} paddingX={73} relative>
      <Row gap={34}>
        <Col width={'47.2%'} lgWidth={'100%'}>
          <Container relative>
            <Bg5 src={bg5} alt={''} />
            <NavNext onClick={nextTab} />
            <NavPrev onClick={prevTab} />
          </Container>{' '}
        </Col>
        <Col
          width={'48.9%'}
          lgWidth={'100%'}
          paddingTop={32}
          lgPaddingTop={43}
          smdPaddingTop={40}
          zIndex={1}
        >
          <Container maxWidth={609}>
            <p>
              Лаборатории укомплектованы квалифицированными и компетентными
              специалистами, современными отечественными н зарубежными
              автоматическими и полуавтоматическими лабораторными приборами и
              оборудованием для определения физико-механических свойств
              дорожно-строительных материалов, применяемых при строительстве,
              реконструкции и ремонте автомобильных дорог, в том числе грунтов и
              каменных материалов, органических вяжущих, асфальтобетонных смесей
              различных типов и марок, цемента, бетонных смесей и бетона.
            </p>
          </Container>
          <Container
            marginTop={60}
            lgMarginTop={34}
            smdMarginTop={22}
            maxWidth={609}
          >
            <p>
              Для качественной достоверной оценки результатов полевых и
              лабораторных испытаний все лаборатории оснащены
              специализированными компьютерными программами по обработке
              результатов испытаний с осуществлением автоматизнрованных расчётов
              физико-механических свойств материалов.
              <br />
              <br />
              Применение современного лабораторного оборудования
              «Асфальтоанализатор», автоматических приборов контроля качества
              битума, асфальтобетона, неразрушающих методов контроля качества
              асфальтобетонных покрытий и земляного полотна.
            </p>
          </Container>
          <Icon5 src={icon5} width={75} alt={''} />
        </Col>
      </Row>

      <FigureYellow5 src={figure_yellow} alt={''} />
      <FigureWhite5 src={figure_white} alt={''} />
    </PageContainer>
  </SlideWrapper>
);

const ListPoint = styled.div`
  margin-top: 8px;
`;
const Header = styled(Container)`
  margin-left: -60px;
  margin-right: -19px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-left: -46px;
    margin-right: -51px;
    position: relative;
    :after {
      content: '';
      position: absolute;
      left: -16px;
      bottom: -1px;
      width: calc(100% + 16px);
      height: 1px;
      background: #000000;
      z-index: 10;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-left: -15px;
    margin-right: -20px;
  }
`;
const Title = styled.p`
  ${fontMagra}
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  white-space: pre-line;
  text-align: center;
  margin-top: -6px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 18px;
    line-height: 25px;
    white-space: nowrap;
    margin: 0;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 12px;
    line-height: 11px;
    white-space: unset;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 10px;
  }
`;
const TabCol = styled(Col)`
  ${(p) => `
  z-index: ${p.index ? p.index : '0'};
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-left: ${p.activeTab === 2 ? '-19px' : '-16px'};
    width: 100%;
    max-width: ${p.active ? '100%' : '127px'};
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-left: ${p.activeTab === 2 ? '-10px' : '-9px'};
    max-width: ${p.active ? '100%' : '68px'};
  }
`}
`;
const Tab = styled(Container)`
  height: 91px;
  margin-right: -46px;
  cursor: pointer;
  ${(p) =>
    p.active
      ? `
    background: url("${tabActiveImg}") center/100% 100% no-repeat;
    pointer-events: none;
    ${Title} {color: #29235C;}

    @media (max-width: ${BREAKPOINTS.lg}px) {
      margin-right: -6px;
      background-image: url("${tab_active_tablet}");
      ${p.index === 2 ? 'margin-left: -17px; margin-right: -17px;' : ''}
    }
  `
      : `
    background: url("${tabImg}") center/100% 100% no-repeat;
    ${Title} {
     color: #989898;
    }
    @media (max-width: ${BREAKPOINTS.lg}px) {
      background-image: url("${tab_tablet}");
      margin: 0;
      ${Title} {
       display: none;
      }
    }
  `}

  @media (max-width: ${BREAKPOINTS.lg}px) {
    height: 49px;
    padding: 0 15px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    height: 26px;
    margin: 0;
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    ${(p) =>
      p.active
        ? `
     margin-right: -10px;
     margin-left: -10px;
    `
        : ''}
  }
`;

const NavPrev = styled.div`
  display: none;
  content: '';
  z-index: 200;
  position: absolute;
  left: -35px;
  top: calc(50% - 36px);
  width: 13px;
  height: 36px;
  background: url('${arrow_left}') center/contain no-repeat;
  cursor: pointer;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: block;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 6px;
    left: 0;
  }
`;
const NavNext = styled(NavPrev)`
  left: unset;
  right: -35px;
  background: url('${arrow_left}') center/contain no-repeat;
  transform: rotate(180deg);
  @media (max-width: ${BREAKPOINTS.sm}px) {
    left: unset;
    right: 0;
  }
`;

const SlideWrapper = styled(Container)`
  background: #edeca5;
  min-height: 698px;
  overflow: hidden;
  ${(p) => (p.visible ? 'display: block;' : 'display: none;')}
  ${PageContainer} {
    min-height: 698px;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    min-height: unset;
    ${PageContainer} {
      min-height: unset;
      max-width: 621px;
      padding: 68px 50px 40px;
    }
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    ${PageContainer} {
      max-width: 369px;
      padding: 29px 20px 20px 20px;
    }
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    ${PageContainer} {
      /* :after {
        left: 6px;
      }
      :before {
        right: 6px;
      } */
    }
  }
`;
const Slide1Row = styled(Row)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    justify-content: center;
  }
`;
const Slide2Row = styled(Row)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    flex-direction: column-reverse;
  }
`;
const Slide3Row = styled(Slide2Row)``;
const TextRow3 = styled(Row)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    justify-content: flex-end;
  }
`;
const Bg = styled(Img)`
  width: 100%;
  object-fit: cover;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    height: 332px !important;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 250px;
    height: 143px !important;
    margin-left: auto;
    margin-right: auto;
    transform: translateX(10px);
  }
  @media (max-width: ${BREAKPOINTS.xs}px) {
    transform: unset;
  }
`;
const Bg1 = styled(Bg)`
  margin-top: 25px;
  height: 442px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`;
const Bg2 = styled(Bg)`
  height: 567px;
`;
const Bg3 = styled(Bg)`
  height: 564px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    content: url('${bg3_tablet}');
  }
`;
const Bg4 = styled(Bg)`
  height: 580px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    content: url('${bg4_tablet}');
  }
`;
const Bg5 = styled(Bg)`
  height: 570px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    content: url('${bg5_tablet}');
  }
`;
const Bg1Tablet = styled(Bg)`
  display: none;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: block;
  }
`;
const Icon1 = styled(Img)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 50px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 40px;
  }
`;
const Icon2 = styled(Img)`
  margin: 78px auto 0 auto;
  transform: translateX(-32px);
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 69px;
    margin-top: 15px;
    transform: translateX(-4px);
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 46px;
  }
`;
const Icon3 = styled(Img)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 45px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 32px;
  }
`;
const Icon4 = styled(Img)`
  margin-top: 62px;
  margin-left: auto;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 47px;
    margin-top: 15px;
    margin-right: auto;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 34px;
    margin-top: 20px;
  }
`;
const Icon5 = styled(Img)`
  margin: 52px auto 0 auto;
  transform: translateX(-15px);
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 40px;
    margin-top: 15px;
    transform: translateX(-4px);
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 28px;
    margin-top: 15px;
  }
`;
const Figure = styled(Img)`
  position: absolute;
`;
const FigureYellow = styled(Figure)`
  width: 428px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 230px;
    top: 0 !important;
    right: -226px !important;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 101px;
    right: -59px !important;
  }
`;
const FigureWhite = styled(Figure)`
  width: 379px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 204px;
    top: 288px !important;
    left: -4.3% !important;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    width: 88px;
    top: 137px !important;
    left: 13.7% !important;
  }
`;
const FigureYellow1 = styled(FigureYellow)`
  top: 0;
  right: -200px;
`;
const FigureWhite1 = styled(FigureWhite)`
  bottom: 0;
  left: -165px;
`;
const FigureYellow2 = styled(FigureYellow)`
  bottom: -100px;
  right: -200px;
`;
const FigureWhite2 = styled(FigureWhite)`
  top: -144px;
  left: 124px;
  transform: rotate(90deg);
  @media (max-width: ${BREAKPOINTS.lg}px) {
    transform: unset;
  }
`;
const FigureYellow3 = styled(FigureYellow)`
  top: 175px;
  right: -200px;
`;
const FigureWhite3 = styled(FigureWhite)`
  bottom: -136px;
  left: -190px;
`;
const FigureYellow4 = styled(FigureYellow)`
  top: 175px;
  right: -200px;
`;
const FigureWhite4 = styled(FigureWhite)`
  bottom: -133px;
  left: -190px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    top: 321px !important;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    top: 137px !important;
  }
`;
const FigureYellow5 = styled(FigureYellow)`
  top: 11px;
  right: -283px;
`;
const FigureWhite5 = styled(FigureWhite)`
  bottom: -168px;
  left: 28.4%;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    top: 321px !important;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    top: 137px !important;
  }
`;
