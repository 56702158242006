import React, { useState } from 'react';
import styled from 'styled-components';
import { Menu } from 'components';
import { Container, MainContainer, Row, Img, Col } from 'ui/layout';
import { Link as DefaultLink, InteractLink } from 'ui/typography';
import { BREAKPOINTS } from 'utils';
import logo from 'assets/logo.svg';
import burger from 'assets/common/menu_burger.svg';

export const Header = ({ active = '' }) => {
  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <StyledHeader>
      <HeaderMainContainer height={'100%'}>
        <MainRow alignCenter spaceBetween height={'100%'} nowrap>
          <Link href={'/'}>
            <Logo src={logo} width={184} block alt='' />
          </Link>

          <NavContainer marginLeft={-16} lgHidden>
            <Row gap={15}>
              <div>
                <Link href='/' active={active === ''}>
                  Главная
                </Link>
              </div>

              <div>
                <Link href='/news' active={active === 'news'}>
                  Новости
                </Link>
              </div>

              <div>
                <Link href='/projects' active={active === 'projects'}>
                  Проекты
                </Link>
              </div>

              <div>
                <Link href='/partners' active={active === 'partners'}>
                  Поставщикам
                </Link>
              </div>

              <div>
                <Link href='/contacts' active={active === 'contacts'}>
                  Контакты
                </Link>
              </div>
            </Row>
          </NavContainer>

          <div>
            <Row gap={32} smdGap={15} alignCenter nowrap>
              <div>
                <InteractLink href='tel:84958990564'>
                  +7 495 899 05 64
                </InteractLink>
              </div>
              <Container hidden lgVisible>
                <Burger
                  src={burger}
                  alt={''}
                  width={39}
                  onClick={() => setMenuOpened(true)}
                />
              </Container>
            </Row>
          </div>
        </MainRow>
      </HeaderMainContainer>

      <Menu opened={menuOpened} onClose={() => setMenuOpened(false)} />
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  height: 128px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 18px;
    height: 108px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 16px;
    height: 51px;
  }
`;
const HeaderMainContainer = styled(MainContainer)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    max-width: unset;
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-left: 23px;
    padding-right: 23px;
  }
`;
const MainRow = styled(Row)`
  position: relative;

  :after {
    content: '';
    position: absolute;
    right: 4px;
    bottom: -11px;
    height: 11px;
    width: 170px;
    background: #54ccc9;
    transform: skew(-33deg);
  }

  @media (max-width: ${BREAKPOINTS.lg}px) {
    :after {
      top: -18px;
      bottom: unset;
      right: 97px;
      height: 18px;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    :after {
      top: -16px;
      bottom: unset;
      right: 34px;
      height: 16px;
      width: 80px;
    }
  }
`;
const NavContainer = styled(Container)`
  line-height: 22px;
  padding-right: 43px;
  padding-bottom: 5px;
`;
const Logo = styled(Img)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 86px;
  }
`;
const Burger = styled(Img)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 18px;
  }
`;
const Link = styled(DefaultLink)`
  ${(p) =>
    p.active
      ? `
    position: relative;
    :after, :before {
      content: '';
      position: absolute;
      top: 0;
      left: calc(50% + 2px);
      left: 50%;
      height: 0;
      width: 130%;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      box-sizing: border-box;
    }
    :after {
      transform: translate(-50%, -53px);
      border-top: 40px solid #54CCC9;
    }
    :before {
      transform: translate(-50%, 33px);
      border-bottom: 43px solid #EFEFA7;
    }
  `
      : ''}
`;
