import React from 'react';
import { ProjectInfo, ProjectsCommon, ProjectAsideInfo } from '../components';
import { Col, Container, Row } from '../../../ui/layout';
import styled from 'styled-components';
import { ill1, ill2, ill3, project } from 'assets/projects/schoolBerezovo';
import { VideoBlock } from '../components/VideoBlock';
import { BREAKPOINTS } from '../../../utils';

export const SchoolBerezovo = () => {
  return (
    <ProjectsCommon>
      <Row gap={14}>
        <Col width={'66.66%'} lgWidth={'100%'}>
          <ProjectInfo ill={project} title={'Школа'} location={'пгт Берёзово'}>
            Школа на 700 учащихся запроектирована для обучения с 1 по 11 класс в
            одну смену. Общее количество классов – 31, из них в начальной школе
            – 12 классов (276 чел.), в основной школе – 15 классов (345 чел.), в
            старшей школе – 4 класса (92 чел.). Соотношение количества
            параллелей по ступеням образования составляет 3:3:2. Количество
            обучающихся в одном классе принято с фронтальной формой занятий не
            более 23 школьников.
          </ProjectInfo>
          <Ill1Wrapper>
            <Img src={ill1} alt={''} />
          </Ill1Wrapper>
        </Col>
        <Col width={'33.33%'} lgWidth={'100%'}>
          <AsideContainer>
            <AsideIllRow gap={31} lgGap={14} cols={1} lgCols={2} smdCols={1}>
              <Col>
                <ProjectAsideInfo
                  info={[
                    {
                      title: 'Площадь земельного участка',
                      value: '2,8443 га'
                    },
                    {
                      title: 'Площадь застройки',
                      value: '6 450 кв. м'
                    },
                    {
                      title: 'Общая площадь здания',
                      value: '19 610 кв. м'
                    },
                    {
                      title: 'Количество учащихся',
                      value: '700 чел.'
                    },
                    {
                      title: 'Количество надземных этажей',
                      value: '3'
                    }
                  ]}
                  minHeight={353}
                />
              </Col>
              <Ill2Wrapper>
                <Img src={ill2} alt={''} />
              </Ill2Wrapper>
              <Ill3Wrapper>
                <Img src={ill3} alt={''} />
              </Ill3Wrapper>
            </AsideIllRow>
          </AsideContainer>
        </Col>
      </Row>

      <LastRow gap={30} marginTop={60} lgMarginTop={41} smdMarginTop={19}>
        <Col width={'100%'}>
          <Ill1WrapperMobile>
            <Img src={ill1} alt={''} />
          </Ill1WrapperMobile>

          <Container
            style={{ whiteSpace: 'break-spaces' }}
            lgMarginTop={48}
            smdMarginTop={53}
          >
            Учебные помещения начальной школы (1–4 классы) располагаются в
            восточной и западной части здания школы на 1 и 2 этажах. Ученики
            начальной общеобразовательной школы обучаются в закрепленных за
            каждым классом универсальных учебных помещениях, сгруппированных в
            учебные секции. Также в учебном блоке начальных классов
            запроектированы две универсальные спальни-игровые и две игровые
            комнаты для групп продленного дня (с размещением оборудования для
            шахматного кабинета).
            <br />
            <br />
            Так же в учебном блоке начальных классов запроектированы:
            <br />
            <br />• гардеробы начальных классов;
            <br />• два кабинета иностранного языка;
            <br />• два кабинета информатики с лаборантской;
            <br />• кабинеты музыки и ИЗО;
            <br />• два лингафонных кабинета;
            <br />• два малых спортзала с сопутствующими помещениями;
            <br />• три ресурсных центра;
            <br />• учительская для преподавателей начальных классов;
            <br />• кабинет психолога;
            <br />• кабинет логопеда;
            <br />• сенсорная комната;
            <br />• кабинет коррекционного педагога для работы с детьми с ОВЗ.
            <br />
            <br />
            Для учащихся основного общего и среднего образования предусмотрена
            организация образовательной деятельности по классно-кабинетной
            системе.
            <br />
            <br />
            Учебные помещения и кабинеты для 5-11 классов:
            <br />
            <br />• по одному кабинету химии, физики, биологии и экологии с
            лаборантскими;
            <br />• два кабинета по истории и обществознанию;
            <br />• кабинет ОБЖ;
            <br />• четыре кабинета русского языка и литературы с лаборантской;
            <br />• четыре кабинета математики с лаборантской;
            <br />• кабинет географии и природоведения с ресурсным центром;
            <br />• два кабинета иностранного языка с лаборантской;
            <br />• четыре кабинета информатики с лаборантской;
            <br />• кабинет музыки;
            <br />• кабинет ИЗО;
            <br />• кабинет черчения с лаборантской;
            <br />• два кабинета трудовой подготовки для девочек (кулинария и
            домоводство; мастерская по обработке тканей и технологии) с кладовой
            для хранения сырья, материалов и готовой продукции;
            <br />• два кабинета трудовой подготовки для мальчиков (мастерская
            по обработке металла и технологии, мастерская по обработке дерева и
            технологии) с кладовой для хранения сырья, материалов и готовой
            продукции;
            <br />• два лингафонных кабинета;
            <br />• кабинет социального педагога;
            <br />• кабинет психолога;
            <br />• сенсорная комната;
            <br />• студия дизайна
            <br />
            <br />- Модуль 1. Образовательный модуль для изучения основ
            робототехники.
            <br />
            <br />
            Конструирование. Электроника и микропроцессоры. Информационные
            системы и устройства. Творческое проектирование и соревновательная
            деятельность
            <br />
            <br />- Модуль 2. Лаборатория инженерной графики
            <br />
            <br />
            -Модуль 3. Лаборатория исследования окружающей среды, природных и
            искусственных материалов, альтернативных источников энергии,
            инженерных конструкций.
            <br />
            <br />В помещениях начальных классов, лаборантских, учебных
            кабинетах химии, физики, ИЗО, биологии, слесарной и столярной
            мастерских, кабинетах домоводства и помещениях медицинского
            назначения установлены умывальные раковины.
            <br />
            <br />
            Слесарная и столярная мастерские расположены на первом этаже здания,
            имеют вход из коридора и общий обособленный выход через тамбур
            непосредственно на улицу. При мастерских предусмотрено помещение
            инструментальной. Спортивный блок расположен в левой части здания
            школы. В нем расположен большой спортивный зал для средней и старшей
            школы с зоной для зрителей на 48 мест в антресольной части.
            Стационарные зрительские места выполнены с устройствами для
            крепления к полу. Также в спортивном блоке расположены тренажерный
            зал, зал для хореографии и ЛФК, стрелковый тир для отработки навыков
            стрельбы по мишеням из пневматического оружия, расположенным на
            расстоянии до 10,0 м от огневого рубежа. Еще два малых спортивных
            зала расположены в блоке начальной школы. Количество и размеры
            спортзалов определены исходя из назначения и количества учебных
            часов с учетом разделения использования начальной и старшей школы.
            Всего запроектировано пять спортивных залов:
            <br />
            <br />• спортивный зал размером 18,0х30,0 м (h до низа выступающих
            конструкций 6,32 м) с зоной для болельщиков;
            <br />
            <br />• спортивный зал размером 9,0х18,0 м (h до низа выступающих
            конструкций 6,0 м);
            <br />
            <br />• спортивный зал размером 9,0х18,0 м (h до низа выступающих
            конструкций 6,4 м);
            <br />
            <br />• зал для хореографии и ЛФК – 10,7х13,6 м (h до низа
            выступающих конструкций 3,9 м);
            <br />
            <br />• тренажерный зал – 10,25х14,3 м (h до низа выступающих
            конструкций 3,9 м).
            <br />
            <br />
            При спортивных залах предусмотрены: снарядные, помещение для
            хранения уборочного инвентаря и приготовления дезинфицирующих и
            моющих растворов, раздевальные, душевые и санузлы для мальчиков и
            девочек. Для МГН в санузлах предусмотрена универсальная кабина, в
            душевой – универсальный душ. При каждом зале есть кабинет учителя
            физкультуры с душевой и санузлом. При туалетах и раздевалках
            оборудованы раковины для мытья рук.
            <br />
            <br />В здании предусмотрены отдельные входные группы для начальной
            и основной школ. Каждый вход оборудован турникетами и калиткой для
            групп МГН. Рядом со входом расположен пост охраны. Площадь вестибюля
            принята из расчета не менее 0,2 м2 на одного учащегося.
            <br />
            <br />
            Гардеробные для учащихся предусмотрены раздельные для каждого
            класса. Для учащихся 1–4 классов гардероб расположен в вестибюле со
            стороны входа начальной школы; для учащихся 5–11 классов – в
            вестибюле со стороны входа учеников основной школы.
            <br />
            <br />
            На каждом этаже запроектированы туалеты для персонала и учащихся, в
            том числе для групп МГН. Количество приборов установлено согласно СП
            251.1325800.2016. Для персонала выделены санузлы из расчета не менее
            1 унитаза на 20 человек для персонала и обучающихся.
            <br />
            <br />
            На 2 этаже расположен актовый зал на 450 мест, размеры которого
            определены числом посадочных мест из расчета не менее 0,65 м2 на
            одно посадочное место. Согласно СП 118.13330.2012 п.6.34 расстояние
            от спинки до спинки между рядами кресел в зрительном зале составляет
            0,9 м. Число непрерывно установленных мест составляет 28 при
            двустороннем выходе. Стационарные места выполнены с устройствами для
            крепления к полу. Обеспечивается ширина проходов между креслами 0,5
            м.
            <br />
            <br />
            Актовый зал представляет собой двухсветное пространство. Вход в зал
            осуществляется из фойе. Рядом расположен кабинет музыки. При актовом
            зале запроектированы две артистические, склад бутафории,
            костюмерная, склад для хранения музыкального инвентаря.
            <br />
            <br />
            На 3 этаже запроектирована библиотека (медиатека) на 50 учеников и 4
            места для библиотекарей, являющаяся одним из структурных
            подразделений школы и включающая совокупность фонда книг и
            разнообразных технических и информационных средств. Общая площадь
            библиотеки принята по расчетному показателю на 1 учащегося не менее
            0,6 м2.
            <br />
            <br />В школьной библиотеке предусмотрены зоны:
            <br />
            <br />• информационный пункт для выдачи-приема литературы;
            <br />• фонд открытого доступа читателей;
            <br />• фонд закрытого хранения литературы;
            <br />• читальный зал, оборудованный мультимедийными средствами
            обучения;
            <br />• компьютерный зал с лабораторией проектной деятельности
            «Коворкинг».
            <br />
            <br />
            На этом же этаже расположен школьный музей, студия художественного
            воспитания и административные помещения: кабинет директора,
            приемная, кабинеты заместителей, завхоза, инженера по ТБ, кабинет
            охраны труда. Для инженерного оборудования и хозяйственных нужд
            предусмотрены следующие помещения:
            <br />
            <br />• в подвале – венткамеры, ИТП, насосная и водомерный узел,
            серверная, помещения для обслуживания бассейна;
            <br />• на 1 этаже, с отдельным входом с улицы, – помещение
            электрощитовой, серверная, помещение инженерных служб.
          </Container>

          {/* <VideoWrapper>
            <VideoBlock
              img={project}
              subtitle={'Видеопрезентация проекта «Школа пгт Березово»'}
            />
          </VideoWrapper> */}
        </Col>
      </LastRow>
    </ProjectsCommon>
  );
};

const AsideContainer = styled.div`
  position: relative;
  margin-right: -14px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin: 0;
    :after {
      content: '';
      position: absolute;
      left: -13px;
      bottom: 0;
      width: calc(100% + 26px);
      height: 1px;
      background: #d9d9d9;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    :after {
      left: -7px;
      width: calc(100% + 14px);
    }
  }
`;
const AsideIllRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 28px;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding-bottom: 41px;
    position: relative;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    flex-direction: column-reverse;
    gap: 28px;
    margin-top: 27px;
    padding-bottom: 28px;
  }
`;
const Img = styled.img`
  width: 100%;
  display: block;
  object-fit: cover;
`;
const Ill1Wrapper = styled.div`
  margin-top: 32px;
  padding-right: 11px;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`;
const Ill1WrapperMobile = styled.div`
  display: none;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: block;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    > img {
      max-width: unset;
    }
  }
`;
const Ill2Wrapper = styled.div`
  margin-top: 46px;
  > img {
    max-width: 362px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: -1px;
    max-width: 294px;
    > img {
      max-width: 261px;
      margin: 0;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 6px;
    max-width: unset;
    order: 1;
    > img {
      max-width: unset;
    }
  }
`;
const Ill3Wrapper = styled.div`
  margin-top: 55px;
  > img {
    max-width: 362px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: -8px;
    max-width: 294px;
    > img {
      max-width: 259px;
      margin: 0;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: -3px;
    max-width: unset;
    > img {
      max-width: unset;
    }
  }
`;
const VideoWrapper = styled.div`
  margin-top: 60px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-top: 102px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-top: 18px;
  }
`;
const LastRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-bottom: -8px;
  }
`;
