export const idea_icon = require('./idea_icon.svg').default;
export const ill1 = require('./illustration1.png');
export const ill1_tablet = require('./illustration1_tablet.png');
export const ill2 = require('./illustration2.png');
export const ill2_tablet = require('./illustration2_tablet.png');
export const ill3 = require('./illustration3.png');
export const ill4 = require('./illustration4.png');
export const ill5 = require('./illustration5.png');
export const man1 = require('./man1.png');
export const man2 = require('./man2.png');
export const fig1 = require('./figure1.svg').default;
export const fig2 = require('./figure2.svg').default;
export const fig3 = require('./figure3.svg').default;
