import React from 'react';
import { Col, Row } from '../../../ui/layout';
import styled from 'styled-components';
import { BREAKPOINTS } from '../../../utils';

export const Pagination = ({
  active = 1,
  pages = 0,
  changeActive,
  color = '#54CCC9'
}) => {
  const onChangeActive = (newActive) => {
    if (newActive > 0 && newActive <= pages) {
      changeActive(newActive);
    }
  };

  if (pages <= 1) return null;

  return (
    <StyledRow gap={5} smdGap={4}>
      {Array.from({ length: pages }, (v, i) => i + 1).map((item, key) => (
        <Col auto key={key}>
          <PageButton
            onClick={() => onChangeActive(item)}
            active={item === active}
            color={color}
          >
            {item}
          </PageButton>
        </Col>
      ))}
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  @media (max-width: ${BREAKPOINTS.smd}px) {
    justify-content: center;
  }
`;
const PageButton = styled.div`
  ${(p) => `
  width: 22px;
  height: 29px;
  background: #F9F9F9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 23px;
  color: #D7D7D7;
  text-align: center;
  cursor: pointer;
  transition: .1s;
  
  ${
    p.active
      ? `
    color: #FFFFFF;
    background: ${p.color};
  `
      : ''
  }
  
  @media (max-width: ${BREAKPOINTS.smd}px) {
     width: 18px;
     height: 24px;
     font-size: 12px;
    line-height: 16px;
  }
`}
`;
