import styled, { css } from 'styled-components';
import { BREAKPOINTS, COLORS } from 'utils';
import { marginStyles, paddingStyles } from 'ui/layout';

export const fontMontserrat = css`
  font-family: 'Montserrat', sans-serif;
`;

export const fontMagra = css`
  font-family: 'Magra', sans-serif;
`;

export const H1 = styled.h1`
  font-size: 48px;
  line-height: ${(p) => (p.lineHeight ? `${p.lineHeight}px` : '58px')};
  font-weight: 600;
  ${(p) => (p.color ? `color: ${p.color};` : '')}
  ${(p) => (p.width || p.wide ? `width: ${p.width || '100%'};` : '')}
    
    @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 30px;
    line-height: 36px;
    ${(p) => (p.lgStyles ? p.lgStyles.componentStyle.rules : '')}
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 20px;
    line-height: 24px;
    ${(p) => (p.smStyles ? p.smStyles.componentStyle.rules : '')}
  }
`;
export const H2 = styled.h2`
  font-size: 40px;
  line-height: 48px;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 30px;
    line-height: 36px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 25px;
    line-height: 30px;
  }
`;
export const H3 = styled.h3`
  font-size: 30px;
  line-height: 39px;
  font-weight: ${(p) => (p.weight ? p.weight : '500')};
  ${(p) => marginStyles(p)}
  ${(p) => (p.maxWidth ? `max-width: ${p.maxWidth}px;` : '')}
    
    @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 20px;
    line-height: 26px;
    ${(p) => (p.lgMaxWidth ? `max-width: ${p.lgMaxWidth}px;` : '')}
  }
`;
export const H4 = styled.h4`
  font-size: 25px;
  line-height: 30px;
  font-weight: ${(p) => (p.weight ? p.weight : '600')};
  ${(p) => (p.color ? `color: ${p.color};` : '')}
  ${(p) => marginStyles(p)}
    ${(p) => (p.display ? `display: ${p.display};` : '')}
    ${(p) => (p.maxWidth ? `max-width: ${p.maxWidth}px;` : '')}
    
    @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 20px;
    line-height: 24px;
  }
`;
export const Caption = styled.p`
  font-size: 14px;
  line-height: 20px;
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 12px;
    line-height: 17px;
  }
`;

export const Number100 = styled.p`
  font-size: 100px;
  line-height: ${(p) => (p.lineHeight ? `${p.lineHeight}px` : '121px')};
  font-weight: 600;
  ${(p) => (p.nowrap ? 'white-space: nowrap;' : '')}

  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 70px;
    line-height: 85px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 35px;
    line-height: 42px;
  }
`;
export const Number96 = styled.p`
  font-size: 96px;
  line-height: 115px;
  font-weight: 600;
  letter-spacing: -3px;

  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 70px;
    line-height: 84px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    font-size: 35px;
    line-height: 42px;
  }
`;
export const Text = styled.p`
  ${(p) => `
  ${p.lineHeight ? `line-height: ${p.lineHeight}px;` : ''}
  ${marginStyles(p)}
  ${paddingStyles(p)}
  ${p.color ? `color: ${p.color};` : ''}
  ${p.inline ? 'display: inline;' : ''}
  @media (max-width: ${BREAKPOINTS.lg}px) {    
    ${p.lgLineHeight ? `line-height: ${p.lgLineHeight}px;` : ''}
  }
`}
`;
export const Link = styled.a`
  color: ${(p) => (p.color ? p.color : COLORS.black)};
  text-decoration: none;
`;
export const InteractLink = styled(Link)`
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  padding: 7px 0;
  border-bottom: 1px solid #909090;
  display: block;

  @media (max-width: ${BREAKPOINTS.smd}px) {
    ${(p) =>
      p.small
        ? `
        font-size: 8px;
        line-height: 11px;
        font-weight: 400;
        padding: 1px 0;
      `
        : `
        font-size: 12px;
        line-height: 15px;
        padding: 6px 0;
      `}
  }
`;

export const Text38 = styled.p`
  font-size: 38px;
  line-height: 42px;
`;
export const Text25 = styled.p`
  font-size: 25px;
  line-height: 28px;
`;
