import React from 'react';
import styled from 'styled-components';
import { Col, Container, Img, MainContainer, Row } from 'ui/layout';
import { fontMontserrat, Number100 } from 'ui/typography';
import { BREAKPOINTS, COLORS } from 'utils';
import illustration from 'assets/nextPurposes/illustration.png';

export const Purposes = (props) => (
  <PurposesLine {...props}>
    <MainContainer maxWidth={1594}>
      <MainRow nowrap direction={'row-reverse'}>
        <Col auto>
          <Illustration src={illustration} alt={''} />
        </Col>
        <Point bg={'#EAE98C'}>
          <TextWrapper paddingLeft={107} xlPaddingLeft={80}>
            <Number100 lineHeight={110}>3</Number100>
            <Text>
              Занять лидирующие позиции в сфере контрактов жизненного цикла по
              объектам гражданского строительства.
            </Text>
          </TextWrapper>
        </Point>
        <Point bg={'#0C67B1'}>
          <TextWrapper
            color={COLORS.white}
            paddingLeft={150}
            xlPaddingLeft={80}
          >
            <Number100 lineHeight={110}>2</Number100>
            <Text>
              Стать крупным игроком на рынке концессий дорожного и
              промышленно-гражданского строительства.
            </Text>
          </TextWrapper>
        </Point>
        <Point bg={'#23B5B6'} width={270}>
          <TextWrapper color={COLORS.white}>
            <Number100 lineHeight={110}>1</Number100>
            <Text>Создать легко масштабируемую модель бизнеса.</Text>
          </TextWrapper>
        </Point>
      </MainRow>
    </MainContainer>
  </PurposesLine>
);

const PurposesLine = styled(Container)`
  height: 320px;
  overflow: hidden;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    display: none;
  }
`;
const MainRow = styled(Row)`
  justify-content: center;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    justify-content: flex-end;
    margin-left: -30px;
  }
  :after {
    content: '';
    position: absolute;
    top: -2px;
    left: -394px;
    width: 399px;
    height: calc(100% + 4px);
    background: #23b5b6;
  }
`;
const Text = styled.div`
  ${fontMontserrat}
  padding-top: 20px;
  border-top: 1px solid ${COLORS.default};
`;

const Point = styled(Container)`
  height: 320px;
  width: ${(p) => (p.width ? `${p.width}px` : '463px')};
  padding-top: 50px;
  ${(p) => (p.bg ? `background: ${p.bg};` : '')}
  position: relative;

  :after {
    content: '';
    position: absolute;
    right: -92px;
    top: -2px;
    width: 0;
    height: 0;
    border-top: 162px solid transparent;
    border-left: 94px solid ${(p) => p.bg};
    border-bottom: 162px solid transparent;
  }
  @media (max-width: ${BREAKPOINTS.xl}px) {
    :after {
      right: -59px;
      border-left: 60px solid ${(p) => p.bg};
    }
  }
`;
const TextWrapper = styled(Container)`
  width: 360px;
  box-sizing: content-box;
  z-index: 1;
  position: relative;
  ${(p) =>
    p.color
      ? `
    color: ${p.color};
    ${Text} {
      border-top: 1px solid ${p.color};
    }
  `
      : ''}
  @media (max-width: ${BREAKPOINTS.xl}px) {
    width: 300px;
  }
`;
const Illustration = styled(Img)`
  height: 320px;
  width: 479px;
  margin-left: -82px;
`;
