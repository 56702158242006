import React, { useMemo } from 'react';
import { Header, Footer, NewsPreview, OtherNews } from 'components';
import { Slider } from 'components/common';
import { Col, MainContainer, Row } from 'ui/layout';
import styled from 'styled-components';
import figure from 'assets/common/figure_green.svg';
import { API_URL, BREAKPOINTS, formatDateFromStrapi } from 'utils';
import { useParams } from 'react-router-dom';
import { GET_ARTICLE } from 'api/queries';
import { useQuery } from '@apollo/client';
import ReactMarkdown from 'react-markdown';

// let NEWS_ON_PAGE = 5;
// if (window.innerWidth < BREAKPOINTS.lg) NEWS_ON_PAGE = 8;

export const ArticlePage = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_ARTICLE, {
    variables: { id: id }
  });

  const article = useMemo(() => {
    if (data) {
      return data.article?.data;
    }

    return null;
  }, [data]);

  if (loading) {
    return <p>Loading...</p>;
  }

  let sliderImages = [];

  if (article && !error) {
    return (
      <div>
        <Header active={'news'} />

        <Wrapper>
          <Figure1 src={figure} alt={''} />

          <MainContainer>
            <ContentWrapper>
              <Title>Новости компании и отрасли</Title>
              <Row gap={8} xlGap={'0'}>
                <Col width={'68%'} xlWidth={'65%'} lgWidth={'100%'}>
                  <ArticleContainer>
                    <Article>
                      {article.attributes.img.data.attributes.url && (
                        <Illustration
                          src={`${API_URL}${article.attributes.img.data.attributes.url}`}
                          alt={''}
                        />
                      )}

                      {article.attributes.date && (
                        <DateText>
                          {formatDateFromStrapi(article.attributes.date)}
                        </DateText>
                      )}
                      {article.attributes.title && (
                        <ArticleTitle>{article.attributes.title}</ArticleTitle>
                      )}
                      {article.attributes.description && (
                        <P>{article.attributes.description}</P>
                      )}

                      {article.attributes.Selection.map((block, index) =>
                        block.Text ? (
                          <P key={index}>{block.Text}</P>
                        ) : block.img ? (
                          <Resource key={index}>
                            <Img
                              src={`${API_URL}${block.img.data.attributes.url}`}
                              alt={''}
                            />
                          </Resource>
                        ) : block.slider ? (
                          block.slider.data.map((img) =>
                            sliderImages.push(`${API_URL}${img.attributes.url}`)
                          )(<Slider images={sliderImages} key={index} />)
                        ) : (
                          <p>Error</p>
                        )
                      )}

                      {/* {ARTICLE_CONTENT[currentId].video && (
                      <Resource>
                        <NewsPreview
                          data={{
                            img: ARTICLE_CONTENT[currentId].video,
                            video: true
                          }}
                          wide
                        />
                      </Resource>
                    )} */}
                    </Article>
                  </ArticleContainer>
                </Col>
                <Col
                  width={'32%'}
                  xlWidth={'35%'}
                  lgWidth={'100%'}
                  lgMarginTop={32}
                >
                  <OtherNews
                    id={id} /*NEWS={NEWS} NEWS_ON_PAGE={NEWS_ON_PAGE} */
                  />
                </Col>
              </Row>
            </ContentWrapper>
          </MainContainer>

          <Figure2 src={figure} alt={''} />
        </Wrapper>

        <Footer />
      </div>
    );
  }

  return <p>Something went wrong...</p>;
};

const Wrapper = styled.article`
  position: relative;
  padding-top: 39px;
  padding-bottom: 166px;
  :after {
    content: '';
    position: absolute;
    left: -76px;
    top: 0;
    height: 89px;
    width: 100px;
    background: #54ccc9;
    transform: skew(-30deg);
  }
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding-top: 46px;
    padding-bottom: 81px;
    :after {
      left: -76px;
    }
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    padding-top: 33px;
    padding-bottom: 57px;
    :after {
      height: 26px;
      left: -90px;
      top: 32px;
    }
  }
`;
const Figure1 = styled.img`
  position: absolute;
  right: -249px;
  top: 84px;
  object-fit: contain;
  width: 499px;
  z-index: -1;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 429px;
    top: 32px;
    right: -276px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 282px;
    top: 67px;
    right: -179px;
  }
`;
const Figure2 = styled(Figure1)`
  top: unset;
  right: unset;
  left: -245px;
  bottom: 2px;
  width: 375px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    width: 263px;
    bottom: -2px;
    left: -183px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    width: 214px;
    bottom: -27px;
    left: -162px;
  }
`;
const ContentWrapper = styled.div`
  margin-left: -10px;
  margin-right: -10px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-left: -15px;
    margin-right: -13px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-left: -7px;
    margin-right: -7px;
  }
`;
const Title = styled.p`
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  background: #23b5b6;
  height: 44px;
  padding: 12px 47px 10px 42px;
  margin-left: 21px;
  clip-path: polygon(24px 0, calc(100% - 24px) 0%, 100% 100%, 0% 100%);
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-left: 16px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 12px;
    line-height: 15px;
    margin-left: 8px;
    height: 29px;
    padding: 8px 30px 8px 30px;
    clip-path: polygon(16px 0, calc(100% - 16px) 0%, 100% 100%, 0% 100%);
  }
`;
const ArticleContainer = styled.div`
  padding-right: 25px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding: 0;
  }
`;
const Article = styled.div`
  border: 1px solid #d9d9d9;
  padding: 12px 10px 0;
  background: #ffffff;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    padding: 10px 12px 0;
  }
`;
const Img = styled.img`
  width: 100%;
`;
const Illustration = styled(Img)`
  margin-bottom: 60px;
  max-height: 446px;
  object-fit: cover;
  @media (max-width: ${BREAKPOINTS.xl}px) {
    max-height: 333px;
  }

  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-bottom: 26px;
    max-height: 430px;
  }
  @media (max-width: ${BREAKPOINTS.md}px) {
    max-height: 333px;
  }

  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-bottom: 12px;
    max-height: 237px;
  }
  @media (max-width: ${BREAKPOINTS.sm}px) {
    max-height: 181px;
  }
`;

const DateText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #696969;
  margin-bottom: 15px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    margin-bottom: 33px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 15px;
  }
`;
const ArticleTitle = styled.h1`
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  color: #23222f;
  margin-bottom: 20px;
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 38px;
    line-height: 46px;
    margin-bottom: 34px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 18px;
  }
`;
const P = styled(ReactMarkdown)`
  font-weight: 300;
  margin-bottom: 20px;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-bottom: 12px;
  }
`;
const Resource = styled.div`
  margin-bottom: 29px;
  margin-top: 34px;
  @media (max-width: ${BREAKPOINTS.smd}px) {
    margin-bottom: 18px;
    margin-top: 20px;
  }
`;
