import React from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';

export const YearsFilter = ({
  options = [],
  selected,
  onChange,
  color = '#54CCC9'
}) => {
  const changeYear = (value) => {
    if (selected !== value) onChange(value);
  };

  return (
    <FiltersContainer>
      {options.map((item, key) => (
        <YearButton
          active={selected === item}
          onClick={() => changeYear(item)}
          color={color}
          key={key}
        >
          {item}
        </YearButton>
      ))}
    </FiltersContainer>
  );
};

const FiltersContainer = styled.div`
  display: flex;
`;
const YearButton = styled.div`
  ${(p) => `
  height: 26px;
  width: 67px;
  margin-right: 7px;
  background: ${p.active ? p.color : '#F9F9F9'};
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: ${p.active ? '#FFFFFF' : '#D7D7D7'};
  cursor: ${p.active ? 'default' : 'pointer'};
  transition: .1s;
  
  @media (max-width: ${BREAKPOINTS.lg}px) {
    font-size: 16px;
  }
  @media (max-width: ${BREAKPOINTS.smd}px) {
    height: 20px;
    width: 56px;
    font-size: 12px;
    line-height: 20px;
    margin-right: 6px;
  }
`}
`;
